<template>
  <section v-loading="loading" class="attachment-popup-body">
    <div class="attachment-upload-button justify-space-between">
      <div
        class="category-select"
        v-if="hasPermission('cases', 'attachments')"
      >
        <el-select
          clearable
          filterable
          default-first-option
          placeholder="Select Category"
          v-model="category"
          allow-create
        >
          <el-option
            v-for="attach in this.attachmentsOptions"
            :label="attach"
            :value="attach"
            :key="attach"
          ></el-option>
        </el-select>
      </div>
      <el-upload
        name="logo"
        action
        :on-change="uploadFile"
        :show-file-list="false"
        :auto-upload="false"
      >
        <el-button
          class="btn radius-button round-button"
          v-if="hasPermission('cases', 'attachments_upload')"
        >
          <span>
            <img
              src="@/assets/images/icons/attachment-icon-v2.svg"
              alt=""
            /> </span
          >SELECT FILE</el-button
        >
      </el-upload>
    </div>
    <!-- Attachment upload -->
    <div class="attachment-upload" v-loading="fileUploadLoading">
      <a v-if="selectedFileName">
        <div class="content">
          <div class="icon">
            <img
              src="@/assets/images/icons/attachments/attachments_0.svg"
              alt="icon"
              class="img-fluid"
            />
          </div>
          <div class="text">
            <p>
              {{ selectedFileName }}
            </p>
          </div>
        </div>
        <ul class="actions">
          <li>
            <el-button @click="remove" class="remove-button">
              <el-icon><close /></el-icon>
            </el-button>
          </li>
          <li>
            <el-button
              class="upload-button"
              @click="uploadSelectedDocument"
              :disabled="!selectedFile ? true : false"
            >
              <el-icon><Upload /></el-icon>
            </el-button>
          </li>
        </ul>
      </a>
    </div>

    <div v-if="!noAttachments" class="attachment-uploaded">
      <ul>
        <li v-for="(doc, index) of allAttachments" :key="index">
          <div class="content" @click="downloadAttachment(doc)">
            <div class="icon">
              <img
                src="@/assets/images/icons/attachments/attachments_0.svg"
                alt="icon"
                class="img-fluid"
              />
            </div>
            <p class="text">
              <b style="color: blue">{{
                doc.category ? doc.category + " -" : ""
              }}</b>
              {{
                doc.metadata && doc.metadata.original_name
                  ? doc.metadata.original_name
                  : ""
              }}
            </p>
          </div>
          <button
            class="remove-button"
            type="button"
            @click="onDeleteAttachment(doc)"
            v-if="hasPermission('cases', 'attachments_upload')"
          >
            <p>Remove</p>
          </button>
        </li>
      </ul>
    </div>
    <div
      class="attachments-empty"
      v-if="noAttachments && !this.selectedFileName"
    >
      <p>You Can manage attachments here</p>
    </div>
    <div class="mini-reason-for-change">
      <ResonForChange
        v-if="takeReasonForChangeDialog"
        v-on:close="takeReasonForChangeDialog = false"
        v-on:reasonSubmit="deleteAttachment"
        title="Reason for Remove"
      ></ResonForChange>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import moment from "moment";
import caseConstants from "@/config/constants/caseConstants";
export default {
  name: "Attachments",
  mixins: [UserPermissionsHelper],
  components: {
    ResonForChange,
  },
  computed: {
    ...mapGetters("cases", [
      "getFileUploadURLForAttachments",
      "getFileUploadRefIdForAttachment",
      "getAttachmentUploadStatusUpdated",
      "getAttachmentUploadStatusForAttachments",
      "getAttachmentDeleteStatus",
      "getCaseInformation",
    ]),
    ...mapGetters("respiratoryFileUpload", ["getFileDownloadUrl"]),
  },

  mounted() {
    this.checkCaseInformation();
  },
  data() {
    return {
      noAttachments: false,
      selectedFile: null,
      selectedFileName: null,
      caseId: "",
      allAttachments: [],
      loadingText: "",
      loading: false,
      fileUploadLoading: false,
      takeReasonForChangeDialog: false,
      selectedNote: null,
      attachmentsOptions: caseConstants.ATTACHMENTS_DROPDOWN,
      category: "",
    };
  },
  methods: {
    uploadFile(file) {
      this.selectedFile = file.raw;
      this.selectedFileName = file.raw.name;
      this.getFileUploadUrl(file);
    },
    remove() {
      (this.selectedFile = null), (this.selectedFileName = "");
    },
    async getFileUploadUrl(file) {
      try {
        this.loading = true;
        let data = {
          file: file.raw.name,
          file_type: file.raw.type,
          file_size: file.raw.size / 1000,
          case_id: this.caseId,
          type: "case-attachments-upload",
        };
        if (this.category) {
          data.category = this.category;
        }
        await this.$store.dispatch("cases/fetchDocumentUploadUrl", data);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async uploadSelectedDocument() {
      this.fileUploadLoading = true;
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: this.selectedFile,
        upload_url: this.getFileUploadURLForAttachments,
      };

      await this.$store.dispatch(
        "cases/uploadDocumentToGeneratedUrlForAttachments",
        params
      );

      if (this.getAttachmentUploadStatusForAttachments) {
        // UPDATEING STATUS
        let params = {
          hash: this.getFileUploadRefIdForAttachment,
          case_id: this.caseId,
          type: "case-attachments-upload",
        };
        await this.$store.dispatch("cases/updateDocumentUploadStatus", params);
        if (this.getAttachmentUploadStatusUpdated) {
          successNotification("Attachment added successfully");
          this.selectedFileName = "";
          this.selectedFile = null;
          await this.checkCaseInformation();
        } else {
          errorNotification("Sorry ! Error while file uploading");
        }
      } else {
        errorNotification("Sorry ! Error while file uploading");
      }
      this.fileUploadLoading = false;
    },
    onDeleteAttachment(doc) {
      this.takeReasonForChangeDialog = true;
      this.selectedNote = doc;
    },

    async deleteAttachment(reason) {
      let params = {
        case_id: this.caseId,
        id: this.selectedNote._id,
        data_update_reason: reason,
      };

      await this.$store.dispatch("cases/deleteAttachment", params);

      if (this.getAttachmentDeleteStatus) {
        successNotification("Attachment Removed Successfully");
        this.checkCaseInformation();
      }
    },

    async downloadAttachment(doc) {
      let routerData = this.$router.resolve({
        name: "ViewAttachments",
        params: {
          path: doc.path,
        },
      });
      window.open(routerData.href, "_blank");
    },

    async checkCaseInformation() {
      try {
        this.loading = true;
        this.fileUploadLoading = false;
        this.caseId = this.$route.params.case_id;
        if (!this.caseId) {
          this.loading = false;
          this.$router.push({
            to: "/cases",
          });
        } else {
          await this.$store.dispatch("cases/fetchCaseInformation", this.caseId);
          if (!this.getCaseInformation) {
            this.disablePatientInformation = true;
            this.showTertiaryInsuranceForm = true;
          }

          if (this.getCaseInformation && this.getCaseInformation.attachments) {
            this.allAttachments = this.getCaseInformation.attachments || [];
            this.allAttachments = this.allAttachments.sort(
              (a, b) => moment(b.created_at) - moment(a.created_at)
            );
            this.noAttachments = this.allAttachments.length ? false : true;
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.mini-reason-for-change {
  .el-dialog {
    width: 28%;
    height: 210px !important;
    margin-top: 20vh;
  }
}
</style>
