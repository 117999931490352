<template>
  <section
    id="view-physician-profile"
    class="case-creation"
    v-loading="loading"
  >
    <div
      class="view-physician-profile-block container-small-table"
      v-if="getSinglePhysician && status == 'view'"
    >
      <div class="recheckBtn">
        <el-tag
          type="success"
          size="large"
          round
          v-if="getSinglePhysician && getSinglePhysician.pecos_status"
          class="mb-10"
          style="cursor: pointer"
        >
          PECOS Verified
          <el-icon>
            <Finished />
          </el-icon>
        </el-tag>
        <el-tag type="danger" size="large" round v-else class="mb-10 mr-10">
          PECOS Not Verified
          <el-icon>
            <Finished />
          </el-icon>
        </el-tag>
        <el-button
          class="btn button-with-icon mb-10"
          v-if="
            (getSinglePhysician && getSinglePhysician.pecos_status == false) ||
            !getSinglePhysician.pecos_status
          "
          @click="reCheckPecosStatus"
        >
          Recheck For PECOS
        </el-button>
      </div>

      <div class="case-creation__step case-creation__patient-information">
        <div class="case-creation__card">
          <div class="case-creation__heading mb-5">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-patient-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Physician Information</h3>
            </div>
            <div class="btn-submit">
              <span v-if="hasPermission('physicians', 'add')">
                <el-button class="edit-btn" @click="changeStatus">
                  <span class="edit-icon">
                    <img
                      src="@/assets/images/icons/edit-case-information.svg"
                      alt=""
                    />

                    EDIT
                  </span>
                </el-button>
              </span>
            </div>
          </div>

          <div class="case-creation__body pb-0">
            <div class="vue-form">
              <el-row :gutter="20">
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">Full Name</h4>

                    <p class="value">{{ this.physicianInfo.name }}</p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">First Name</h4>

                    <p class="value">
                      {{
                        this.physicianInfo.first_name
                          ? this.physicianInfo.first_name
                          : "--"
                      }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">Middle Name</h4>

                    <p class="value">
                      {{
                        this.physicianInfo.middle_name
                          ? this.physicianInfo.middle_name
                          : "--"
                      }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">Last Name</h4>

                    <p class="value">
                      {{
                        this.physicianInfo.last_name
                          ? this.physicianInfo.last_name
                          : "--"
                      }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">UPIN/NPI</h4>
                    <p class="value" v-if="this.physicianInfo.npi_code">
                      {{ this.physicianInfo.npi_code }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">Phone Number</h4>
                    <p class="value">{{ this.physicianInfo.phone }}</p>
                  </div>
                </el-col>

                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
                  <div class="input-value">
                    <h4 class="name">Email</h4>
                    <p class="value">{{ this.physicianInfo.email || "--" }}</p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
                  <div class="input-value">
                    <h4 class="name">Fax</h4>
                    <p class="value">{{ this.physicianInfo.fax || "--" }}</p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
                  <div class="input-value">
                    <h4 class="name">Address Line 1</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.address &&
                        this.physicianInfo.address.address_line_1
                      "
                    >
                      {{ this.physicianInfo.address.address_line_1 }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>

                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="24">
                  <div class="input-value">
                    <h4 class="name">Address Line 2</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.address &&
                        this.physicianInfo.address.address_line_2
                      "
                    >
                      {{ this.physicianInfo.address.address_line_2 }}
                    </p>
                    <p v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">ZIP</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.address &&
                        this.physicianInfo.address.zip
                      "
                    >
                      {{ this.physicianInfo.address.zip }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">State</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.address &&
                        this.physicianInfo.address.state
                      "
                    >
                      {{ this.physicianInfo.address.state }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value">
                    <h4 class="name">City</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.address &&
                        this.physicianInfo.address.city
                      "
                    >
                      {{ this.physicianInfo.address.city }}
                    </p>
                    <p v-else>-</p>
                  </div>
                </el-col>

                <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="24">
                  <div class="input-value">
                    <h4 class="name">Branches</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.hospital_branches &&
                        this.physicianInfo.hospital_branches.length
                      "
                    >
                      {{
                        this.physicianInfo.hospital_branches
                          .map(function (e) {
                            return e.display_name;
                          })
                          .join(", ")
                      }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>

                <el-col :xl="12" :lg="12" :md="8" :sm="12" :xs="24">
                  <div class="input-value">
                    <h4 class="name">Specializations</h4>

                    <p
                      class="value"
                      v-if="
                        this.physicianInfo.specializations &&
                        this.physicianInfo.specializations.length
                      "
                    >
                      {{ this.physicianInfo.specializations.join(", ") }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="case-creation__heading border-top">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-case-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Ordering Facility Information</h3>
            </div>
          </div>
          <div class="case-creation__body">
            <div class="all-cases-view hospital-information">
              <div class="primary-content-view-new">
                <div
                  v-if="this.physicianHospitals"
                  class="listings listings__table listings__all-cases"
                >
                  <el-table :data="this.physicianHospitals">
                    <el-table-column
                      label="Name"
                      width="350"
                      prop="name"
                    ></el-table-column>
                    <el-table-column
                      label="Code"
                      width="300"
                      prop="code"
                    ></el-table-column>
                    <el-table-column label="User Account Status" width="350">
                      <template v-slot="scope">
                        <span v-if="scope.row.account">{{
                          scope.row.account.status
                        }}</span>
                        <span v-else>NA</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="case-creation__card mt-10">
          <div class="case-creation__heading">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-case-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Physician Settings</h3>
            </div>
          </div>
          <div class="case-creation__body pb-0">
            <div>
              <div class="vue-form">
                <span v-if="hasPermission('physicians', 'add')"> </span>
                <el-row :gutter="30">
                  <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
                    <div class="input-value">
                      <h4 class="name">Auto fax</h4>

                      <p class="value">
                        {{ this.settingsInfo.auto_fax ? "Yes" : "No" }}
                      </p>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
                    <div class="input-value">
                      <h4 class="name">Email Notifications</h4>

                      <p class="value">
                        {{
                          this.settingsInfo.email_notifications ? "Yes" : "No"
                        }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddNewPhysician
      v-else-if="status == 'edit' && !loading"
      :updateOnCancel="true"
      @onCancel="onCancelForm"
      @onUpdate="loadPhysicianDetails"
    ></AddNewPhysician>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
const AddNewPhysician = defineAsyncComponent(() =>
  import("@/components/physicians/AddNewPhysician")
);
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import { ElMessageBox } from "element-plus";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [UserPermissionsHelper],
  data() {
    return {
      loading: false,
      settingsInfo: {},
      form: {
        auto_fax: false,
        email_notifications: false,
      },
      physicianInfo: {},
      physicianFormData: {
        npi_code: "",
        name: "",
        phone: "",
        fax: "",
        email: "",
        hospitals: [],
        specializations: [],
        address: {
          address_line_1: "",
          address_line_2: "",
          city: "",
          state: "",
          zip: "",
        },
        hospital_branches: [],
        settings: {
          auto_fax: false,
          email_notifications: false,
        },
      },
      physicianHospitals: [],
      status: "view",
      physicianEditFormData: {
        npi_code: "",
        name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        fax: "",
        email: "",
        hospital: "",
        hospital_branches: [],
        specializations: [],
        address: {
          address_line_1: "",
          address_line_2: "",
          city: "",
          state: "",
          zip: "",
        },
        settings: {
          auto_fax: false,
          email_notifications: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters("physicians", [
      "getSinglePhysician",
      "getPecosData",
      "getPhysicianValidationErrors",
      "getPhysicianAddStatus",
    ]),
    physician_id() {
      if (this.$route.params.physician_id) {
        return this.$route.params.physician_id;
      }
      return null;
    },
  },

  components: {
    AddNewPhysician,
  },
  async mounted() {
    this.status = "view";
    this.$store.dispatch("hospitals/fetchAllHospitals", {
      get_all: true,
    });

    this.$store.dispatch("physicians/clearValidationErrors");
    this.$store.dispatch("help/fetchAllStates");
    await this.fetchPhysicianDetails(this.physician_id);
  },
  methods: {
    onCancelForm() {
      this.status = "view";
    },

    async fetchPhysicianDetails(physician_id) {
      this.loading = true;
      await this.$store.dispatch("physicians/fetchSinglePhysician", {
        physician_id: physician_id,
        include_hospital_accounts: true,
        include_hospital_details: true,
      });
      this.loading = false;
      this.physicianInfo = JSON.parse(JSON.stringify(this.getSinglePhysician));
      this.physicianHospitals = [];
      this.physicianHospitals = this.physicianInfo.hospitals;
      this.settingsInfo = {
        ...this.settingsInfo,
        ...this.physicianInfo.settings,
      };
    },
    async changeStatus() {
      this.loading = true;
      this.status = "edit";
      this.$store.dispatch("physicians/clearValidationErrors");

      this.physicianFormData.hospitals =
        this.physicianInfo.hospitals.map((e) => e._id) || [];
      await this.$store.dispatch("branches/fetchMultipleHospitalBranches", {
        hospitals: this.physicianFormData.hospitals,
      });
      this.physicianFormData.npi_code = this.physicianInfo.npi_code || "";
      this.physicianFormData.name = this.physicianInfo.name || "";
      this.physicianFormData.phone = this.physicianInfo.phone || "";
      this.physicianFormData.fax = this.physicianInfo.fax || "";
      this.physicianFormData.email = this.physicianInfo.email || "";

      this.physicianFormData.hospital_branches =
        this.physicianInfo.hospital_branches.map((e) => e._id) || [];
      this.physicianFormData.specializations =
        this.physicianInfo.specializations || [];
      this.physicianFormData.settings = {
        ...this.physicianFormData.settings,
        ...this.physicianInfo.settings,
      };
      if (this.physicianInfo.address) {
        this.physicianFormData.address.address_line_1 =
          this.physicianInfo.address.address_line_1 || "";
        this.physicianFormData.address.address_line_2 =
          this.physicianInfo.address.address_line_2 || "";
        this.physicianFormData.address.city =
          this.physicianInfo.address.city || "";
        this.physicianFormData.address.state =
          this.physicianInfo.address.state || "";
        this.physicianFormData.address.zip =
          this.physicianInfo.address.zip || "";
      } else {
        this.physicianFormData.address.address_line_1 = "";
        this.physicianFormData.address.address_line_2 = "";
        this.physicianFormData.address.city = "";
        this.physicianFormData.address.state = "";
        this.physicianFormData.address.zip = "";
      }

      this.loading = false;
    },

    async loadPhysicianDetails() {
      this.status = "view";
      await this.fetchPhysicianDetails(this.physician_id);
      this.settingsInfo = {
        ...this.settingsInfo,
        ...this.physicianInfo.settings,
      };
      this.form = {
        ...this.form,
        ...this.physicianInfo.settings,
      };
    },
    preparePhysicianData() {
      if (this.physician_id) {
        this.physicianEditFormData.id = this.physician_id;
      }
      this.physicianEditFormData.hospitals =
        this.physicianInfo.hospitals.map((e) => e._id) || [];
      this.physicianEditFormData.npi_code = this.physicianInfo.npi_code || "";
      this.physicianEditFormData.name = this.physicianInfo.name || "";
      this.physicianEditFormData.first_name = this.physicianInfo.name || "";
      this.physicianEditFormData.middle_name = this.physicianInfo.name || "";
      this.physicianEditFormData.last_name = this.physicianInfo.name || "";

      this.physicianEditFormData.phone = this.physicianInfo.phone || "";
      this.physicianEditFormData.fax = this.physicianInfo.fax || "";
      this.physicianEditFormData.email = this.physicianInfo.email || "";

      this.physicianEditFormData.hospital_branches =
        this.physicianInfo.hospital_branches.map((e) => e._id) || [];
      this.physicianEditFormData.specializations =
        this.physicianInfo.specializations || [];
      this.physicianEditFormData.settings = {
        ...this.physicianEditFormData.settings,
        ...this.physicianInfo.settings,
      };
      if (this.physicianInfo.address) {
        this.physicianEditFormData.address.address_line_1 =
          this.physicianInfo.address.address_line_1 || "";
        this.physicianEditFormData.address.address_line_2 =
          this.physicianInfo.address.address_line_2 || "";
        this.physicianEditFormData.address.city =
          this.physicianInfo.address.city || "";
        this.physicianEditFormData.address.state =
          this.physicianInfo.address.state || "";
        this.physicianEditFormData.address.zip =
          this.physicianInfo.address.zip || "";
      } else {
        this.physicianEditFormData.address.address_line_1 = "";
        this.physicianEditFormData.address.address_line_2 = "";
        this.physicianEditFormData.address.city = "";
        this.physicianEditFormData.address.state = "";
        this.physicianEditFormData.address.zip = "";
      }

      return this.physicianEditFormData;
    },
    async reCheckPecosStatus() {
      this.loading = true;
      const payload = this.preparePhysicianData();
      if (!this.physicianEditFormData.first_name) delete payload.first_name;
      if (!this.physicianEditFormData.middle_name) delete payload.middle_name;
      if (!this.physicianEditFormData.last_name) delete payload.last_name;
      await this.$store.dispatch(
        "physicians/checkPecosStatus",
        this.physicianEditFormData.npi_code
      );
      if (this.getPecosData && this.getPecosData.length > 0) {
        this.physicianEditFormData.pecos_status = true;
        this.physicianEditFormData.pecos_number =
          this.getPecosData[0].PECOS_ASCT_CNTL_ID; // Accessing the first element of the array
      } else {
        this.physicianEditFormData.pecos_status = false;
        ElMessageBox.alert("Not enrolled in PECOS", "PECOS Status", {
          confirmButtonText: "OK",
          // type: "error",
          center: true,
          callback: () => {
            // Optional callback logic after the user closes the ElMessageBox
          },
        });
      }
      let previousPecosStatus = this.physicianInfo.pecos_status;
      if (previousPecosStatus != this.physicianEditFormData.pecos_status) {
        await this.$store.dispatch("physicians/editPhysician", payload);
        if (this.getPhysicianAddStatus) {
          successNotification(this.getPhysicianAddStatus.message);
          await this.fetchPhysicianDetails(this.physician_id);
        } else if (
          this.getPhysicianValidationErrors &&
          this.getPhysicianValidationErrors.critical_error
        ) {
          errorNotification(this.getPhysicianValidationErrors.critical_error);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#view-physician-profile {
  .view-physician-profile-block {
    .recheckBtn {
      display: flex;
      justify-content: end;
    }
    .case-creation__heading {
      position: relative;
      .title-with-icon {
        max-width: 265px;
      }
    }
    .case-creation__body {
      margin-top: 1.5px;
      border-radius: 6.40567px;
    }
    .hospital-information {
      .page-title {
        color: #828282;
      }
      .el-table::before {
        height: 0px;
      }
      .listings__table .el-table table {
        border-spacing: 0 0.5px;
      }
      .listings__table .el-table table thead tr {
        border: none;
      }
      .listings__table .el-table table thead tr th.el-table__cell {
        border: none;
      }
      .listings__table .el-table table thead,
      .listings__table .el-table table tbody tr {
        td {
          &:first-child {
            border: none !important;
          }
          &:last-child {
            border: none !important;
          }
        }
        th {
          &:first-child {
            border: none !important;
          }
          &:last-child {
            border: none !important;
          }
        }
      }
      .listings__table .el-table table tbody tr td.el-table__cell {
        border: none;
      }
    }

    .npi-button {
      border: none;
      padding: 8px 5px;
      &:hover {
        background-color: none;
      }
    }
    .edit-btn {
      position: absolute;
      right: 0px;
      top: -3px;

      z-index: 99;
      padding: 0 20px;
      border: none;
      background-color: transparent;
      color: #828282;
      .edit-icon {
        img {
          position: relative;
          top: 2.5px;
        }
      }
    }
  }
}
</style>
