<template>
  <div id="revenue-stats-section">
    <div class="upload-toggle">
      <ul class="tab-listing">
        <li>
          <el-button
            @click="toggleUploadFile(true)"
            :class="{ active: uploadFile }"
          >
            New Upload
          </el-button>
        </li>
        <li>
          <el-button
            @click="toggleUploadFile(false)"
            :class="{ active: !uploadFile }"
          >
            Recent
          </el-button>
        </li>
      </ul>
      <div class="upload-preview" v-if="uploadFile">
        <RevenueUploadFile />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
const RevenueUploadFile = defineAsyncComponent(() =>
  import("@/components/revenueStats/FileUpload")
);
export default {
  data() {
    return {
      uploadFile: true,
    };
  },
  components: { RevenueUploadFile },
  computed: {
    ...mapGetters("revenueStats", [
      "getRevenueStatsFileUploadStatus",
      "getRevenueStatsProcessFileStatus",
    ]),
  },
  mounted() {},
  methods: {
    async uploadRevenueFile() {
      try {
        await this.$store.dispatch("revenueStats/uploadRevenueStatsFileUpload");
      } catch (error) {
        console.log(error);
      }
    },
    async processRevenueFile() {
      try {
        await this.$store.dispatch("revenueStats/revenueStatsProcessFile");
      } catch (error) {
        console.log(error);
      }
    },
    toggleUploadFile(upload) {
      this.uploadFile = upload;
    },
  },
};
</script>

<style lang="scss">
#revenue-stats-section {
  margin-top: 2rem;
  .upload-toggle {
    background-color: #ffffff;
    margin: auto;
    width: 900px;
    border-radius: 20px;
    padding-top: 15px;
    overflow: hidden;

    .tab-listing {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f7f9fb;
      max-width: fit-content;
      border-radius: 40px;
      margin: 0 auto 15px;
      column-gap: 5px;
      padding: 4px 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      min-height: 38px;
      li {
        .el-button {
          color: #4353ff;
          border-radius: 50px;
          border: none;
          background-color: transparent;
          min-height: 30px;
          padding: 10px 20px;
          font-weight: 600;
          font-size: 16px;

          &.active {
            background-color: #4353ff;
            color: #ffffff;
          }
        }
      }
    }
  }
  .upload-preview {
    background-color: #f8f8f8;
    font-family: Poppins;
    margin: auto;
    padding: 2rem 0;
  }
}
</style>
