export default {
  data() {
    return {
      exportEmrOrderExcelFields: [
        {
          label: "Accession ID",
          value: "accession_id",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "EMR Client Name",
          value: "emr_client.name",
        },
        {
          label: "Territory Manager",
          value: "hospital_marketers",
        },
        {
          label: "Ordering Facility",
          value: "hospital.name",
        },
        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Physician NPI",
          value: "ordering_physician.npi_code",
        },
        {
          label: "Patient First Name",
          value: "patient_info.first_name",
        },
        {
          label: "Patient Last Name",
          value: "patient_info.last_name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },
        {
          label: "Status",
          value: "status",
        },
        {
          label: "Test Panels",
          value: "tests_panels",
        },
        {
          label: "ICD Codes",
          value: "icd_codes",
        },
        {
          label: "Ordering Facility Phone",
          value: "hospital.phone",
        },
        {
          label: "Ordering Facility Branch",
          value: "hospital_branch.display_name",
        },
        {
          label: "Patient Middle Name",
          value: "patient_info.middle_name",
        },
        {
          label: "Patient Gender",
          value: "patient_info.gender",
        },
        {
          label: "Patient Phone",
          value: "patient_info.phone",
        },
        {
          label: "Patient Email",
          value: "patient_info.email",
        },
        {
          label: "Patient Address",
          value: "patient_info.address",
        },
        {
          label: "Pregnancy",
          value: "patient_info.pregnant",
        },
      ],
      defaultExportEmrOrderExcelFields: [
        {
          label: "EMR Client Name",
          value: "emr_client.name",
        },
        {
          label: "Accession ID",
          value: "accession_id",
        },
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Patient First Name",
          value: "patient_info.first_name",
        },
        {
          label: "Patient Last Name",
          value: "patient_info.last_name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },
        {
          label: "Ordering Facility",
          value: "hospital.name",
        },
        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Physician NPI",
          value: "ordering_physician.npi_code",
        },
        {
          label: "Status",
          value: "status",
        },
        {
          label: "Test Panels",
          value: "tests_panels",
        },
        {
          label: "Territory Manager",
          value: "hospital_marketers",
        },
      ],
    }
  },
}
