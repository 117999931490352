<template>
  <section class="all-cases-view" id="marketer-stats" v-loading="loading">
    <div class="container-default">
      <div class="marketers-navbar">
        <el-row type="flex">
          <el-col :lg="24">
            <div class="right-block">
              <ul class="justify-start">
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="manager_id"
                      placeholder="Select Manager"
                      @change="fetchManagerMarketers"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="(manager, index) in managersList"
                        :key="index"
                        :label="manager.first_name + ' ' + manager.last_name"
                        :value="manager._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                    </span>
                  </div>
                </li>
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="marketer_ids"
                      placeholder="Select Territory Manager"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="(marketer, index) in managerWiseMarketers"
                        :key="index"
                        :label="marketer.first_name + ' ' + marketer.last_name"
                        :value="marketer._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                    </span>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <div class="date-picker">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      :shortcuts="shortcuts"
                      :disabled-date="disabledDate"
                      format="MM-DD-YYYY"
                      value-format="YYYY-MM-DD"
                    >
                    </el-date-picker>
                  </div>
                </li>
                <li>
                  <el-button
                    class="btn button-with-icon with-blue-bg"
                    @click="fetchAllStats()"
                    :disabled="checkFiltersData"
                  >
                    GET DATA
                  </el-button>
                </li>
                <li
                  v-if="
                    this.manager_id &&
                    this.value1 &&
                    this.value1.length &&
                    this.noDataPopup == false
                  "
                >
                  <el-button
                    class="btn button-with-icon cancel"
                    @click="clearMarketerData"
                  >
                    CLEAR
                  </el-button>
                </li>
                <li
                  v-if="
                    this.value1 &&
                    this.value1.length &&
                    this.noDataPopup == false &&
                    this.getIndividualRevenueStats &&
                    this.getIndividualRevenueStats.data &&
                    this.getIndividualRevenueStats.data.length
                  "
                >
                  <el-button
                    class="btn button-with-icon"
                    @click="exportTableData"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/export-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>Export</span>
                  </el-button>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="combined-marketers-table individual">
        <el-table
          :data="getIndividualRevenueStats.data"
          height="calc(100vh - 240px)"
          @sort-change="onChangeSort"
        >
          <el-table-column label="Ordering Facility Name" prop="hospital">
            <template #default="scope">
              <p class="marketer-name">
                {{ getSelectedHospital(scope.row.hospital) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="Total Amount"
            prop="total_amount"
            sortable="custom"
          >
            <template #header>
              <!-- Display total number of hospitals count in the header -->

              <p class="header">Total Amount</p>
              <p class="total-count total">$ {{ calculateTotal() }}</p>
            </template>
            <template #default="scope">
              <p class="amount total">
                $ {{ scope.row.total_amount.toLocaleString("en-US") || "0" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="Paid Amount"
            prop="paid_amount"
            sortable="custom"
          >
            <template #header>
              <!-- Display total number of hospitals count in the header -->
              <p class="header">Paid Amount</p>
              <p class="total-count paid">$ {{ calculatePaid() }}</p>
            </template>
            <template #default="scope">
              <p class="amount paid">
                ${{ scope.row.paid_amount.toLocaleString("en-US") || "0" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="Pending Amount"
            prop="pending_amount"
            sortable="custom"
          >
            <template #header>
              <!-- Display total number of hospitals count in the header -->

              <p class="header">Pending Amount</p>
              <p class="total-count">${{ calculatePending() }}</p>
            </template>
            <template #default="scope">
              <p class="amount">
                $ {{ scope.row.pending_amount.toLocaleString("en-US") || "0" }}
              </p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog top="10%" width="35%" v-model="noDataPopup">
        <div class="dialog-header">
          <span class="header-part"> Warning! </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="noDataPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date text-center">
          <img src="@/assets/images/icons/cases/warning-icon.svg" alt="icon" />
        </div>

        <h2 class="text-center">No Data Found With This Filter!</h2>
        <div class="popup-date text-center">
          <h3>Please Select Any Filter</h3>

          <div class="dialog-footer justify-center">
            <el-button class="blue-save-button" @click="noDataPopup = false">
              Ok!</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      noDataPopup: false,
      loading: false,
      page: 1,
      pageSize: 25,
      manager_id: "",
      marketer_ids: [],
      managersList: [],
      managerWiseMarketers: [],
      value1: [],
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      orderBy: "created_at",
      orderType: "DESC",
      shortcuts: [
        {
          text: "Today",
          value: [new Date(), new Date()],
        },
        {
          text: "Yesterday",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            return [start, end];
          },
        },
        {
          text: "Last 30 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 30);
            return [start, end];
          },
        },
        {
          text: "This month",
          value: () => {
            const end = new Date();
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            );
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            return [start, end];
          },
        },
        {
          text: "Last 6 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          },
        },
        {
          text: "Last 9 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 9);
            return [start, end];
          },
        },
        {
          text: "Last year",
          value: () => {
            const end = new Date(new Date().getFullYear() - 1, 11, 31);
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            return [start, end];
          },
        },
        {
          text: "This year",
          value: () => {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            return [start, end];
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("revenueStats", [
      "getIndividualRevenueStats",
      "getManagerWiseMarketers",
    ]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    checkFiltersData() {
      if (this.manager_id && this.value1 && this.value1.length) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    // await this.fetchAllStats();
    // await this.fetchAllMarketers();
    this.$store.commit("revenueStats/setIndividualRevenueStats", []);
    await this.fetchHospitalsData();

    await this.fetchAllMarketingManagers();
  },
  methods: {
    calculatePending() {
      if (
        this.getIndividualRevenueStats &&
        this.getIndividualRevenueStats.data
      ) {
        return this.getIndividualRevenueStats.data
          .reduce((total, row) => total + row.pending_amount, 0)
          .toLocaleString("en-US");
      }
      return 0;
    },
    calculatePaid() {
      if (
        this.getIndividualRevenueStats &&
        this.getIndividualRevenueStats.data
      ) {
        return this.getIndividualRevenueStats.data
          .reduce((total, row) => total + row.paid_amount, 0)
          .toLocaleString("en-US");
      }
      return 0;
    },
    calculateTotal() {
      if (
        this.getIndividualRevenueStats &&
        this.getIndividualRevenueStats.data
      ) {
        return this.getIndividualRevenueStats.data
          .reduce((total, row) => total + row.total_amount, 0)
          .toLocaleString("en-US");
      }
      return 0;
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchAllStats();
    },
    async fetchHospitalsData() {
      try {
        this.loading = true;
        await this.$store.dispatch("hospitals/fetchAllHospitals", {
          get_all: true,
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    getSelectedHospital(hospitalId) {
      if (this.getAllHospitals && this.getAllHospitals.data) {
        return this.getAllHospitals.data
          .filter((hospital) => hospitalId.indexOf(hospital._id) !== -1)
          .flatMap((hospital) => hospital.name)
          .join(", ");
      }
    },
    fetchManagerMarketers() {
      if (this.manager_id) {
        this.fetchManagerWiseMarketers(this.manager_id);
      }
      this.marketer_ids = "";
    },
    clearMarketerData() {
      this.value1 = [];
      this.manager_id = "";
      this.marketer_ids = "";
      this.$store.commit("revenueStats/setIndividualRevenueStats", []);
    },
    async fetchManagerWiseMarketers(manager) {
      try {
        let managerId = manager;
        await this.$store.dispatch(
          "revenueStats/fetchManagerWiseMarketersData",
          managerId
        );
        if (
          this.getManagerWiseMarketers &&
          this.getManagerWiseMarketers.length
        ) {
          this.managerWiseMarketers = this.getManagerWiseMarketers;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getMarketerName(marketer) {
      if (this.getAllUsers && this.getAllUsers.data) {
        let mark =
          this.getAllUsers &&
          this.getAllUsers.data.find((e) => e._id === marketer);
        if (mark) {
          return mark.first_name + " " + mark.last_name;
        }
        return "--";
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllStats();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllStats();
    },
    async fetchAllMarketers() {
      try {
        this.loading = true;
        let params = {
          get_all: true,
          user_type: "MARKETER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.marketersData = this.getAllUsers.data;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchAllMarketingManagers() {
      try {
        this.loading = true;
        let params = {
          get_all: true,
          user_type: "HOSPITAL_MARKETING_MANAGER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.managersList = this.getAllUsers.data;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    uploadFile() {
      this.$router.push({
        name: "RevenueStats",
      });
    },
    async fetchAllStats() {
      try {
        this.loading = true;
        let params = {};
        if (this.manager_id) {
          params.manager_id = this.manager_id;
        }
        if (this.value1 && this.value1.length) {
          params.from_date = this.value1[0] + "T00:00:00.000Z";
          params.to_date = this.value1[1] + "T00:00:00.000Z";
        }
        if (this.marketer_ids && this.marketer_ids.length) {
          params.marketer_ids = [this.marketer_ids];
        }
        if (this.orderBy && this.orderType) {
          params.order_by = this.orderBy;
          params.order_type = this.orderType;
        }
        await this.$store.dispatch(
          "revenueStats/fetchIndividualRevenueStats",
          params
        );
        if (
          this.getIndividualRevenueStats &&
          this.getIndividualRevenueStats.data &&
          this.getIndividualRevenueStats.data.length
        ) {
          this.noDataPopup = false;
        } else {
          this.noDataPopup = true;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    exportTableData() {
      let currentDate = new Date();

      let formattedDate = currentDate.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const modifiedData = this.getIndividualRevenueStats.data.map((item) => {
        // Map original keys to custom headers
        return {
          "ORDERING FACILITY NAME": this.getSelectedHospital(item.hospital),
          "TOTAL AMOUNT": item.total_amount,
          "PAID AMOUNT": item.paid_amount,

          "PENDING AMOUNT": item.pending_amount,
        };
      });
      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(modifiedData);

      const headerRowStyle = {
        fill: { fgColor: { rgb: "8e8e8e" } },
        font: { color: { rgb: "FFFFFF" }, bold: true },
      };

      // Set styles for each header row
      for (let i = 0; i < modifiedData.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: i, c: 0 });
        ws[cellRef].s = headerRowStyle;
      }

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the file
      XLSX.writeFile(wb, `Revenue Stats Individual_${formattedDate}.xlsx`);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
#marketer-stats {
  font-family: "Poppins", sans-serif;
  .marketers-navbar {
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    z-index: 100;
    background-color: #fff;
    .el-row {
      align-items: center;
    }
    .marketers-menu {
      display: flex;
      li {
        &:not(:first-child) {
          margin-left: 15px;
        }
        a {
          display: inline-flex;
          padding: 0 15px;
          line-height: 36px;
          border-radius: 4px;
          background-color: #f0f2f7;
          font-size: 14.5px;
          font-weight: 500;
          color: #005475;
          border: none;
          white-space: nowrap;
        }
      }
      .router-link-active {
        a {
          display: inline-flex;
          padding: 0 15px;
          line-height: 36px;
          font-size: 14.5px;
          font-weight: 500;
          border: none;
          border-radius: 4px;
          background-color: #005475;
          color: #ffffff !important;
          white-space: nowrap;
        }
      }
    }
    .right-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .justify-start {
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }
      ul {
        display: flex;
        align-items: center;

        li {
          .date-picker {
            max-width: 300px;
          }
          .el-range-editor.el-input__inner {
            height: 36px;
            padding: 0 10px;
          }
          .el-select .el-input__inner {
            height: 36px;
            padding: 0 10px;
          }
          &:not(:first-child) {
            margin-left: 20px;
          }
        }
        .search-with-filter-input {
          position: relative;

          .search-icon {
            position: absolute;
            left: 6px;
            top: 0;
            bottom: 0;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
            }
          }

          .el-select {
            height: 36px;

            .el-input__inner {
              height: 35px;
              background: #fff;
              border: 1px solid hsl(0, 0%, 88%);
              box-shadow: none;
              padding-left: 30px;
              font-weight: 500;
              color: #000;
            }

            .el-input__suffix {
              background: #273480;
              border-radius: 0 5px 5px 0;
              right: 0;
              height: 98%;

              .el-icon {
                color: #fff !important;
                font-weight: 800;
              }
            }
          }
          &.trigger-drowpdown {
            .select-trigger {
              .el-select__input {
                margin: 0;
                padding-left: 8px;
              }
              .el-input__inner {
                padding-left: 8px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .combined-marketers-table {
    padding: 0.5rem 2rem;
    border-radius: 15px;
    background-color: #ffffff;
    &.individual {
      .el-table__body-wrapper {
        width: 100%;
        /* width */
        &::-webkit-scrollbar {
          width: 8px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: #b8c4cc;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      .el-table {
        background-color: transparent !important;
        table-layout: fixed;
        overflow: initial;
        table {
          border-collapse: separate;
          border-spacing: 0 5px;
          thead {
            tr {
              border: none;
              th {
                &.el-table__cell {
                  padding: 0;
                  border: none;
                }
                .cell {
                  color: #878790;
                  font-weight: 500;
                  line-height: 21px;
                  letter-spacing: 0.01em;
                  padding: 6px 10px !important;
                  text-transform: uppercase;
                }
              }
            }
            .total-count {
              color: #eb5757;
              font-weight: 600;
              font-size: 15px;
              &.paid {
                color: #4bb543;
              }
              &.total {
                color: #5d5fef;
              }
            }
            .header {
              position: relative;
            }
          }
          tbody {
            tr {
              border: 0.633951px solid #e0e0e0;
              td {
                &:first-child {
                  border-left: 0.633951px solid #e0e0e0 !important;
                  border-radius: 6.5px 0 0 6.5px;
                }

                &:last-child {
                  border-right: 0.633951px solid #e0e0e0 !important;
                  border-radius: 0 6.5px 6.5px 0;
                }

                &.el-table__cell {
                  padding: 0;
                  border: 0.633951px solid #e0e0e0;
                  border-right: none;
                  border-left: none;
                }

                .cell {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  overflow: hidden;
                  padding: 5px 10px !important;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 19px;
                  letter-spacing: 0.01em;
                  text-overflow: ellipsis;
                  text-transform: initial;
                  white-space: pre-wrap;
                }
                .amount {
                  color: #eb5757;
                  border-radius: 6px;
                  font-weight: 600;

                  &.total {
                    color: #5d5fef;
                  }
                  &.paid {
                    color: #4bb543;
                  }
                }
                .marketer-name {
                  color: #bf1b39;
                }

                .el-checkbox {
                  padding: 0 3px;
                }
              }
            }
          }
        }
        .caret-wrapper {
          position: absolute;
          top: 10px;
          left: 44%;
        }
        &::before {
          display: none;
        }
      }
      .el-table--border th.el-table__cell {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-right: 1px solid rgba(0, 0, 0, 0.15);
      }
      .el-table--border::after,
      .el-table--group::after {
        display: none;
      }
    }
  }
}
.el-picker-panel {
  &__sidebar {
    width: 125px !important;
  }
  &__shortcut:hover {
    color: #005475 !important;
    font-weight: 500 !important;
  }
}
</style>
