<template>
  <section class="all-cases-view" id="marketer-stats" v-loading="loading">
    <div class="container-default">
      <div class="marketers-navbar">
        <el-row type="flex">
          <el-col :lg="24">
            <div class="right-block">
              <ul class="justify-start">
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="manager_id"
                      placeholder="Select Manager"
                      @change="fetchManagerMarketers"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="(manager, index) in managersList"
                        :key="index"
                        :label="manager.first_name + ' ' + manager.last_name"
                        :value="manager._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li>
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="marketer_ids"
                      placeholder="Select Territory Manager"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="(marketer, index) in managerWiseMarketers"
                        :key="index"
                        :label="marketer.first_name + ' ' + marketer.last_name"
                        :value="marketer._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <div class="date-picker">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      :shortcuts="shortcuts"
                      :disabled-date="disabledDate"
                      format="MM-DD-YYYY"
                      value-format="YYYY-MM-DD"
                    >
                    </el-date-picker>
                  </div>
                </li>
                <li>
                  <el-button
                    class="btn button-with-icon with-blue-bg"
                    @click="fetchMarketers"
                    :disabled="checkFiltersData"
                  >
                    GET DATA
                  </el-button>
                </li>
                <li>
                  <el-button
                    class="btn button-with-icon with-blue-bg"
                    @click="clearFilters()"
                    :disabled="checkFiltersData"
                  >
                    CLEAR
                  </el-button>
                </li>
                <li v-if="this.manager_id && this.value1 && this.value1.length">
                  <el-button
                    class="btn button-with-icon"
                    @click="exportTableData"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/export-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>Export</span>
                  </el-button>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="primary-content-view-new">
        <div class="marketers-table camparision" id="all-cases-listings">
          <el-table
            :data="this.usersList"
            height="calc(100vh - 235px)"
            @sort-change="onChangeSort"
          >
            <el-table-column type="index" label="S No." width="100" />
            <el-table-column prop="date" label="MARKETER NAME" width="350">
              <template #default="scope">
                <div class="name-accession">
                  <p class="marketer-name">
                    {{ scope.row.first_name + " " + scope.row.last_name }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(head, index) in tableFilterCategoryHeaders"
              :key="index"
              :label="getDate(head.label)"
              :class="{ active: head.label, 'in-active': !head.label }"
            >
              <el-table-column
                width="175"
                :prop="item.value"
                :label="item.label"
                v-for="(item, index) in head.tableHeader"
                :key="index"
                sortable="custom"
              >
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog top="10%" width="35%" v-model="noDataPopup">
        <div class="dialog-header">
          <span class="header-part"> Warning! </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="noDataPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date text-center">
          <img src="@/assets/images/icons/cases/warning-icon.svg" alt="icon" />
        </div>

        <h2 class="text-center">No Data Found With This Filter!</h2>
        <div class="popup-date text-center">
          <h3>Please Select Any Filter</h3>

          <div class="dialog-footer justify-center">
            <el-button class="blue-save-button" @click="noDataPopup = false">
              Ok!</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
// import { jsontoexcel } from "vue-table-to-excel";
import * as XLSX from "xlsx";
import FilterHelper from "@/mixins/FilterHelper";
export default {
  data() {
    return {
      layout: "div",
      usersList: [],
      managersList: [],
      manager_id: "",
      marketer_ids: [],
      managerWiseMarketers: [],
      loading: false,
      orderBy: "total_amount",
      orderType: "desc",
      page: 1,
      pageSize: 25,
      newMarketersList: [],
      value1: [],
      noDataPopup: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      shortcuts: [
        {
          text: "Today",
          value: [new Date(), new Date()],
        },
        {
          text: "Yesterday",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            return [start, end];
          },
        },
        {
          text: "Last 30 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 30);
            return [start, end];
          },
        },
        {
          text: "This month",
          value: () => {
            const end = new Date();
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            );
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            return [start, end];
          },
        },
        {
          text: "Last 6 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          },
        },
        {
          text: "Last 9 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 9);
            return [start, end];
          },
        },
        {
          text: "Last year",
          value: () => {
            const end = new Date(new Date().getFullYear() - 1, 11, 31);
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            return [start, end];
          },
        },
        {
          text: "This year",
          value: () => {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            return [start, end];
          },
        },
      ],
      tableHeader: [],
      tableFilterCategoryHeaders: [],
    };
  },
  mixins: [FilterHelper],
  computed: {
    ...mapGetters("revenueStats", [
      "getAllRevenueStats",
      "getManagerWiseMarketers",
    ]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    checkFiltersData() {
      if (this.manager_id && this.value1 && this.value1.length) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.fetchMarketerAccounts();
    await this.fetchManagerAccounts();
  },
  methods: {
    getDate(dateString) {
      const startDate = new Date(dateString.split("_")[0]);
      const endDate = new Date(dateString.split("_")[1]);
      const formattedStartDate = this.formatDate(startDate);
      const formattedEndDate = this.formatDate(endDate);
      return `${formattedStartDate} to ${formattedEndDate}`;
    },
    formatDate(date) {
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = date.getUTCDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    fetchManagerMarketers() {
      if (this.manager_id) {
        this.fetchManagerWiseMarketers(this.manager_id);
      }
      this.marketer_ids = "";
    },
    clearFilters() {
      this.manager_id = "";
      this.marketer_ids = [];
      this.tableHeader = [];
      this.value1 = [];
      this.tableFilterCategoryHeaders = [];
      this.fetchMarketerAccounts();
    },
    onChangeSort(sort) {
      const { prop: sortProp, order: sortOrder } = sort;
      this.usersList.sort((a, b) => {
        const aValue = a[sortProp];
        const bValue = b[sortProp];
        if (aValue === bValue) {
          return 0;
        }
        if (sortOrder === "ascending") {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    },

    async fetchManagerWiseMarketers(manager) {
      try {
        let managerId = manager;
        await this.$store.dispatch(
          "revenueStats/fetchManagerWiseMarketersData",
          managerId
        );
        if (
          this.getManagerWiseMarketers &&
          this.getManagerWiseMarketers.length
        ) {
          this.managerWiseMarketers = this.getManagerWiseMarketers;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMarketerAccounts() {
      try {
        let params = {
          get_all: true,
          user_type: "MARKETER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.usersList = this.getAllUsers.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchManagerAccounts() {
      try {
        let params = {
          get_all: true,
          user_type: "HOSPITAL_MARKETING_MANAGER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.managersList = this.getAllUsers.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMarketers() {
      try {
        this.loading = true;
        let params = {};
        if (this.manager_id) {
          // await this.fetchMarketerAccounts(); // wait for fetchMarketerAccounts to complete
          await this.fetchManagerWiseMarketers(this.manager_id);
          params.manager_id = this.manager_id;
        }
        if (this.marketer_ids && this.marketer_ids.length) {
          params.marketer_ids = [this.marketer_ids];
        }
        if (this.value1 && this.value1.length) {
          params.from_date = this.value1[0] + "T00:00:00.000Z";
          params.to_date = this.value1[1] + "T00:00:00.000Z";
        }
        await this.$store.dispatch("revenueStats/fetchRevenueStats", params);
        let keyPrefix = params.from_date + "_" + params.to_date;
        if (
          this.getAllRevenueStats &&
          this.getAllRevenueStats.data &&
          this.getAllRevenueStats.data.length
        ) {
          this.noDataPopup = false;
          let managerWiseMarketerIds = this.managerWiseMarketers.map(
            (marketer) => marketer._id
          );
          if (this.manager_id && this.marketer_ids) {
            this.usersList = this.usersList.filter(
              (user) => user._id === this.marketer_ids
            );
          } else if (this.manager_id) {
            this.usersList = this.usersList.filter((user) =>
              managerWiseMarketerIds.includes(user._id)
            );
          } else {
            this.usersList = this.usersList;
          }
          this.usersList = this.usersList.map((e) => {
            let exists = this.getAllRevenueStats.data.find(
              (item) => item.marketer_id == e._id
            );

            if (exists) {
              e[
                `${keyPrefix}_pending_amount`
              ] = `$ ${exists._sum.pending_amount}`;
              e[`${keyPrefix}_paid_amount`] = `$ ${exists._sum.paid_amount}`;

              e[`${keyPrefix}_total_amount`] = `$ ${exists._sum.total_amount}`;

              //   e[`${keyPrefix}_pending_cases`] = exists._sum.pending_cases;
              //   e[`${keyPrefix}_completed_cases`] = exists._sum.completed_cases;
            } else {
              e[`${keyPrefix}_pending_amount`] = `$ ${0}`;
              e[`${keyPrefix}_paid_amount`] = `$ ${0}`;

              e[`${keyPrefix}_total_amount`] = `$ ${0}`;
              //   e[`${keyPrefix}_pending_cases`] = 0;
              //   e[`${keyPrefix}_completed_cases`] = 0;
            }
            return e;
          });
        } else if (
          this.getAllRevenueStats &&
          this.getAllRevenueStats.data &&
          !this.getAllRevenueStats.data.length
        ) {
          this.usersList = this.usersList.map((e) => {
            e[`${keyPrefix}_pending_amount`] = 0;
            e[`${keyPrefix}_paid_amount`] = 0;

            e[`${keyPrefix}_total_amount`] = 0;
            // e[`${keyPrefix}_pending_cases`] = 0;
            // e[`${keyPrefix}_completed_cases`] = 0;
            return e;
          });

          this.noDataPopup = true;
        } else {
          this.noDataPopup = true;
        }

        if (params.from_date && params.to_date) {
          let pendingKey = `${keyPrefix}_pending_amount`;
          let paidKey = `${keyPrefix}_paid_amount`;

          let totakKey = `${keyPrefix}_total_amount`;
          let categoryHeader = this.tableFilterCategoryHeaders.find(
            (category) => category.label === keyPrefix
          );
          if (!categoryHeader) {
            this.tableFilterCategoryHeaders.push({
              label: keyPrefix,
              tableHeader: [
                {
                  label: "PENDING AMOUNT",
                  value: pendingKey,
                },
                {
                  label: "PAID AMOUNT",
                  value: paidKey,
                },
                {
                  label: "TOTAL AMOUNT",
                  value: totakKey,
                },
              ],
            });
          }
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    exportTableData() {
      let currentDate = new Date();

      let formattedDate = currentDate.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const modifiedData = this.usersList.map((item) => {
        const exportData = {};

        // Extract all keys with "_pending_amount" and "_total_amount"
        const pendingCountKeys = Object.keys(item).filter((key) =>
          key.includes("_pending_amount")
        );
        const paidCountKeys = Object.keys(item).filter((key) =>
          key.includes("_paid_amount")
        );
        const totalCountKeys = Object.keys(item).filter((key) =>
          key.includes("_total_amount")
        );

        // Check if the user has data for "NO OF FACILITIES" and "TOTAL CASES"
        if (pendingCountKeys.length > 0) {
          // Include "MARKETER NAME" only once
          exportData["MARKETER NAME"] = item.first_name + " " + item.last_name;
        }

        // Iterate over the keys and extract values
        pendingCountKeys.forEach((pendingCountKey, index) => {
          const pendingKey = item[pendingCountKey];
          const paidKey = paidCountKeys[index];
          const noOfPaidAmount = paidKey ? item[paidKey] : null;
          const totalCountKey = totalCountKeys[index];
          const noOfCases = totalCountKey ? item[totalCountKey] : null;

          // Add the values to exportData
          exportData[`PENING AMOUNT_${index + 1}`] = pendingKey;
          exportData[`PAID AMOUNT_${index + 1}`] = noOfPaidAmount;

          exportData[`TOTAL AMOUNT_${index + 1}`] = noOfCases;
        });

        return exportData;
      });

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(modifiedData);

      const headerRowStyle = {
        fill: { fgColor: { rgb: "8e8e8e" } },
        font: { color: { rgb: "FFFFFF" }, bold: true },
      };

      // Set styles for header row
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: 0 });
      const cell = ws[cellRef] || {};
      cell.s = headerRowStyle;
      ws[cellRef] = cell;

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the file
      XLSX.writeFile(wb, `Volume Stats Combined_${formattedDate}.xlsx`);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
#marketer-stats {
  font-family: "Poppins", sans-serif;
  .marketers-navbar {
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    z-index: 100;
    background-color: #fff;
    .el-row {
      align-items: center;
    }
    .marketers-menu {
      display: flex;
      li {
        &:not(:first-child) {
          margin-left: 15px;
        }
        a {
          display: inline-flex;
          padding: 0 15px;
          line-height: 36px;
          border-radius: 4px;
          background-color: #f0f2f7;
          font-size: 14.5px;
          font-weight: 500;
          color: #005475;
          border: none;
          white-space: nowrap;
        }
      }
      .router-link-active {
        a {
          display: inline-flex;
          padding: 0 15px;
          line-height: 36px;
          font-size: 14.5px;
          font-weight: 500;
          border: none;
          border-radius: 4px;
          background-color: #005475;
          color: #ffffff !important;
          white-space: nowrap;
        }
      }
    }
    .right-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .justify-start {
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }
      ul {
        display: flex;
        align-items: center;

        li {
          .date-picker {
            max-width: 300px;
          }
          .el-range-editor.el-input__inner {
            height: 36px;
            padding: 0 10px;
          }
          .el-select .el-input__inner {
            height: 36px;
            padding: 0 10px;
          }
          &:not(:first-child) {
            margin-left: 20px;
          }
        }
        .search-with-filter-input {
          position: relative;

          .search-icon {
            position: absolute;
            left: 6px;
            top: 0;
            bottom: 0;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
            }
          }

          .el-select {
            height: 36px;

            .el-input__inner {
              height: 35px;
              background: #fff;
              border: 1px solid hsl(0, 0%, 88%);
              box-shadow: none;
              padding-left: 30px;
              font-weight: 500;
              color: #000;
            }

            .el-input__suffix {
              background: #273480;
              border-radius: 0 5px 5px 0;
              right: 0;
              height: 98%;

              .el-icon {
                color: #fff !important;
                font-weight: 800;
              }
            }
          }
          &.trigger-drowpdown {
            .select-trigger {
              .el-select__input {
                margin: 0;
                padding-left: 8px;
              }
              .el-input__inner {
                padding-left: 8px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .marketers-table {
    padding: 0.5rem 2rem;
    border-radius: 15px;
    background-color: #ffffff;
    &.camparision {
      .el-table__body-wrapper {
        width: 100%;
        /* width */
        &::-webkit-scrollbar {
          width: 8px;
          height: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: #b8c4cc;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      .el-table {
        background-color: transparent !important;
        table-layout: fixed;
        overflow: initial;
        table {
          border-collapse: separate;
          border-spacing: 0 5px;
          thead {
            tr {
              border: none;

              th {
                &.el-table__cell {
                  padding: 0;
                  border: none;
                }
                &.el-table_1_column_3 {
                  text-align: center;
                  .cell {
                    color: #5d5fef !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                &.el-table_1_column_7 {
                  text-align: center;

                  .cell {
                    color: #eb5757 !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                &.el-table_1_column_11 {
                  text-align: center;

                  .cell {
                    color: #4bb543 !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                &.el-table_1_column_15 {
                  text-align: center;

                  .cell {
                    color: #f28b0c !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                &.el-table_1_column_19 {
                  text-align: center;
                  .cell {
                    color: #5d5fef !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                &.el-table_1_column_23 {
                  text-align: center;
                  .cell {
                    color: #eb5757 !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                &.el-table_1_column_27 {
                  text-align: center;
                  .cell {
                    color: #4bb543 !important;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                .cell {
                  color: #878790;
                  font-weight: 500;
                  line-height: 21px;
                  letter-spacing: 0.01em;
                  padding: 6px 10px !important;
                  text-transform: uppercase;
                }
              }
            }
            .total-count {
              color: #eb5757;
              font-weight: 600;
              font-size: 15px;
              &.paid {
                color: #4bb543;
              }
              &.total {
                color: #5d5fef;
              }
            }
            .header {
              position: relative;
            }
          }
          tbody {
            tr {
              border: 0.633951px solid #e0e0e0;
              td {
                &:first-child {
                  border-left: 0.633951px solid #e0e0e0 !important;
                  border-radius: 6.5px 0 0 6.5px;
                }

                &:last-child {
                  border-right: 0.633951px solid #e0e0e0 !important;
                  border-radius: 0 6.5px 6.5px 0;
                }

                &.el-table__cell {
                  padding: 0;
                  border: 0.633951px solid #e0e0e0;
                  border-right: none;
                  border-left: none;
                }

                .cell {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  overflow: hidden;
                  padding: 6px 10px !important;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 19px;
                  letter-spacing: 0.01em;
                  text-overflow: ellipsis;
                  text-transform: initial;
                  white-space: pre-wrap;
                }
                .amount {
                  color: #eb5757;
                  border-radius: 6px;
                  font-weight: 600;
                  position: relative;
                  right: 3px;

                  &.total {
                    color: #5d5fef;
                  }
                  &.paid {
                    color: #4bb543;
                  }
                }
                .marketer-name {
                  color: #bf1b39;
                }
              }
            }
          }
        }

        &::before {
          display: none;
        }
      }
      .el-table--border th.el-table__cell {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-right: 1px solid rgba(0, 0, 0, 0.15);
      }
      .el-table thead.is-group th.el-table__cell {
        background: transparent;
      }
      .el-table--border,
      .el-table--group {
        border: none;
      }
      .el-table--border::after,
      .el-table--group::after {
        display: none;
      }
    }
  }
}
.el-picker-panel {
  &__sidebar {
    width: 125px !important;
  }
  &__shortcut:hover {
    color: #005475 !important;
    font-weight: 500 !important;
  }
}
</style>
