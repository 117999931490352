<template>
  <section class="selected-test-preview" id="toxicology-tests-preview">
    <div class="vue-tabs">
      <el-tabs v-if="getToxicActiveTab">
        <el-tab-pane
          v-if="showTab('TOXICOLOGY', ['SCREENING', 'SCREENING_VALIDITY'])"
        >
          <template #label>
            <div class="custom-tabs-label">
              <span @click="showScreenTests"> Screening </span>
              <el-button
                class="custom-tab-close-btn"
                @click="removeTab"
                v-if="notCreateCase && isBillingUser && !isReleasePending"
                :disabled="hasCases"
              >
                <img
                  src="@/assets/images/fax-tag-close.svg"
                  alt="icon"
                  width="30"
                />
              </el-button>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane
          v-if="
            showTab(
              'TOXICOLOGY',
              ['SCREENING', 'ETG/ETS', 'SCREENING_VALIDITY','D_L_Isomer'],
              false
            )
          "
        >
          <template #label>
            <div class="custom-tabs-label">
              <span @click="showConfirmationTests"> Confirmation </span>
              <el-button
                class="custom-tab-close-btn"
                @click="removeConfirmationTab"
                v-if="notCreateCase && isBillingUser && !isReleasePending"
                :disabled="hasCases"
              >
                <img
                  src="@/assets/images/fax-tag-close.svg"
                  alt="icon"
                  width="30"
                />
              </el-button>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="showTab('TOXICOLOGY', ['ETG/ETS'])">
          <template #label>
            <div class="custom-tabs-label">
              <span @click="showEtgEtsTests"> ETG/ETS </span>
              <el-button
                class="custom-tab-close-btn"
                @click="removeEtgTab"
                v-if="notCreateCase && isBillingUser && !isReleasePending"
                :disabled="hasCases"
              >
                <img
                  src="@/assets/images/fax-tag-close.svg"
                  alt="icon"
                  width="30"
                />
              </el-button>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="showTab('TOXICOLOGY', ['D_L_Isomer'])">
          <template #label>
            <div class="custom-tabs-label">
              <span @click="showDLIsomer"> D & L Isomer</span>
              <el-button
                class="custom-tab-close-btn"
                @click="removeDLIsomerTab"
                v-if="notCreateCase && isBillingUser && !isReleasePending"
                :disabled="hasCases"
              >
                <img
                  src="@/assets/images/fax-tag-close.svg"
                  alt="icon"
                  width="30"
                />
              </el-button>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="default-modal" v-if="screeningTestsPopup">
      <el-dialog v-model="screeningTestsPopup">
        <div class="dialog-header">
          <span class="header-part">Screening</span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="screeningTestsPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-panels-blocks">
          <ul>
            <li
              class="toxicology-text-panels"
              v-for="(category, index) of getCaseCategoryBasedData(
                test.case_type_code,
                ['SCREENING', 'SCREENING_VALIDITY']
              )"
              :key="index"
            >
              <h3 class="test-group-title">{{ category.category }}</h3>
              <ul class="check-list-woundpanel">
                <li
                  v-for="(item, index) of getCategoryWiseTestsData(
                    category.case_type_code,
                    category.category_code
                  )"
                  :key="index"
                >
                  <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                    <el-checkbox :label="item._id" :value="item" disabled>{{
                      item.name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
    <div class="default-modal" v-if="confirmationTestsPopup">
      <el-dialog v-model="confirmationTestsPopup">
        <div class="dialog-header">
          <span class="header-part">Confirmation</span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="confirmationTestsPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-panels-blocks">
          <ul>
            <li
              class="toxicology-text-panels"
              v-for="(category, index) of getCaseCategoryBasedData(
                test.case_type_code,
                ['SCREENING', 'ETG/ETS', 'SCREENING_VALIDITY','D_L_Isomer'],
                false
              )"
              :key="index"
            >
              <h3 class="test-group-title">{{ category.category }}</h3>
              <ul class="check-list-woundpanel">
                <li
                  v-for="(item, index) of getCategoryWiseTestsData(
                    category.case_type_code,
                    category.category_code
                  )"
                  :key="index"
                >
                  <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                    <el-checkbox :label="item._id" :value="item" disabled>{{
                      item.name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
    <div class="default-modal" v-if="etgetsTestsPopup">
      <el-dialog v-model="etgetsTestsPopup">
        <div class="dialog-header">
          <span class="header-part">ETG/ETS</span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="etgetsTestsPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-panels-blocks">
          <ul class="check-list-check-list-default">
            <li
              class="toxicology-text-panels"
              v-for="(category, index) of getCaseCategoryBasedData(
                test.case_type_code,
                ['ETG/ETS']
              )"
              :key="index"
            >
              <h3 class="test-group-title">
                {{ category.category }}
              </h3>
              <ul class="check-list-toxicology">
                <li
                  v-for="(item, index) of getCategoryWiseTestsData(
                    category.case_type_code,
                    category.category_code
                  )"
                  :key="index"
                >
                  <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                    <el-checkbox :label="item._id" :value="item" disabled>{{
                      item.name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
    <div class="default-modal" v-if="dlIsomerTestsPopup">
      <el-dialog v-model="dlIsomerTestsPopup">
        <div class="dialog-header">
          <span class="header-part">D & L Isomer</span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="dlIsomerTestsPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-panels-blocks">
          <ul>
            <li
              class="toxicology-text-panels"
              v-for="(category, index) of getCaseCategoryBasedData(
                test.case_type_code,
                ['D_L_Isomer']
              )"
              :key="index"
            >
              <h3 class="test-group-title">{{ category.category }}</h3>
              <ul class="check-list-woundpanel">
                <li
                  v-for="(item, index) of getCategoryWiseTestsData(
                    category.case_type_code,
                    category.category_code
                  )"
                  :key="index"
                >
                  <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                    <el-checkbox :label="item._id" :value="item" disabled>{{
                      item.name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import { mapGetters } from "vuex";
export default {
  mixins: [UserPermissionsHelper],
  props: ["panelTestsData", "test", "allSelectedTests", "newCase"],
  data() {
    return {
      toxicTestsLoading: false,
      panelsTestsData: "",
      allTests: "",
      caseRouteNames: [
        "NewCase",
        "Case-Information-Update",
        "NewOrder",
        "Edit-Order-Info",
        "PendingCasesEdit",
        "Concurrent-Case-Information-Update",
      ],
      screeningTestsPopup: false,
      confirmationTestsPopup: false,
      etgetsTestsPopup: false,
      dlIsomerTestsPopup: false,
    };
  },
  async mounted() {
    this.panelsTestsData = this.panelTestsData;
    this.allTests = this.allSelectedTests;
    this.toxicTestsLoading = true;
    try {
      await this.$store.dispatch("labTests/fetchAllLabTests", {
        get_all: true,
        case_type_code: ["TOXICOLOGY"],
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
    this.toxicTestsLoading = false;
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    hasCases() {
      if (this.checkUser && !this.hasAllowedCaseType && !this.newCase) {
        return true;
      } else if (this.checkUser && this.hasAllowedCaseType && this.newCase) {
        return true;
      }
      return false;
    },
    checkUser() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type == "LAB_TECHNOLOGIST"
      ) {
        return true;
      }
      return false;
    },
    hasAllowedCaseType() {
      if (
        this.getAuthenticatedUser.allowed_case_types &&
        this.getAuthenticatedUser.allowed_case_types.length
      ) {
        if (
          this.getCaseInformation &&
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.length
        ) {
          const cases = this.getCaseInformation.case_types;
          const casetypes = this.getAuthenticatedUser.allowed_case_types;

          if (cases.every((item) => casetypes.includes(item))) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    ...mapGetters("cases", ["getCaseInformation"]),
    selectedTestsDetails() {
      const data = [];
      if (this.panelsTestsData.selectedTestIds) {
        this.panelsTestsData.selectedTestIds.forEach((el) => {
          if (el && this.getTestsInformationObject[el]) {
            data.push(this.getTestsInformationObject[el]);
          }
        });
      }
      return data;
    },
    getTestsInformationObject() {
      const obj = {};
      if (this.allTests) {
        this.allTests.forEach((el) => {
          obj[el._id] = el;
        });
      }
      return obj;
    },
    getToxicActiveTab() {
      let toxic = this.selectedTestsDetails.filter(
        (x) => x.case_type_code == "TOXICOLOGY"
      );

      if (toxic.length) {
        let screeningFound = toxic.find(
          (test) => test.category.indexOf("Screening") > -1
        );
        let confirmationFound = toxic.find(
          (test) =>
            test.category.indexOf("Screening") == -1 &&
            test.category.indexOf("ETG/ETS") == -1
        );
        let etgEtsFound = toxic.find(
          (test) => test.category.indexOf("ETG/ETS") > -1
        );

        if (screeningFound) {
          return "screening";
        } else if (confirmationFound) {
          return "confirmation";
        } else if (etgEtsFound) {
          return "ETG/ETS";
        }
      }

      return "screening";
    },
    notCreateCase() {
      if (this.caseRouteNames.indexOf(this.$route.name) != -1) {
        return true;
      }
      return false;
    }
  },
  methods: {
    showDLIsomer() {
      this.dlIsomerTestsPopup = true;
    },
    showScreenTests() {
      this.screeningTestsPopup = true;
    },
    showConfirmationTests() {
      this.confirmationTestsPopup = true;
    },
    showEtgEtsTests() {
      this.etgetsTestsPopup = true;
    },
    removeTab() {
      let screening = ["SCREENING", "SCREENING_VALIDITY"];

      this.$confirm(
        `Are you sure want to remove this
        <br/>
        <b style="color:#0A5DA6">"SCREENING"</b> test type ?`,
        "Warning",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (var i = 0; i < this.allTests.length; i++) {
          var obj = this.allTests[i];

          if (screening.indexOf(obj.category_code) !== -1) {
            this.allTests.splice(i, 1);
            i--;
          }
        }
      });
    },

    removeConfirmationTab() {
      let confirmation = ["SCREENING", "ETG/ETS", "SCREENING_VALIDITY"];

      this.$confirm(
        `Are you sure want to remove this
        <br/>
        <b style="color:#0A5DA6">"CONFIRMATION"</b> test type ?`,
        "Warning",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (var i = 0; i < this.allTests.length; i++) {
          var obj = this.allTests[i];

          if (confirmation.indexOf(obj.category_code) == -1) {
            this.allTests.splice(i, 1);
            i--;
          }
        }
      });
    },
    removeEtgTab() {
      this.$confirm(
        `Are you sure want to remove this
        <br/>
        <b style="color:#0A5DA6">"ETG/ETS"</b> test type ?`,
        "Warning",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (var i = 0; i < this.allTests.length; i++) {
          var obj = this.allTests[i];

          if (obj.category_code === "ETG/ETS") {
            this.allTests.splice(i, 1);
            i--;
          }
        }
      });
    },
    removeDLIsomerTab() {
      this.$confirm(
        `Are you sure want to remove this
        <br/>
        <b style="color:#0A5DA6">"D & L Isomer"</b> test type ?`,
        "Warning",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (var i = 0; i < this.allTests.length; i++) {
          var obj = this.allTests[i];

          if (obj.category_code === "D_L_Isomer") {
            this.allTests.splice(i, 1);
            i--;
          }
        }
      });
    },
    getCategoryWiseTestsData(caseType, category) {
      const data = this.selectedTestsDetails.filter(
        (el) => el.case_type_code === caseType && el.category_code === category
      );
      return data;
    },
    showTab(caseType, category, include = true) {
      if (include) {
        return this.selectedTestsDetails.filter(
          (el) =>
            el.case_type_code === caseType &&
            category.indexOf(el.category_code) != -1
        ).length
          ? true
          : false;
      } else {
        return this.selectedTestsDetails.filter(
          (el) =>
            el.case_type_code === caseType &&
            category.indexOf(el.category_code) == -1
        ).length
          ? true
          : false;
      }
    },
    getCaseCategoryData(caseType) {
      const data = this.selectedTestsDetails.filter(
        (el) => el.case_type_code === caseType
      );
      let flags = [],
        output = [],
        outputWithFullDetails = [],
        l = data.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[data[i].category_code]) continue;
        flags[data[i].category_code] = true;
        output.push(data[i].category_code);
        outputWithFullDetails.push(data[i]);
      }
      return outputWithFullDetails;
    },
    // for toxicology
    getCaseCategoryBasedData(caseType, category, include = true) {
      let data = "";
      if (include) {
        data = this.selectedTestsDetails.filter(
          (el) =>
            el.case_type_code === caseType &&
            category.indexOf(el.category_code) != -1
        );
      } else {
        data = this.selectedTestsDetails.filter(
          (el) =>
            el.case_type_code === caseType &&
            category.indexOf(el.category_code) == -1
        );
      }

      let flags = [],
        output = [],
        outputWithFullDetails = [],
        l = data.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[data[i].category_code]) continue;
        flags[data[i].category_code] = true;
        output.push(data[i].category_code);
        outputWithFullDetails.push(data[i]);
      }
      return outputWithFullDetails;
    },

    updateTestsPanelsData(event) {
      this.loading = true;
      if (event.data) {
        this.panelsTestsData.selectedTestIds = event.data.selectedTestIds;
        this.panelsTestsData.selectedCoriellPanels =
          event.data.selectedCoriellPanels;
        this.allTests = event.allTests;
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
.test-panels-blocks {
  .toxicology-text-panels {
    padding: 10px 0;
    position: relative;
  }
  .toxicology-text-panels:not(:last-child):after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #e8e4e4;
  }
  .check-list-toxicology {
    display: grid;
    grid-template-columns: repeat(5, 19%);
    -moz-column-gap: 10px;
    column-gap: 10px;
    li {
      margin: 7px 0;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    .el-checkbox {
      height: auto;
    }
    .el-checkbox-group {
      .el-checkbox {
        height: 20px;
      }
      .el-checkbox__input .el-checkbox__inner {
        border-color: transparent;
        box-shadow: 0 0 0 1px #ced1d6;
        border-radius: 4px;
        width: 17px;
        height: 17px;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #273480;
        border-color: #ffffff;
        box-shadow: 0 0 0 1px #ced1d6;
        width: 17px;
        height: 17px;
      }
      .el-checkbox__inner::after {
        box-sizing: content-box;
        content: "";
        border: 2px solid var(--el-checkbox-checked-icon-color);
        border-left: 0;
        border-top: 0;
        height: 9px;
        left: 5px;
        position: absolute;
        top: 1px;
        transform: rotate(45deg) scaleY(1);
        width: 3px;
        transition: transform 0.15s ease-in 50ms;
        transform-origin: center;
      }
      .el-checkbox__label {
        color: #34495e;
      }
    }
  }
  .test-group-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }
}
</style>
