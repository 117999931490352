<template>
  <section
    id="new-physician-creation"
    class="case-creation"
    v-loading="loading"
  >
    <div class="container-small-table" v-loading="physicianLoading">
      <div class="case-creation__step case-creation__patient-information">
        <div class="case-creation__card">
          <div class="case-creation__heading">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-patient-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Edit Physician</h3>
            </div>
          </div>
          <div class="case-creation__body pb-0">
            <div class="vue-form">
              <el-form :model="physicianFormData" class="vue-form">
                <el-row :gutter="20">
                  <el-col :xl="5" :lg="5" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          NPI Number
                          <span class="star">*</span>
                        </label>
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <el-input
                            placeholder="Enter NPI Code"
                            v-model="physicianFormData.npi_code"
                            
                          ></el-input>
                          <el-button class="npi-button">
                            <img
                              src="@/assets/images/icons/npi-search.svg"
                              alt="icon"
                            />
                          </el-button>
                        </div>

                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.npi_code
                          "
                        >
                          {{ getPhysicianValidationErrors.npi_code }}
                        </p>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.npi_number
                          "
                        >
                          {{ getPhysicianValidationErrors.npi_number }}
                        </p>
                        <p
                          class="err"
                          v-else-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.error_code
                          "
                        >
                          {{ getPhysicianValidationErrors.error_code }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="4" :lg="4" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          Physician Name
                          <span class="star">*</span>
                        </label>
                        <el-input
                          placeholder="Enter Name"
                          v-model="physicianFormData.name"
                          
                        ></el-input>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.name
                          "
                        >
                          {{ getPhysicianValidationErrors.name }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="4" :lg="4" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          Phone Number
                          <span class="star">*</span>
                        </label>
                        <el-input
                          placeholder="Enter Phone Number"
                          v-model="physicianFormData.phone"
                          maxlength="10"
                          
                        ></el-input>
                        <p
                          class="err error-break"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.phone
                          "
                        >
                          {{ getPhysicianValidationErrors.phone }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"> Email </label>
                        <el-input
                          type="email"
                          class="email-lower"
                          placeholder="Enter Email"
                          v-model="physicianFormData.email"
                        ></el-input>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.email
                          "
                        >
                          {{ getPhysicianValidationErrors.email }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="5" :lg="5" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"> Fax </label>
                        <el-input
                          placeholder="Enter Fax"
                          v-model="physicianFormData.fax"
                          
                        ></el-input>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.fax
                          "
                        >
                          {{ getPhysicianValidationErrors.fax }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="9" :lg="9" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Address Line 1</label>
                        <el-input
                          type="text"
                          placeholder="Enter Address Line 1"
                          v-model="physicianFormData.address.address_line_1"
                        ></el-input>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors[
                              'address.address_line_1'
                            ]
                          "
                        >
                          {{
                            getPhysicianValidationErrors[
                              "address.address_line_1"
                            ]
                          }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="7" :lg="7" :md="12" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Address Line 2</label>
                        <el-input
                          type="text"
                          placeholder="Enter Address Line 2"
                          v-model="physicianFormData.address.address_line_2"
                        ></el-input>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors[
                              'address.address_line_2'
                            ]
                          "
                        >
                          {{
                            getPhysicianValidationErrors[
                              "address.address_line_2"
                            ]
                          }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="3" :lg="3" :md="8" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          Zip
                          <span class="star">*</span>
                        </label>
                        <el-input
                          v-model="physicianFormData.address.zip"
                          
                        ></el-input>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors['address.zip']
                          "
                        >
                          {{ getPhysicianValidationErrors["address.zip"] }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <state
                          :stateData="physicianFormData.address"
                          :validataionErrors="getPhysicianValidationErrors"
                          :errorKey="'address.state'"
                        ></state>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="3" :lg="3" :md="8" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <city
                          :cityData="physicianFormData.address"
                          :validataionErrors="getPhysicianValidationErrors"
                          :errorKey="'address.city'"
                        ></city>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="6" :lg="6" :md="8" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          Ordering Facility
                          <span class="star">*</span>
                        </label>
                        <el-select
                          collapse-tags
                          filterable
                          multiple
                          v-model="physicianFormData.hospitals"
                          placeholder="Select Ordering Facility"
                          @change="getHospitalBranches"
                        >
                          <el-option
                            v-for="(hospital, index) in hospitalsData.data"
                            :key="index"
                            :label="hospital.name"
                            :value="hospital._id"
                            :disabled="isInactiveStatus(hospital)"
                          >
                            <span style="float: left">{{ hospital.name }}</span>
                            <span
                              v-if="isInactiveStatus(hospital)"
                              style="
                                float: right;
                                color: #eb5757;
                                font-size: 13px;
                                padding-left: 10px;
                              "
                            >
                              Inactive
                            </span>
                          </el-option>
                        </el-select>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.hospitals
                          "
                        >
                          {{ getPhysicianValidationErrors.hospitals }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="7" :lg="7" :md="8" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Facility Branch</label>
                        <el-select
                          v-model="physicianFormData.hospital_branches"
                          multiple
                          collapse-tags
                          filterable
                          placeholder="Select Facility Branch"
                        >
                          <el-option
                            v-for="branch in getMultipleHospitalBranches"
                            :key="branch._id"
                            :label="branch.display_name"
                            :value="branch._id"
                          >
                            <span style="float: left">{{
                              branch.display_name
                            }}</span>
                            <span
                              style="
                                float: right;
                                color: #8492a6;
                                font-size: 13px;
                                margin-right: 13px;
                              "
                              >{{ branch.hospital.code }}</span
                            >
                          </el-option>
                        </el-select>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.hospital_branches
                          "
                        >
                          {{ getPhysicianValidationErrors.hospital_branches }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          Specializations
                          <span class="star"></span>
                        </label>
                        <el-select
                          placeholder="Specializations"
                          :clearable="true"
                          :filterable="true"
                          :multiple="true"
                          :allow-create="true"
                          v-model="physicianFormData.specializations"
                          collapse-tags
                          default-first-option
                          
                        >
                          <el-option
                            v-for="(
                              specialization, index
                            ) in physicianFormData.specializations"
                            :key="index"
                            :label="specialization"
                            :value="specialization"
                          ></el-option>
                        </el-select>
                        <p
                          class="err"
                          v-if="
                            getPhysicianValidationErrors &&
                            getPhysicianValidationErrors.specializations
                          "
                        >
                          {{ getPhysicianValidationErrors.specializations }}
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
        <div class="case-creation__card mt-10">
          <div class="hospitals-settings">
            <div class="case-creation__heading">
              <div class="title-with-icon">
                <span class="icon">
                  <img
                    src="@/assets/images/icons/icon-case-info.svg"
                    alt="icon"
                    class="img-fluid"
                  />
                </span>
                <h3 class="title">Physician Settings</h3>
              </div>
            </div>
            <div class="case-creation__body pb-0">
              <div class="radio-buttons-alignments">
                <el-form>
                  <el-row>
                    <el-col :span="8">
                      <div class="case-creation__input">
                        <el-form-item
                          class="text mb-0"
                          label="Auto Fax :"
                          :required="true"
                        >
                          <div class="d-flex">
                            <el-radio
                              v-model="physicianFormData.settings.auto_fax"
                              :value="true"
                              :label="true"
                              >Yes</el-radio
                            >
                            <el-radio
                              v-model="physicianFormData.settings.auto_fax"
                              :value="false"
                              :label="false"
                              >No</el-radio
                            >
                          </div>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="case-creation__input">
                        <el-form-item
                          class="text mb-0"
                          label="Email Notifications :"
                          :required="true"
                        >
                          <div class="d-flex">
                            <el-radio
                              v-model="
                                physicianFormData.settings.email_notifications
                              "
                              :value="true"
                              :label="true"
                              >Yes</el-radio
                            >
                            <el-radio
                              v-model="
                                physicianFormData.settings.email_notifications
                              "
                              :value="false"
                              :label="false"
                              >No</el-radio
                            >
                          </div>
                        </el-form-item>
                      </div>
                    </el-col>
                  </el-row>
                </el-form>
              </div>

              <p
                class="err"
                v-if="
                  getPhysicianValidationErrors &&
                  getPhysicianValidationErrors.critical_error
                "
              >
                {{ getPhysicianValidationErrors.critical_error }}
              </p>
            </div>
          </div>
        </div>

        <div class="case-creation__actions">
          <ul class="list">
            <li>
              <el-button
                type="default"
                class="btn cancel-button"
                @click="cancelPhysicianDetails"
                >Cancel</el-button
              >
            </li>
            <li>
              <el-button
                type="success"
                class="btn save-button"
                @click="submitPhysicianDetails"
                :loading="physicianLoading"
                >Update</el-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";

import { mapGetters } from "vuex";
const state = defineAsyncComponent(() => import("@/components/misc/State"));
const city = defineAsyncComponent(() => import("@/components/misc/City"));
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  data() {
    return {
      physicianLoading: false,
      loading: false,
      physicianInfo: {},
      physicianFormData: {
        npi_code: "",
        name: "",
        phone: "",
        fax: "",
        email: "",
        hospitals: "",
        specializations: [],
        address: {
          address_line_1: "",
          address_line_2: "",
          city: "",
          state: "",
          zip: "",
        },
        hospital_branches: [],
        settings: {
          auto_fax: false,
          email_notifications: false,
        },
      },
      status: "view",
      hospitalsData: {},
    };
  },
  components: {
    state,
    city,
  },
  computed: {
    ...mapGetters("physicians", [
      "getPhysicianAddStatus",
      "getPhysicianValidationErrors",
      "getSinglePhysician",
    ]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("branches", ["getMultipleHospitalBranches"]),
    physician_id() {
      if (this.$route.params.physician_id) {
        return this.$route.params.physician_id;
      }
      return null;
    },
  },
  async mounted() {
    this.$store.dispatch("physicians/clearValidationErrors");
    this.$store.dispatch("help/fetchAllStates");
    await this.fetchPhysicianDetails(this.physician_id);
    await this.$store.dispatch("hospitals/fetchAllHospitals", {
      get_all: true,
    });
    this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
  },
  methods: {
    async fetchPhysicianDetails(physician_id) {
      this.loading = true;
      await this.$store.dispatch("physicians/fetchSinglePhysician", {
        physician_id: physician_id,
        include_hospital_accounts: true,
        include_hospital_details: true,
      });
      this.loading = false;
      this.physicianInfo = JSON.parse(JSON.stringify(this.getSinglePhysician));
      this.physicianInfo.settings = {
        ...this.physicianInfo.settings,
        ...this.getSinglePhysician.settings,
      };
      this.changeStatus();
    },

    async getHospitalBranches() {
      this.physicianFormData.hospital_branches = [];
      await this.$store.dispatch("branches/fetchMultipleHospitalBranches", {
        hospitals: this.physicianFormData.hospitals,
      });
    },

    async changeStatus() {
      this.loading = true;
      this.status = "edit";
      this.$store.dispatch("physicians/clearValidationErrors");

      this.physicianFormData.npi_code = this.physicianInfo.npi_code || "";
      this.physicianFormData.name = this.physicianInfo.name || "";
      this.physicianFormData.phone = this.physicianInfo.phone || "";
      this.physicianFormData.fax = this.physicianInfo.fax || "";
      if (
        this.physicianFormData.phone &&
        this.physicianFormData.phone.includes("-")
      ) {
        this.physicianFormData.phone = this.physicianFormData.phone.replace(
          /-/g,
          ""
        );
      }
      this.physicianFormData.settings = {
        ...this.physicianFormData.settings,
        ...this.physicianInfo.settings,
      };
      this.physicianFormData.email = this.physicianInfo.email || "";
      this.physicianFormData.hospitals =
        this.physicianInfo.hospitals && this.physicianInfo.hospitals.length
          ? this.physicianInfo.hospitals.flatMap((hospital) => hospital._id)
          : [];
      this.physicianFormData.hospital_branches =
        this.physicianInfo.hospital_branches.map((e) => e._id) || [];
      this.physicianFormData.specializations =
        this.physicianInfo.specializations || [];
      if (this.physicianInfo.address) {
        this.physicianFormData.address.address_line_1 =
          this.physicianInfo.address.address_line_1 || "";
        this.physicianFormData.address.address_line_2 =
          this.physicianInfo.address.address_line_2 || "";
        this.physicianFormData.address.city =
          this.physicianInfo.address.city || "";
        this.physicianFormData.address.state =
          this.physicianInfo.address.state || "";
        this.physicianFormData.address.zip =
          this.physicianInfo.address.zip || "";
      } else {
        this.physicianFormData.address.address_line_1 = "";
        this.physicianFormData.address.address_line_2 = "";
        this.physicianFormData.address.city = "";
        this.physicianFormData.address.state = "";
        this.physicianFormData.address.zip = "";
      }
      await this.$store.dispatch("branches/fetchMultipleHospitalBranches", {
        hospitals: this.physicianFormData.hospitals,
      });

      this.loading = false;
    },

    preparePhysicianAddData() {
      if (this.physician_id) {
        this.physicianFormData.id = this.physician_id;
        this.physicianFormData.hospital_branches = this.physicianFormData
          .hospital_branches
          ? this.physicianFormData.hospital_branches
          : undefined;
        if (this.physicianFormData.specializations.length) {
          this.physicianFormData.specializations =
            this.physicianFormData.specializations.map((item) => item.trim());
        }
        return this.physicianFormData;
      }
      if (this.physicianFormData.email) {
        this.physicianFormData.email =
          this.physicianFormData.email.toLowerCase();
      }
      return this.physicianFormData;
    },
    async submitPhysicianDetails() {
      try {
        this.physicianLoading = true;
        const payload = this.preparePhysicianAddData();

        await this.$store.dispatch("physicians/editPhysician", payload);
        this.physicianLoading = false;
        if (this.getPhysicianAddStatus && this.getPhysicianAddStatus.success) {
          successNotification(this.getPhysicianAddStatus.message);

          this.status = "view";
          await this.fetchPhysicianDetails(this.physician_id);
          this.$router.back();
        } else if (this.getPhysicianValidationErrors) {
          this.scrollToFirstError();
        } else if (
          this.getPhysicianValidationErrors &&
          this.getPhysicianValidationErrors.critical_error
        ) {
          errorNotification(this.getPhysicianValidationErrors.critical_error);
        }
      } catch (err) {
        this.physicianLoading = false;
        console.log(err);
      }
    },
    scrollToFirstError() {
      let el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },

    cancelPhysicianDetails() {
      this.$confirm(
        "Are you sure want to Cancel Update. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        await this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="scss">
#new-physician-creation {
  .npi-button {
    border: none;
    padding: 3px 0 0;
    background: none;
    img {
      width: 40px;
      height: 29px;
    }
  }
  .radio-buttons-alignments {
    .el-radio__input .el-radio__inner {
      border-color: transparent;
      box-shadow: 0 0 0 1px #ced1d6;
    }
    .el-radio__input.is-checked .el-radio__inner {
      background-color: #0a5da6;
      border-color: #ffffff;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #0a5da6;
    }
    .case-creation__input .el-form-item {
      margin-top: 10px;
      margin-bottom: 0px !important;
    }
  }
}
</style>
