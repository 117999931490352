<template>
  <section id="all-orders-listing" class="all-cases-view">
    <div class="container-default">
      <div class="listings-navbar">
        <el-row gutter="20">
          <el-col :lg="12">
            <ul class="d-flex list-items-group-15 barcode-print">
              <li
                v-if="
                  selectedCaseIds.length &&
                  hasPermission('generate_order_barcode', 'view')
                "
              >
                <el-button
                  class="btn button-with-icon"
                  auto-insert-space
                  @click="onPrintBarCodes"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/printer-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                    <img
                      class="inactive"
                      src="@/assets/images/printer-icon-black.svg"
                      alt="icon"
                    />
                  </span>
                  <span>PRINT BARCODE</span>
                </el-button>
              </li>
              <li
                v-if="
                  !selectedCaseIds.length &&
                  hasPermission('generate_order_barcode', 'view')
                "
              >
                <el-button
                  class="btn button-with-icon"
                  @click="generateBarcodesDialogVisible = true"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/new-barcode-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                    <img
                      class="inactive"
                      src="@/assets/images/new-barcode-icon.svg"
                      alt="icon"
                    />
                  </span>
                  <span>GENERATE BARCODE</span>
                </el-button>
              </li>
              <li v-if="hasPermission('orders', 'create')">
                <el-button class="btn button-with-icon" @click="addNewOrder">
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/plus-icon.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/plus-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>ADD NEW ORDER</span>
                </el-button>
              </li>
              <li>
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    ref="orderInput"
                    placeholder="Order Id / Patient Name"
                    v-model="search_string"
                    @input="getAutoCompleteOrders()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
              <li v-if="hasPermission('cases', 'create_case')">
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    ref="barcodeInput"
                    placeholder="SCAN QR CODE"
                    v-model="barcodeNumber"
                    @input="getOrderDetails()"
                    :clearable="true"
                    @focus="handleFocus"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </el-col>
          <el-col :lg="12">
            <ul
              class="d-flex flex-wrap justify-content-end list-items-group-15"
            >
              <li>
                <div class="form-element search-with-filter-input-without-icon">
                  <el-select
                    v-model="searchBy"
                    clearable
                    filterable
                    default-first-option
                    :reserve-keyword="false"
                    placeholder="Select Search"
                    @change="clearInputSearch"
                    @clear="clearSearchBy"
                  >
                    <el-option
                      v-for="item in searchOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </li>

              <li v-if="searchBy == 'Patient DOB'">
                <div class="search-with-date-input">
                  <el-date-picker
                    format="MM-DD-YYYY"
                    v-model="date_of_birth"
                    :disabled-date="disabledDate"
                    @change="fetchAllOrders()"
                    placeholder="Select DOB"
                    :clearable="true"
                  ></el-date-picker>
                </div>
              </li>
              <li v-else-if="searchBy == 'patient_name'">
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    ref="patientNameInput"
                    placeholder="Patient Name"
                    v-model="patient_name"
                    @input="getAutoCompleteOrders()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'hospital_marketers'">
                <div class="form-element search-with-filter-input marketers">
                  <el-select
                    v-model="hospital_marketer"
                    placeholder="Select Sales Rep"
                    default-first-option
                    :reserve-keyword="false"
                    filterable
                    clearable
                    @change="handleCurrentChange(1)"
                    @focus="fetchAllUsers"
                    :loading="userLoading"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="(user, index) in marketerData"
                      :key="index"
                      :label="user.first_name + ' ' + user.last_name"
                      :value="user._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'hospital'">
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="hospital"
                    placeholder="Select"
                    filterable
                    clearable
                    default-first-option
                    @change="handleCurrentChange(1)"
                    @focus="fetchAllHospitals"
                    :loading="hospitalsLoading"
                  >
                    <el-option
                      v-for="hospital in hospitalsData.data"
                      :key="hospital"
                      :label="hospital.name"
                      :value="hospital._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'ordering_physician'">
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="physician"
                    placeholder="Select"
                    filterable
                    clearable
                    default-first-option
                    @change="handleCurrentChange(1)"
                    :loading="physiciansLoading"
                    remote
                    :remote-method="remoteMethodPhysician"
                  >
                    <el-option
                      v-for="physician in physiciansData.data"
                      :key="physician"
                      :label="physician.name"
                      :value="physician._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'case_types'">
                <div
                  class="form-element search-with-filter-input"
                  v-if="
                    getAuthenticatedUser.user_type == 'LAB_TECHNOLOGIST' &&
                    getAuthenticatedUser.allowed_case_types
                  "
                >
                  <el-select
                    v-model="caseType"
                    placeholder="Select"
                    filterable
                    clearable
                    @clear="clearTest"
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleCurrentChangeCaseType(1)"
                  >
                    <el-option
                      v-for="caseType in getUserBasedCaseTypes(caseTypes)"
                      :key="caseType"
                      :label="caseType.label"
                      :value="caseType.value"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
                <div class="form-element search-with-filter-input" v-else>
                  <el-select
                    v-model="caseType"
                    placeholder="Select"
                    filterable
                    clearable
                    @clear="clearTest"
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleCurrentChangeCaseType(1)"
                  >
                    <el-option
                      v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                      :key="caseType"
                      :label="caseType.label"
                      :value="caseType.value"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-if="caseType && caseType.length">
                <div
                  v-if="
                    caseType && caseType.length && caseType === 'TOXICOLOGY'
                  "
                  class="form-element search-with-filter-input trigger-drowpdown"
                >
                  <el-select
                    v-model="test_info"
                    placeholder="Select Test Id"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleTest(1)"
                  >
                    <el-option
                      v-for="test in this.testIds"
                      :key="test"
                      :label="test.category"
                      :value="test.tests"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="form-element search-with-filter-input trigger-drowpdown"
                  v-else
                >
                  <el-select
                    v-model="test_info"
                    placeholder="Select Test Id"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleTest(1)"
                  >
                    <el-option
                      v-for="test in this.testIds"
                      :key="test"
                      :label="test.category"
                      :value="test.tests"
                    >
                    </el-option>
                  </el-select>
                </div>
              </li>
              <li>
                <el-button
                  class="btn button-with-icon"
                  @click="onReportsExport"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/export-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>Export</span>
                </el-button>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="container-default" style="padding-top: 0">
      <!-- Web Version -->
      <div class="primary-content-view-new" v-loading="loading">
        <div class="listings listings__table listings__all-cases">
          <el-table
            :data="getAllOrders.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
            @selection-change="handleSelectionChange($event)"
          >
            <el-table-column
              type="selection"
              width="40"
              class="selection-check"
            ></el-table-column>
            <el-table-column
              label="ORDER ID"
              prop="order_id"
              width="280"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <div id="name-accession">
                  <span class="order-files ml-5">
                    <img
                      src="@/assets/images/orders-table-file.svg"
                      alt="icon"
                    />
                  </span>
                  <span
                    id="accession-id"
                    @click="goToOrdersView(scope.row._id)"
                    class="cursor ml-5"
                  >
                    {{ scope.row.order_id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              width="135"
              prop="case_types"
              fixed="left"
              sortable="custom"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}

                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div v-else class="case-type">
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="PATIENT NAME"
              prop="patient_info.first_name"
              min-width="160"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="getPatientNameByInfo(scope.row.patient_info)"
                  @click="
                    copyTitle(getPatientNameByInfo(scope.row.patient_info))
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    getPatientNameByInfo(scope.row.patient_info)
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Patient DOB"
              prop="date_of_birth"
              min-width="100"
            >
              <template #default="scope">
                {{ formatDate(scope.row.patient_info.date_of_birth) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="hospitals"
              label="ORDERING FACILITIES"
              min-width="200"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="scope.row.hospital ? scope.row.hospital.name : '--'"
                >
                  <span class="text-center ordering_facility-value">{{
                    scope.row.hospital ? scope.row.hospital.name : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="physican" label="physician" min-width="150">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes('Dr')
                        ? scope.row.ordering_physician.name
                        : 'Dr ' + scope.row.ordering_physician.name
                      : '--'
                  "
                >
                  <span class="ordering_physician-value">{{
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes("Dr")
                        ? scope.row.ordering_physician.name
                        : "Dr " + scope.row.ordering_physician.name
                      : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="CREATED ON"
              min-width="130"
              prop="created_at"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.created_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.created_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              min-width="140"
              prop="status"
              sortable="custom"
            >
              <template #default="scope">
                <div
                  class="column-status"
                  v-if="
                    hasPermission('orders', 'pending_rejection') &&
                    scope.row.status
                  "
                >
                  <div
                    v-if="
                      scope.row.status !== 'COMPLETE' &&
                      scope.row.status !== 'ARCHIVED' &&
                      scope.row.status !== 'PARTIAL'
                    "
                  >
                    <el-dropdown
                      class="status-dropdown"
                      :disabled="
                        scope.row.status === 'COMPLETE' ||
                        scope.row.status === 'PARTIAL'
                      "
                      @command="
                        (command) =>
                          changeStatus(command, scope.row, scope.$index)
                      "
                      trigger="hover"
                      v-if="hasPermission('orders', 'update_status')"
                    >
                      <span class="el-dropdown-link">
                        <el-tag
                          :type="getStatusType(scope.row.status)"
                          style="cursor: pointer"
                        >
                          {{ scope.row.status.split("_").join(" ") }}
                        </el-tag>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu
                          class="status-dropdown-menu center-dropdown"
                        >
                          <span
                            v-if="
                              scope.row.status && scope.row.status == 'REJECTED'
                            "
                          >
                            <el-dropdown-item
                              command="ACTIVE"
                              :disabled="scope.row.status === 'ACTIVE'"
                            >
                              ACTIVE
                            </el-dropdown-item>
                          </span>
                          <!-- <el-dropdown-item
                            command="PENDING"
                            :disabled="scope.row.status === 'PENDING'"
                          >
                            PENDING
                          </el-dropdown-item> -->
                          <el-dropdown-item
                            command="REJECTED"
                            :disabled="scope.row.status === 'REJECTED'"
                          >
                            REJECTED
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                  <div v-else>
                    <!-- Render content when status is 'FINALIZED' -->
                    <el-tag
                      :type="getStatusType(scope.row.status)"
                      style="cursor: not-allowed"
                    >
                      <span v-if="scope.row.status == 'COMPLETE'">
                        FINALIZED
                      </span>
                      <span v-else-if="scope.row.status == 'PARTIAL'">
                        PARTIAL
                      </span>
                      <span v-else>
                        {{ scope.row.status.split("_").join(" ") }}
                      </span>
                    </el-tag>
                  </div>
                </div>
                <div v-else class="column-status">
                  <el-tag :type="getStatusType(scope.row.status)">
                    {{
                      scope.row.status === "COMPLETE"
                        ? "FINALIZED"
                        : scope.row.status.split("_").join(" ")
                    }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="accession_id"
              label="ACCESSION ID"
              min-width="285"
              sortable="custom"
              width="285"
            >
              <template #default="scope">
                <div
                  class="name-accession cursor"
                  v-if="
                    scope.row.accession_data && scope.row.accession_data.length
                  "
                >
                  <span
                    id="accession-id-blue"
                    class="order-accession"
                    v-for="(acccession, index) in scope.row.accession_data"
                    :key="index"
                    @click="goToCaseView(acccession.case_id)"
                  >
                    {{ acccession.accession_id }}
                  </span>
                </div>
                <div
                  class="name-accession cursor"
                  @click="goToCaseView(scope.row.case_id)"
                  v-else-if="scope.row.case_id"
                >
                  <span id="accession-id-blue" class="order-accession">
                    {{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="ARCHIVE REASON"
              width="300"
              v-if="
                hasPermission('orders', 'view') &&
                this.$route.path === '/all-orders/archive'
              "
            >
              <template #default="scope">
                <div class="name-accession cursor">
                  <span id="accession-id-blue">
                    {{
                      scope.row.archive_reason ? scope.row.archive_reason : "--"
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              width="150"
              fixed="right"
            >
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li @click="goToOrdersView(scope.row._id)">
                    <el-button class="view-button" title="View Order">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      hasPermission('orders', 'delete') &&
                      scope.row.status != 'ARCHIVED' &&
                      scope.row.status == 'ACTIVE'
                    "
                  >
                    <el-button
                      title="Archive Order"
                      class="delete-button"
                      @click="onDelete(scope.row._id)"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/archive-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/archive-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li v-if="hasPermission('orders', 'archive_orders')">
                    <el-button
                      title="Move To Validation"
                      class="reports-button validation-button"
                      @click="moveToValidations(scope.row._id)"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/validation-new-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/validation-new-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      hasPermission('orders', 'create') &&
                      scope.row.status != 'ARCHIVED'
                    "
                  >
                    <el-button @click="reOrder(scope.row._id)" title="Re-Order">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/re-order-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/re-order-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      hasPermission('orders', 'archive_orders') &&
                      scope.row.status != 'ARCHIVED'
                    "
                  >
                    <el-button
                      title="Archive Order"
                      class="delete-button"
                      @click="onDelete(scope.row._id)"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/archive-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/archive-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li v-if="scope.row.count && scope.row.count > 0">
                    <el-button
                      @click="goToNotificationOrderView(scope.row._id)"
                      class="notification_bell"
                    >
                      <img
                        src="@/assets/images/icons/bell-icon.svg"
                        alt="icon"
                      />

                      <span class="icon_tag">
                        {{ scope.row.count }}
                      </span>
                    </el-button>
                  </li>
                  <li v-if="scope.row.status == 'ARCHIVED'">
                    <el-button
                      class="view-button"
                      @click="onRestore(scope.row)"
                      title="Restore Order"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/restore-icon-danger.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/restore-icon-danger.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout="prev, pager, next, jumper, sizes, total"
            :total="getAllOrders.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div class="default-modal">
      <div class="archive-popup">
        <el-dialog top="25vh" width="30%" v-model="archivePopup">
          <div class="dialog-header">
            <span class="header-part"> Reason for Archive </span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="archivePopup = false"
            >
              <img src="@/assets/images/close-icon.svg" alt="icon"
            /></el-button>
          </div>

          <el-row :gutter="20" class="popup-text-area">
            <el-col :span="24">
              <el-input
                v-model="textarea"
                type="textarea"
                placeholder="Reason for Archive"
              />
            </el-col>
            <el-col :span="24">
              <div class="dialog-footer">
                <el-button class="blue-cancel-button" plain> CANCEL</el-button>

                <el-button class="blue-save-button"> SUBMIT</el-button>
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
    <div class="default-modal">
      <div v-if="generateBarcodesDialogVisible">
        <el-dialog
          top="10%"
          width="35%"
          v-model="generateBarcodesDialogVisible"
        >
          <div class="dialog-header">
            <span class="header-part">
              <img
                src="@/assets/images/new-barcode-icon-blue.svg"
                alt="icon"
              />Generate Barcodes
            </span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="generateBarcodesDialogVisible = false"
            >
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
            /></el-button>
          </div>

          <el-row :gutter="20" class="popup-date">
            <el-col :lg="8" :xl="9">
              <span class="date-label">From Date</span>
              <el-date-picker
                type="date"
                v-model="barcode_receive_from_date"
                placeholder="From Date"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-col>
            <el-col :lg="8" :xl="9">
              <span class="date-label">To Date</span>
              <el-date-picker
                type="date"
                v-model="barcode_receive_to_date"
                placeholder="To Date"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-col>
            <el-col :lg="8" :xl="6">
              <div class="dialog-footer mt-15">
                <el-button
                  class="blue-save-button"
                  @click="onSelectedDatesGenerateBarcodes"
                  :disabled="isDateAvailable"
                >
                  GENERATE</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog
        v-model="regenerateOrder"
        width="30%"
        :before-close="handleClose"
        title="Re Order"
        v-loading="loading"
      >
        <div class="export-dialog-header">
          <div class="export-header-part pl-1r">
            <h3>Re Order</h3>
          </div>

          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="handleClose"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date pb-0">
          <div class="reorder-radio">
            <el-radio-group
              v-model="reOrderWithTestInfo"
              class="re-order-radio-list"
            >
              <el-radio label="test_info">Include Test Info</el-radio>
              <el-radio label="new_test_info">New Test Info</el-radio>
            </el-radio-group>
          </div>
          <div class="reorder-radio">
            <el-radio-group
              v-model="reOrderWithAddress"
              class="re-order-radio-list"
            >
              <el-radio label="old_address">Include Address</el-radio>
              <el-radio label="new_address">New Address</el-radio>
            </el-radio-group>
          </div>
          <div class="reorder-radio">
            <el-radio-group
              v-model="reOrderWithInsurance"
              class="re-order-radio-list"
            >
              <el-radio label="old_insurance">Include Insurance</el-radio>
              <el-radio label="new_insurance">New Insurance</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="dialog-footer w-95">
          <el-button
            class="blue-save-button"
            :disabled="checkOptions"
            @click="reOrderCase()"
            >Submit</el-button
          >
        </div>
      </el-dialog>
    </div>
    <ExportOrders
      v-if="exportReportsDialogVisisble"
      v-on:close="exportReportsDialogVisisble = false"
      :selected_search_hospital="[hospital]"
      :selected_marketer="hospital_marketer"
      :selected_search_string="search_string"
      :selected_search_ordering_physician="physician"
      :selected_case_type="caseType"
      :selected_dob="date_of_birth"
      :search_case_status="selectedStatuses"
      :search_first_name="patient_name"
      :pregnancy="pregnant"
      :selected_tests="test_info"
      :featureOrders="featureDateOrders"
    >
    </ExportOrders>
    <ResonForChange
      v-if="takeReasonDialog"
      v-on:close="takeReasonDialog = false"
      v-on:reasonSubmit="deleteCase"
      title="Reason for Archive"
    ></ResonForChange>
    <ResonForValidation
      v-if="takeValidationReasonDialog"
      v-on:close="takeValidationReasonDialog = false"
      v-on:reasonSubmit="moveToValidation"
      title="Reason for Validation"
    ></ResonForValidation>
    <ResonForBulkValidations
      v-if="bulkValidationDialog"
      v-on:close="bulkValidationDialog = false"
      v-on:reasonSubmit="submitBulkValidation"
      title="Bulk Validations"
      :no_of_accessions="bulkValidationData"
    ></ResonForBulkValidations>
    <ReasonForStatusChange
      v-if="takeStatusChangeReasonDialog"
      v-on:close="takeStatusChangeReasonDialog = false"
      v-on:reasonSubmit="unsetOrderCompleteStatus"
      :width="40"
      :title="takeStatusChangeReasonDialogTitle"
    ></ReasonForStatusChange>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";

import { mapGetters } from "vuex";
import moment from "moment";
const ExportOrders = defineAsyncComponent(() =>
  import("@/components/orders/ExportOrders")
);
const ReasonForStatusChange = defineAsyncComponent(() =>
  import("@/components/misc/OrderReasonForChange")
);
import caseConstants from "@/config/constants/caseConstants";
import {
  ordersExtraSearchOptions,
  pregnencyOptions,
} from "@/config/constants/searchConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import FilterHelper from "@/mixins/FilterHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
const ResonForValidation = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
const ResonForBulkValidations = defineAsyncComponent(() =>
  import("@/components/misc/ReasonForBulkValidations")
);
export default {
  name: "",
  components: {
    ExportOrders,
    ResonForChange,
    ResonForValidation,
    ResonForBulkValidations,
    ReasonForStatusChange,
  },

  props: {
    selectedStatuses: {
      default: [],
    },
    featureOrders: {
      default: false,
    },
  },
  mixins: [FilterHelper, UserPermissionsHelper, caseDataHelper],
  data() {
    return {
      marketerData: [],
      scrollPosition: null,
      marketingManagerData: [],
      userLoading: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      archivePopup: false,
      page: 1,
      pageSize: 25,
      caseType: "",
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      caseTypes: caseConstants.CASE_TYPES_DATA,
      searchOptions: ordersExtraSearchOptions,
      pregnencyOptions: pregnencyOptions,
      loading: false,
      search_string: "",
      patient_name: "",
      hospital: "",
      hospital_marketer: [],
      date_of_birth: "",
      physician: "",
      searchBy: "",
      orderBy: "",
      orderType: "",
      exportReportsDialogVisisble: false,
      takeReasonDialog: false,
      requestArchiveOrder: "",
      hospitalsData: {},
      physiciansData: {},
      hospitalsLoading: false,
      physiciansLoading: false,
      pregnant: "",
      testIds: {},
      testNames: [],
      test_info: [],
      selectedCaseIds: [],
      generateBarcodesDialogVisible: false,
      barcode_receive_from_date: "",
      barcode_receive_to_date: "",
      requestValidationOrder: "",
      takeValidationReasonDialog: false,
      bulkValidationData: [],
      bulkValidationDialog: false,
      reOrderWithTestInfo: "",
      reOrderWithInsurance: "",
      reOrderWithAddress: "",
      regenerateOrder: false,
      reOrderId: "",
      barcodeNumber: "",
      takeStatusChangeReasonDialog: false,
      takeStatusChangeReasonDialogTitle: "",
      orderIds: [],
      counts: [],
      featureDateOrders: "",
    };
  },
  created() {
    if (this.isFacilityUser || this.isLabAssistantUser) {
      let markerFilterIndex = this.searchOptions.findIndex(
        (option) => option.value == "hospital_marketers"
      );
      if (markerFilterIndex > -1) {
        this.searchOptions[markerFilterIndex].active = false;
      }
    }
    this.searchOptions = this.searchOptions.filter((option) => option.active);
  },
  computed: {
    getStatusType() {
      return (statusVal) => {
        const status = statusVal;
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else if (status == "TEST_REPORTS") {
          return "warning";
        } else if (status == "PARTIAL") {
          return "info";
        } else if (status == "ARCHIVED") {
          return "danger";
        } else if (status == "REJECTED") {
          return "darkDanger";
        } else if (status == "PENDING") {
          return "danger";
        } else {
          return "";
        }
      };
    },

    ...mapGetters("orders", [
      "getAllOrders",
      "getCaseDeleteStatus",
      "getCaseDeleteError",
      "getUpdateValidationStatus",
      "getBulkValidations",
      "getScanOrderDetails",
      "getUpdateOrderStatus",
      "getRestoreOrderStatus",
      "getRestoreOrderError",
    ]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    ...mapGetters("labTests", ["getAllLabTests"]),
    ...mapGetters("clientPendingOrders", ["getOrderNotificationCounts"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    isDateAvailable() {
      if (!this.barcode_receive_to_date) {
        return true;
      }
      return false;
    },
    checkOptions() {
      if (
        this.reOrderWithTestInfo &&
        this.reOrderWithAddress &&
        this.reOrderWithInsurance
      ) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (this.hasPermission("cases", "create_case")) {
        this.setDefaultFocus(); // Set initial focus to barcodeInput
        document.addEventListener("click", this.focusInput.bind(this));
      }
    });
    this.setQueryParamsData();
    await this.fetchAllOrders();
    await this.fetchCounts();
  },
  beforeUnmount() {
    // Clean up the event listener when the component is destroyed
    document.removeEventListener("click", this.focusInput.bind(this));
  },
  methods: {
    async onRestore(order) {
      this.$confirm("Are you sure to Restore order?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.restoreOrder(order);
      });
    },

    async restoreOrder(order) {
      this.loading = true;
      let params = {
        order_id: order._id,
        status: order.accession_id ? "COMPLETE" : "ACTIVE",
      };
      await this.$store.dispatch("orders/restoreArchivedOrder", params);
      if (this.getRestoreOrderStatus) {
        successNotification("Order Restored Successfully");
        await this.fetchAllOrders(this.page);
      } else {
        errorNotification(
          this.getRestoreOrderError || "An Error occured while restoring."
        );
      }
      this.loading = false;
    },
    setDefaultFocus() {
      this.$nextTick(() => {
        if (this.$refs.barcodeInput) {
          this.$refs.barcodeInput.focus();
        }
      });
    },
    handleFocus() {
      this.$refs.barcodeInput.focus();
    },
    focusInput(event) {
      // Check if the click is inside any of the input fields
      const isOrderInputClicked =
        this.$refs.orderInput &&
        this.$refs.orderInput.$el.contains(event.target);
      const isBarcodeInputClicked =
        this.$refs.barcodeInput &&
        this.$refs.barcodeInput.$el.contains(event.target);
      const isPatientNameInputClicked =
        this.$refs.patientNameInput &&
        this.$refs.patientNameInput.$el.contains(event.target);
      // Handle focus based on the input field clicked
      if (isOrderInputClicked) {
        this.$refs.orderInput.focus();
      } else if (isPatientNameInputClicked) {
        this.$refs.patientNameInput.focus();
      } else if (!isBarcodeInputClicked) {
        this.$refs.barcodeInput.focus();
      }
    },
    async getOrderDetails() {
      try {
        let updateBarcode = this.barcodeNumber.trim();
        let params = {
          orderId: updateBarcode.toLocaleUpperCase(),
        };
        await this.$store.dispatch("orders/fetchScanOrderById", params);
        if (this.getScanOrderDetails) {
          this.goToOrdersView(this.getScanOrderDetails._id);
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    async remoteMethodPhysician(query) {
      if (query !== "") {
        localStorage.setItem("searchQueryPhysician", query);
        await this.fetchAllPhysicians(query);
      }
    },
    async fetchCounts() {
      if (this.$route && this.$route.name == "RejectionOrders") {
        await this.fetchPendingOrderNotificaitons();
      }
    },
    async fetchPendingOrderNotificaitons() {
      try {
        this.loading = true;
        let params = {
          order_ids: this.orderIds,
        };
        await this.$store.dispatch(
          "clientPendingOrders/fetchOrdersCount",
          params
        );
        if (this.getOrderNotificationCounts) {
          for (const orderId in this.getOrderNotificationCounts) {
            const orderIndex = this.getAllOrders.data.findIndex(
              (order) => order._id === orderId
            );
            if (orderIndex !== -1) {
              this.getAllOrders.data[orderIndex].count =
                this.getOrderNotificationCounts[orderId];
            }
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async submitBulkValidation(reason) {
      try {
        let bulkValidations = [];
        for (let accession of this.bulkValidationData) {
          bulkValidations.push(accession._id);
        }
        let params = {
          status: true,
          type: "all",
          validation_reasons: [reason],
          order_ids: bulkValidations,
        };
        await this.$store.dispatch("orders/updateBulkValidations", params);
        if (this.getBulkValidations) {
          successNotification("Orders moved to Validation Labsquire Orders");
          this.bulkValidationDialog = false;
          this.bulkValidationData = [];
          await this.fetchAllOrders();
        } else {
          errorNotification(
            "Error while moving orers to Validation Labsquire Orders"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    moveBulkValidation() {
      this.bulkValidationDialog = true;
    },
    async moveToValidations(validation) {
      this.requestValidationOrder = validation;
      this.takeValidationReasonDialog = true;
    },
    async moveToValidation(reason) {
      try {
        this.loading = true;
        let params = {
          id: this.requestValidationOrder,
          status: true,
          validation_reason: reason,
        };
        await this.$store.dispatch("orders/changeCaseValidationStatus", params);
        if (this.getUpdateValidationStatus) {
          successNotification(
            "Labsquire order moved to validation Labsquire Orders"
          );
          await this.fetchAllOrders();
        } else {
          errorNotification(
            "Error while moving labsquire order to validation labsquire orders"
          );
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    handleSelectionChange(rows) {
      if (rows) {
        this.selectedCaseIds = [];
        this.bulkValidationData = [];
        for (let caseData of rows) {
          this.selectedCaseIds.push(caseData._id);
          this.bulkValidationData.push(caseData);
        }
      } else {
        this.selectedCaseIds = [];
        this.bulkValidationData = [];
      }
    },
    onPrintBarCodes() {
      let urlData = this.$router.resolve({
        name: "OrderMultipleBarcodes",
        path: "/orders/multiple-barcodes",
        query: {
          order_ids: this.selectedCaseIds,
        },
      });
      window.open(urlData.href, "_blank");
    },
    onSelectedDatesGenerateBarcodes() {
      if (
        this.barcode_receive_from_date != "" &&
        this.barcode_receive_to_date != ""
      ) {
        let urlData = this.$router.resolve({
          name: "OrderMultipleBarcodes",
          path: "/orders/multiple-barcodes",
          query: {
            from_date: this.barcode_receive_from_date,
            to_date: this.barcode_receive_to_date,
          },
        });
        this.barcode_receive_from_date = "";
        this.barcode_receive_to_date = "";
        this.generateBarcodesDialogVisible = false;
        window.open(urlData.href, "_blank");
      }
    },
    async fetchAllUsers() {
      this.userLoading = true;

      await this.$store.dispatch("userAccounts/fetchAllUserAccounts", {
        get_all: true,
        user_types: [
          "MARKETER",
          "HOSPITAL_MARKETING_MANAGER",
          "SALES_DIRECTOR",
        ],
        order_by: "first_name",
        order_type: "asc",
      });
      if (this.getAllUsers && this.getAllUsers.data) {
        this.marketerData = this.getAllUsers.data;
      }
      this.userLoading = false;
    },
    async onDelete(id) {
      this.requestArchiveOrder = id;
      this.takeReasonDialog = true;
    },
    async deleteCase(reason) {
      try {
        await this.$store.dispatch("orders/deleteCase", {
          order_id: this.requestArchiveOrder,
          reason: reason,
        });
        if (this.getCaseDeleteStatus) {
          successNotification("Order deleted successfully");
          this.requestArchiveOrder = "";
          this.fetchAllOrders(this.page);
        } else {
          errorNotification(
            this.getCaseDeleteError || "Error while deleting order"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    addNewOrder() {
      this.$router.push({
        path: "/new-order",
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async onReportsExport() {
      this.exportReportsDialogVisisble = true;
    },
    clearSearchBy() {
      if (
        this.$route.query.selectedOrderSearchBy == "Pregnancy" ||
        this.$route.query.selectedOrderSearchBy == "Future Orders"
      ) {
        this.fetchAllOrders(1);
      }
    },
    clearInputSearch() {
      if (
        this.hospital ||
        this.physician ||
        this.date_of_birth ||
        this.patient_name ||
        this.search_string ||
        this.caseType ||
        this.hospital_marketer.length ||
        this.patient_name ||
        this.test_info.length
      ) {
        this.hospital = "";
        this.hospital_marketer = [];
        this.patient_name = "";
        this.physician = "";
        this.date_of_birth = "";
        this.caseType = "";
        this.patient_name = "";
        this.search_string = "";
        this.isSearchByInput = "";
        this.patient_name = "";
        this.test_info = [];
        this.page = 1;
        this.fetchAllOrders(1);
      }
      if (this.searchBy == "Pregnancy") {
        this.fetchAllOrders(1);
      }
      if (this.searchBy == "Future Orders") {
        this.fetchAllOrders(1);
      }
    },
    async fetchAllPhysicians(physician) {
      try {
        this.physiciansLoading = true;
        let params = {
          limit: 25,
        };
        if (physician) {
          params.search_string = physician;
        }
        await this.$store.dispatch("physicians/fetchAllPhysicians", params);
        this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
        this.physiciansLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAllHospitals() {
      try {
        this.hospitalsLoading = true;

        await this.$store.dispatch("hospitals/fetchAllHospitals", {
          get_all: true,
        });
        this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
        this.hospitalsLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedOrderPage
        ? parseInt(this.$route.query.selectedOrderPage)
        : 1;
      this.pageSize = this.$route.query.selectedOrderPageSize
        ? parseInt(this.$route.query.selectedOrderPageSize)
        : 25;
      if (this.$route.query.selectedOrderString) {
        this.search_string = this.$route.query.selectedOrderString;
      }
      if (this.$route.query.selectedOrderPatientName) {
        this.patient_name = this.$route.query.selectedOrderPatientName;
      }
      if (this.$route.query.selectedOrderCase) {
        this.caseType = this.$route.query.selectedOrderCase;
        this.fetchAllTestPanels();
      }
      if (
        this.$route.query.selectedTest &&
        Array.isArray(this.$route.query.selectedTest) &&
        this.$route.query.selectedTest.length
      ) {
        this.fetchAllTestPanels();
        this.test_info = this.$route.query.selectedTest;
      } else if (
        this.$route.query.selectedTest &&
        this.$route.query.selectedTest.length
      ) {
        this.fetchAllTestPanels();
        this.test_info = [this.$route.query.selectedTest];
      }
      if (this.$route.query.selectedTestReportsOpen) {
        this.dialogVisible =
          this.$route.query.selectedTestReportsOpen === "true" ? true : false;
      }
      if (this.$route.query.selectedCaseId) {
        this.selectedCaseId = this.$route.query.selectedCaseId;
      }
      if (this.$route.query.selectedOrderSearchBy) {
        this.searchBy = this.$route.query.selectedOrderSearchBy;
      }
      if (this.$route.query.selectedOrderPhysician) {
        const savedQuery = localStorage.getItem("searchQueryPhysician");
        if (
          savedQuery &&
          this.$route.query.selectedOrderString !== savedQuery
        ) {
          this.fetchAllPhysicians(savedQuery);
        }
        this.physician = this.$route.query.selectedOrderPhysician;
      }
      if (this.$route.query.selectedOrderHospital) {
        this.fetchAllHospitals();
        this.hospital = this.$route.query.selectedOrderHospital;
      }
      if (this.$route.query.selectedMarketer) {
        this.fetchAllUsers();
        this.hospital_marketer = this.$route.query.selectedMarketer;
      }
      if (this.$route.query.selectedOrderDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedOrderDateOfBirth;
      }
      if (this.$route.query.selectedOrderOrderBy) {
        this.orderBy = this.$route.query.selectedOrderOrderBy;
      }
      if (this.$route.query.selectedOrderOrderType) {
        this.orderType = this.$route.query.selectedOrderOrderType;
      }
    },
    prepareParams() {
      this.pregnant = "";
      this.featureDateOrders = "";
      const params = {
        page: this.page,
        limit: this.pageSize,
        is_validation_purpose: false,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.patient_name) {
        params.patient_name = this.patient_name;
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.date_of_birth = date_of_birth;
      }
      if (this.hospital) {
        params.hospital = this.hospital;
      }
      if (this.hospital_marketer.length) {
        params.hospital_marketers = this.hospital_marketer;
      }
      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.test_info.length) {
        params.tests_info = [].concat.apply([], this.test_info);
      }
      if (this.physician) {
        params.ordering_physician = this.physician;
      }
      if (this.searchBy == "Pregnancy") {
        this.pregnant = true;
        params.pregnant = true;
      }
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.searchBy == "Future Orders") {
        this.featureDateOrders = true;
        params.future_collection_date = true;
      }
      // if (this.featureOrders) {
      //   params.future_collection_date = this.featureOrders;
      // }
      params.status = this.selectedStatuses;
      return params;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedOrderPage: params.page,
        selectedOrderCase: params.case_type,
        selectedOrderHospital: params.hospital,
        selectedMarketer: params.hospital_marketers,
        selectedOrderDateOfBirth: params.date_of_birth,
        selectedOrderPhysician: params.ordering_physician,
        selectedOrderString: params.search_string,
        selectedOrderPatientName: params.patient_name,
        selectedOrderPageSize: params.limit,
        selectedOrderOrderBy: params.order_by,
        selectedOrderOrderType: params.order_type,
        selectedStatus: params.status,
        selectedTestReportsOpen: this.$route.query.selectedTestReportsOpen,
        selectedCaseId: this.$route.query.selectedCaseId,
        selected_panel: this.$route.query.selected_panel,
        selectedOrderSearchBy: this.searchBy || undefined,
        selectedTest: params.tests_info,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllOrders();
      this.fetchCounts();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllOrders();
      this.fetchCounts();
    },
    handleCurrentChangeCaseType(page) {
      this.page = page;
      this.test_info = [];
      this.fetchAllTestPanels();
      this.fetchAllOrders();
    },
    handleTest(page) {
      this.page = page;
      this.fetchAllOrders();
    },
    clearTest() {
      this.caseType = "";
      this.test_info = [];
      this.fetchAllOrders();
    },
    async fetchAllTestPanels() {
      await this.$store.dispatch("labTests/fetchAllLabTests", {
        get_all: true,
        case_type_code: this.caseType,
      });
      if (this.getAllLabTests) {
        this.testNames = [];
        for (let caseData of this.getAllLabTests) {
          this.testNames.push(caseData);
        }
        this.testIds = {};
        let uniqueObject = {};
        if (this.caseType == "TOXICOLOGY") {
          this.testNames = this.testNames.map((e) => {
            if (
              e.category_code == "SCREENING" ||
              e.category_code == "SCREENING_VALIDITY"
            ) {
              e.category = "SCREENING";
            } else if (e.category_code == "ETG/ETS") {
              e.category = "ETG/ETS";
            } else {
              e.category = "CONFIRMATION";
            }
            return e;
          });
        }

        let categories = Array.from(
          new Set(this.testNames.map((e) => e.category))
        );

        this.testIds = categories.map((category) => {
          let tests = this.testNames
            .filter((t) => t.category == category)
            .map((e) => e._id);
          return {
            category,
            tests,
          };
        });
      } else {
        this.testNames = [];
        this.testIds = {};
      }
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "";
        this.orderType = "";
      }
      this.fetchAllOrders();
    },
    getAutoCompleteOrders() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllOrders();
      }, 600);
    },
    async fetchAllOrders() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("orders/getOrders", params);
        this.updateQueryParams(params);
        if (this.getAllOrders && this.getAllOrders.data) {
          this.getAllOrders.data.forEach((obj) => {
            this.orderIds.push(obj._id);
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    goToNotificationOrderView(orderId) {
      this.$router.push({
        name: "Order-Information",
        params: { order_id: orderId },
        query: {
          ...this.$route.query,
          testInfo: undefined,
          address: undefined,
          insurance: undefined,
          redirectRoute: this.$route.name,
          drawer: true,
        },
      });
    },
    goToOrdersView(orderId) {
      this.$router.push({
        name: "Order-Information",
        params: { order_id: orderId },
        query: {
          ...this.$route.query,
          testInfo: undefined,
          address: undefined,
          insurance: undefined,
          redirectRoute: this.$route.name,
        },
      });
    },
    goToCaseView(case_id) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: case_id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    reOrderCase() {
      this.$router.push({
        name: "Re-Order",
        params: { order_id: this.reOrderId },
        query: {
          ...this.$route.query,
          testInfo: this.reOrderWithTestInfo,
          insurance: this.reOrderWithInsurance,
          address: this.reOrderWithAddress,
          redirectRoute: this.$route.name,
        },
      });
    },
    handleClose() {
      this.reOrderWithTestInfo = "";
      this.regenerateOrder = false;
      this.reOrderWithAddress = "";
      this.reOrderWithInsurance = "";
    },
    reOrder(orderId) {
      this.reOrderId = orderId;
      this.regenerateOrder = true;
    },
    async changeStatus(command, OrderData, index) {
      try {
        if (command == OrderData.status) {
          return;
        }
        this.takeStatusChangeReasonDialogTitle = command;
        let params = {
          status: command,
          id: OrderData._id,
        };
        if (OrderData.status == "ACTIVE" || OrderData.status == "REJECTED") {
          this.unsetCompleteStatusChangeCase = OrderData._id;
          this.toStatus = command;
          this.caseIndex = index;
          this.askReasonOnUnsetCompletedStatus();
        } else {
          await this.updateStatus(params, index);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async unsetOrderCompleteStatus(data) {
      let params = {
        id: this.unsetCompleteStatusChangeCase,
        status: this.toStatus,
      };
      if (data.notes != "") {
        params.notes = data.notes;
      }
      if (data.reasons && data.reasons.length > 0) {
        params.reasons = data.reasons;
      }
      await this.updateStatus(params, this.caseIndex);
    },

    async askReasonOnUnsetCompletedStatus() {
      this.takeStatusChangeReasonDialog = true;
    },
    async updateStatus(params, index) {
      try {
        this.loading = true;
        await this.$store.dispatch("orders/changeOrderStatus", params, {
          root: true,
        });
        if (this.getUpdateOrderStatus && this.getUpdateOrderStatus.success) {
          this.$notify({
            type: "success",
            title: "Success",
            message: this.getUpdateOrderStatus.message,
          });
          await this.$store.dispatch("orders/fetchOrderStats");

          await this.fetchAllOrders();
          this.getAllOrders.data[index].status = params.status;
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
  watch: {
    $route() {
      this.fetchCounts();
    },
  },
};
</script>

<style lang="scss">
#all-orders-listing {
  .image-fluid {
    width: 100%;
  }
  .order-files {
    img {
      position: relative;
      top: 3px;
      right: 4px;
    }
  }
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-default {
      padding-top: 0;
    }
  }
  .listings__table {
    .name-accession {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    #accession-id {
      color: #eb5757;
      cursor: pointer;
    }
    #patient-name {
      overflow: hidden;
      margin-left: 7px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.validation-button {
  display: block;
  margin-bottom: -5px;
  img {
    width: 25px;
  }
}
.reorder-radio {
  .el-radio-group {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    .el-radio__input .el-radio__inner {
      border-color: transparent;
      box-shadow: 0 0 0 1px #ced1d6;
    }
    .el-radio__input.is-checked .el-radio__inner {
      background-color: #0a5da6;
      border-color: #ffffff;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #0a5da6;
    }
  }
}
.center-dropdown {
  text-align: center;
  li {
    padding: 0px 15px;
  }
}
.active-button {
  background: #273480 !important;
  color: #fff;
  font-size: 13px;
  padding: 2px 5px !important;
  &:focus,
  &:hover {
    color: #fff !important;
  }
}
</style>
