<template>
  <div class="default-modal">
    <el-dialog
      width="70%"
      top="15vh"
      v-model="exportReportsDialogVisisble"
      @close="close"
    >
      <div class="export-dialog-header">
        <div class="export-header-part"><h3>ORDERS REPORTS</h3></div>

        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="close"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
        /></el-button>
      </div>
      <div
        class="selected-string"
        v-if="selected_case_type && selected_case_type.length"
      >
        <h3>
          Selected Case Type :
          <span class="value">{{ getCaseTypeMap(selected_case_type) }} </span>
        </h3>
      </div>

      <div
        class="selected-string"
        v-if="
          selected_case_types &&
          Array.isArray(this.selected_case_types) &&
          this.selected_case_types.length
        "
      >
        <h3>
          Selected Case Type :
          <span
            class="value"
            v-for="(caseType, index) of getCaseTypes(selected_case_types)"
            :key="index"
            >{{ caseType }}
            <span v-if="index + 1 < selected_case_types.length">,</span>
          </span>
        </h3>
      </div>
      <div class="selected-string" v-if="dateOfBirth && dateOfBirth.length">
        <h3>
          Selected DOB :
          <span class="value">{{ selectedDob(selected_dob) }} </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="selected_date_type && selected_date_type.length"
      >
        <h3>
          Selected Date Type :
          <span class="value">{{ getDateTypeMap(selected_date_type) }} </span>
        </h3>
      </div>
      <div class="selected-string" v-if="selected_from_date">
        <h3>
          Selected From Date :
          <span class="value">{{ selectedDob(selected_from_date) }} </span>
        </h3>
      </div>
      <div class="selected-string" v-if="selected_to_date">
        <h3>
          Selected To Date :
          <span class="value">{{ selectedDob(selected_to_date) }} </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="search_first_name && search_first_name.length"
      >
        <h3>
          Search Patient Name :
          <span class="value">{{ search_first_name }} </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="search_last_name && search_last_name.length"
      >
        <h3>
          Search Last Name :
          <span class="value">{{ search_last_name }} </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="search_case_statuses && search_case_statuses.length"
      >
        <h3>
          Selected Status :
          <span class="value" v-if="search_case_statuses == 'COMPLETE'"
            >{{ getStatusType(search_case_statuses) }}
          </span>

          <span class="value" v-else
            >{{ getStatusType(search_case_statuses) }}
          </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="
          selected_search_hospital &&
          selected_search_hospital.filter(Boolean).length > 0
        "
      >
        <h3>
          Selected Hospital :
          <span class="value"
            >{{ getSelectedHospital(selected_search_hospital) }}
          </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="
          selected_marketer &&
          Array.isArray(this.selected_marketer) &&
          this.selected_marketer.length
        "
      >
        <h3>
          Selected Marketer :
          <span
            class="value"
            v-for="(marketer, index) of selected_marketer"
            :key="index"
            >{{ getSelectedUser(marketer) }}
          </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="
          selected_search_ordering_physician &&
          selected_search_ordering_physician.length
        "
      >
        <h3>
          Selected Physician :

          <span class="value"
            >{{ getSelectedPhysician(selected_search_ordering_physician).name }}
          </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="selected_search_string && selected_search_string.length"
      >
        <h3>
          Selected String :
          <span class="value">{{ selected_search_string }} </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="search_case_status && search_case_status.length"
      >
        <h3>
          Selected Status :
          <span
            class="value"
            v-for="(status, index) of getStatusMap(search_case_status)"
            :key="index"
            >{{ status }}
            <span v-if="index + 1 < search_case_status.length">,</span>
          </span>
        </h3>
      </div>
      <div class="dialog-body">
        <div class="export-popup-body" v-loading="loading">
          <el-checkbox-group v-model="activeFieldList">
            <ul class="select-fields-list">
              <li
                class="search-checkbox"
                v-for="(item, index) in exportOrderExcelFields"
                :key="index"
              >
                <el-checkbox :label="item.value">
                  {{ item.label }}
                </el-checkbox>
              </li>
            </ul>
          </el-checkbox-group>
        </div>
      </div>
      <div class="dialog-footer export-footer w-95">
        <el-button
          class="cancel-button"
          @click="handleClear"
          :disabled="!this.activeFieldList.length"
          >CLEAR ALL</el-button
        >
        <el-button class="cancel-button" @click="handleCheckAllChange"
          >SELECT ALL</el-button
        >
        <el-button class="cancel-button" @click="close">CANCEL</el-button>
        <el-button class="blue-save-button" @click="downloadCases">
          DOWNLOAD
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import AppHelper from "@/mixins/AppHelper";
import exportOrderHelper from "@/mixins/exportOrderHelper";
import FilterHelper from "@/mixins/FilterHelper";
import { mapGetters } from "vuex";
import moment from "moment";

import caseConstants from "@/config/constants/caseConstants";
export default {
  mixins: [
    UserPermissionsHelper,
    exportOrderHelper,
    caseDataHelper,
    AppHelper,
    FilterHelper,
  ],
  props: [
    "search_case_status",
    "selected_search_hospital",
    "selected_search_string",
    "selected_search_ordering_physician",
    "selected_case_type",
    "selected_case_types",
    "selected_dob",
    "selected_search_status",
    "selected_from_date",
    "selected_to_date",
    "search_first_name",
    "search_last_name",
    "selected_date_type",
    "search_case_statuses",
    "selected_marketer",
    "pregnancy",
    "selected_tests",
    "featureOrders",
  ],
  computed: {
    ...mapGetters("orders", ["getDownloadOrderDataStatus"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("userAccounts", ["getAllUsers"]),

    getCaseTypeMap() {
      return (status) => this.caseTypeMap[status];
    },
    getDateTypeMap() {
      return (date) => this.dateTypeMap[date];
    },
    getStatusMap() {
      return (status) => {
        return (status || []).map((e) => this.statusMap[e]);
      };
    },
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    selectedDob() {
      return (selected_dob) => moment(selected_dob).format("MM-DD-YYYY");
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "Active";
        } else if (status == "COMPLETE") {
          return "Finalized";
        } else if (status == "TEST_REPORTS") {
          return "Processing";
        } else if (status == "REJECTED") {
          return "Rejected";
        } else if (status == "PENDING") {
          return "Pending";
        } else if (status == "TO_BE_COLLECTED") {
          return "Future";
        } else {
          return "";
        }
      };
    },
  },
  data() {
    return {
      exportReportsDialogVisisble: true,
      fieldList: [],
      activeFieldList: [],
      checkAll: true,
      loading: false,
      date_type: "",
      status: "",
      statusMap: caseConstants.STATUS_TYPE_MAP,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      caseTypes: caseConstants.CASE_TYPES_DATA,
      dateOfBirth: "",
      dateTypeMap: {
        collection_date: "Collection",
        received_date: "Received",
        finalized_date: "Reported",
        amended_date: "Amended",
      },
    };
  },
  created() {
    this.activeFieldList = [
      ...this.defaultExportOrderExcelFields.map((opt) => opt.value),
    ];
  },
  mounted() {
    this.status = this.search_case_status;
    if (this.selected_dob) {
      this.dateOfBirth = this.selectedDob(this.selected_dob);
    }
  },
  methods: {
    handleClear() {
      this.activeFieldList = [];
      this.fieldList = [];
    },
    getSelectedHospital(hospitalId) {
      return this.getAllHospitals.data
        .filter((hospital) => hospitalId.indexOf(hospital._id) != -1)
        .flatMap((hospital) => hospital.name)
        .join(", ");
    },
    getSelectedUser(userId) {
      return this.getAllUsers.data
        .filter((user) => userId.indexOf(user._id) != -1)
        .flatMap((user) => user.first_name + " " + user.last_name)
        .join(", ");
    },
    getSelectedPhysician(physicianId) {
      return this.getAllPhysicians.data.find(
        (physician) => physician._id == physicianId
      );
    },
    async downloadCases() {
      if (this.activeFieldList.length) {
        let params = {
          include: this.activeFieldList,
        };

        if (this.date_type) {
          params.date_type = this.date_type;
        }

        if (
          this.search_case_status &&
          Array.isArray(this.search_case_status) &&
          this.search_case_status.length > 1
        ) {
          params.case_status = this.search_case_status;
        } else if (this.search_case_status && this.search_case_status.length) {
          params.status = this.search_case_status.toString();
        } else if (Object.keys(params).indexOf("status") != -1) {
          delete params.status;
        }
        if (this.search_case_statuses) {
          params.status = this.search_case_statuses;
        }
        if (this.selected_search_string != []) {
          params.search_string = this.selected_search_string;
        }
        if (this.selected_search_hospital != "") {
          params.hospital = this.selected_search_hospital;
        }
        if (this.selected_marketer && this.selected_marketer.length) {
          params.hospital_marketers = this.selected_marketer;
        }
        if (this.selected_search_ordering_physician != "") {
          params.ordering_physician = this.selected_search_ordering_physician;
        }
        if (this.selected_case_type && this.selected_case_type.length) {
          params.case_types = [this.selected_case_type];
        }
        if (this.selected_case_types && this.selected_case_types.length) {
          params.case_types = this.selected_case_types;
        }
        if (this.search_first_name) {
          params.patient_name = this.search_first_name;
        }
        if (this.search_last_name) {
          params.last_name = this.search_last_name;
        }
        if (this.selected_date_type) {
          params.date_type = this.selected_date_type;
        }
        if (this.selected_dob) {
          const date_of_birth = moment(this.selected_dob).format("YYYY-MM-DD");

          params.date_of_birth = date_of_birth;
        }
        if (this.selected_from_date) {
          // const from_date = moment(this.selected_from_date).format(
          //   "YYYY-MM-DD"
          // );

          params.from_date = this.convertToLabUTC(this.selected_from_date);
        }
        if (this.selected_to_date) {
          // const to_date = moment(this.selected_to_date).format("YYYY-MM-DD");

          params.to_date = this.convertToLabUTC(this.selected_to_date);
        }
        if (this.pregnancy) {
          params.pregnant = this.pregnancy;
        }
        if (this.selected_tests && this.selected_tests.length) {
          params.tests_info = [].concat.apply([], this.selected_tests);
        }
        if (this.featureOrders) {
          params.future_collection_date = true;
        }
        this.loading = true;
        await this.$store.dispatch("orders/downloadOrdersData", params);
        this.loading = false;
        if (this.getDownloadOrderDataStatus) {
          this.close();
        } else {
          this.$message({
            message: "Error in downloading cases",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "Please select the data fields.",
          type: "warning",
        });
      }
    },
    resetAllFilters() {
      this.fieldList = [];
      this.activeFieldList = [];
      this.date_type = "collection_date";
    },
    close() {
      this.$emit("close");
    },
    handleCheckAllChange() {
      this.activeFieldList = [];
      this.fieldList = [];
      this.exportOrderExcelFields.forEach((obj) => {
        this.fieldList.push(obj.value);
      });
      this.activeFieldList = this.fieldList;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-fields {
  ul {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 20px;
    column-gap: 20px;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
