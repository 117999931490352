<template>
  <section v-loading="loading" class="attachment-popup-body">
    <div class="attachment-upload-button">
      <el-upload
        name="logo"
        action
        :on-change="uploadFile"
        :show-file-list="false"
        :auto-upload="false"
      >
        <el-button
          class="btn radius-button round-button"
          v-if="hasPermission('cases', 'delete')"
        >
          <span>
            <img
              src="@/assets/images/icons/attachment-icon-v2.svg"
              alt=""
            /> </span
          >SELECT FILE</el-button
        >
      </el-upload>
    </div>

    <!-- Attachment upload -->
    <div class="attachment-upload" v-loading="fileUploadLoading">
      <a v-if="selectedFileName">
        <div class="content">
          <div class="icon">
            <img
              src="@/assets/images/icons/attachments/attachments_0.svg"
              alt="icon"
              class="img-fluid"
            />
          </div>
          <div class="text">
            <p>
              {{ selectedFileName }}
            </p>
          </div>
        </div>
        <ul class="actions">
          <li>
            <el-button @click="remove" class="remove-button">
              <el-icon><close /></el-icon>
            </el-button>
          </li>
          <li>
            <el-button
              class="upload-button"
              @click="uploadSelectedDocument"
              :disabled="!selectedFile ? true : false"
            >
              <el-icon><Upload /></el-icon>
            </el-button>
          </li>
        </ul>
      </a>
    </div>

    <!-- Attachment uploaded -->
    <div v-if="!noDocuments" class="attachment-uploaded">
      <ul>
        <li v-for="(doc, index) of allInternalDocuments" :key="index">
          <div class="content" @click="downloadDocument(doc)">
            <div class="icon">
              <img
                src="@/assets/images/icons/attachments/attachments_0.svg"
                alt="icon"
                class="img-fluid"
              />
            </div>
            <p class="text">
              {{ doc.metadata.original_name }}
            </p>
          </div>
          <button
            class="remove-button"
            type="button"
            @click="onDeleteAttachment(doc)"
            v-if="hasPermission('cases', 'delete')"
          >
            <p>Remove</p>
          </button>
        </li>
      </ul>
    </div>

    <div class="attachments-empty" v-if="noDocuments && !this.selectedFileName">
      <p>You can manage internal documents here</p>
    </div>
    <div class="mini-reason-for-change">
      <ResonForChange
        v-if="takeReasonForChangeDialog"
        v-on:close="takeReasonForChangeDialog = false"
        v-on:reasonSubmit="deleteAttachment"
        title="Reason for Remove"
      ></ResonForChange>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import axios from "axios"
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
export default {
  name: "InternalDocuments",
  components: {
    ResonForChange,
  },
  mixins:[UserPermissionsHelper],
  computed: {
    ...mapGetters("cases", [
      "getFileUploadURLForAttachments",
      "getFileUploadRefIdForAttachment",
      "getAttachmentUploadStatusUpdated",
      "getAttachmentUploadStatusForAttachments",
      "getAttachmentDeleteStatus",
      "getCaseInformation",
    ]),
    ...mapGetters("respiratoryFileUpload", ["getFileDownloadUrl"]),
  },

  mounted() {
    this.checkCaseInformation();
  },
  data() {
    return {
      noDocuments: false,
      selectedFile: null,
      selectedFileName: null,
      caseId: "",
      allInternalDocuments: [],
      loadingText: "",
      loading: false,
      fileUploadLoading: false,
      takeReasonForChangeDialog: false,
      selectedNote: null,
    };
  },
  methods: {
    onDeleteAttachment(doc) {
      this.takeReasonForChangeDialog = true;
      this.selectedNote = doc;
    },

    uploadFile(file) {
      this.selectedFile = file.raw;
      this.selectedFileName = file.raw.name;
      this.getFileUploadUrl(file);
    },
    remove() {
      this.selectedFile = null;
      this.selectedFileName = "";
    },
    async getFileUploadUrl(file) {
      try {
        this.loading = true
        let data = {
        file: file.raw.name,
        file_type: file.raw.type,
        file_size: file.raw.size / 1000,
        case_id: this.caseId,
        type: "case-internal-documents-upload",
      };
      await this.$store.dispatch("cases/fetchDocumentUploadUrl", data);
      this.loading = false
      } catch(error){
        this.loading = false
        console.log(error)
      }
    },
    async uploadSelectedDocument() {
      this.fileUploadLoading = true;
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: this.selectedFile,
        upload_url: this.getFileUploadURLForAttachments,
      };

      await this.$store.dispatch("cases/uploadDocumentToGeneratedUrlForAttachments", params);

      if (this.getAttachmentUploadStatusForAttachments) {
        // UPDATEING STATUS
        let params = {
          hash: this.getFileUploadRefIdForAttachment,
          case_id: this.caseId,
          type: "case-internal-documents-upload",
        };
        await this.$store.dispatch("cases/updateDocumentUploadStatus", params);
        if (this.getAttachmentUploadStatusUpdated) {
          successNotification("Internal Document added successfully");
          this.selectedFileName = "";
          this.selectedFile = null;
          this.checkCaseInformation();
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.fileUploadLoading = false;
    },

    async deleteAttachment(reason) {
      let params = {
        case_id: this.caseId,
        id: this.selectedNote._id,
        data_update_reason: reason,
      };

      await this.$store.dispatch("cases/deleteInternalDocument", params);

      if (this.getAttachmentDeleteStatus) {
        successNotification("Internal Document Removed successfully");
        this.checkCaseInformation();
      }
    },

    async downloadDocument(doc) {
      await this.$store.dispatch("respiratoryFileUpload/fetchFileDownloadUrl", {
        path: "case_internal_documents/" + doc.path,
      });

      let url = this.getFileDownloadUrl.download_url;
            axios({
                  url: url, //your url
                  method: 'GET',
                  responseType: 'blob', // important
              }).then((response) => {
                  const href = URL.createObjectURL(response.data);
                  const link = document.createElement('a');
                  link.href = href;
                  link.setAttribute('download', `${doc.path}`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
              });
    },

    async checkCaseInformation() {
      try {
        this.loading = true;
        this.fileUploadLoading = false;
        this.caseId = this.$route.params.case_id;
        if (!this.caseId) {
          this.loading = false;
          this.$router.push({
            to: "/cases",
          });
        } else {
          await this.$store.dispatch("cases/fetchCaseInformation", this.caseId);
          if (!this.getCaseInformation) {
            this.disablePatientInformation = true;
            this.showTertiaryInsuranceForm = true;
          }

          if (
            this.getCaseInformation &&
            this.getCaseInformation.internal_documents
          ) {
            this.allInternalDocuments =
              this.getCaseInformation.internal_documents || [];
            this.allInternalDocuments = this.allInternalDocuments.sort(
              (a, b) => moment(b.created_at) - moment(a.created_at)
            );
            this.noDocuments = this.allInternalDocuments.length ? false : true;
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
