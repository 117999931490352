<template>
  <section class="marketers" id="volume-stats-section" v-loading="loading">
    <div class="container-default">
      <div class="marketers__statsBlock">
        <div class="marketers__statsNavbar">
          <el-row type="flex">
            <el-col :lg="24">
              <div class="left-block">
                <ul class="justify-start">
                  <li>
                    <div class="form-element search-with-filter-input">
                      <el-select
                        v-model="manager_id"
                        placeholder="Select Manager"
                        @change="fetchManagerMarketers"
                      >
                        <el-option
                          v-for="(manager, index) in managersList"
                          :key="index"
                          :label="manager.first_name + ' ' + manager.last_name"
                          :value="manager._id"
                        >
                        </el-option>
                      </el-select>
                      <span class="search-icon">
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid"
                        />
                      </span>
                    </div>
                  </li>
                  <li>
                    <div class="form-element search-with-filter-input">
                      <el-select
                        v-model="marketer_ids"
                        placeholder="Select Territory Manager"
                      >
                        <el-option
                          v-for="(marketer, index) in managerWiseMarketers"
                          :key="index"
                          :label="
                            marketer.first_name + ' ' + marketer.last_name
                          "
                          :value="marketer._id"
                        >
                        </el-option>
                      </el-select>
                      <span class="search-icon">
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid"
                        />
                      </span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <div class="date-picker">
                      <el-date-picker
                        v-model="value1"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        :shortcuts="shortcuts"
                        :disabled-date="disabledDate"
                        format="MM-DD-YYYY"
                        value-format="YYYY-MM-DD"
                      >
                      </el-date-picker>
                    </div>
                  </li>
                  <li>
                    <el-button
                      class="btn button-with-icon with-blue-bg"
                      @click="fetchMarketers"
                      :disabled="checkFiltersData"
                    >
                      GET DATA
                    </el-button>
                  </li>
                  <li>
                    <el-button
                      class="btn button-with-icon cancel"
                      @click="clearFilters()"
                      :disabled="checkFiltersData"
                    >
                      CLEAR
                    </el-button>
                  </li>
                  <li
                    v-if="this.manager_id && this.value1 && this.value1.length"
                  >
                    <el-button
                      class="btn button-with-icon"
                      @click="exportTableData"
                    >
                      <span class="mr-8">
                        <img
                          src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                          alt="icon"
                          class="inactive"
                        />
                        <img
                          src="@/assets/images/icons/export-icon-white.svg"
                          alt="icon"
                          class="active"
                        />
                      </span>
                      <span>Export</span>
                    </el-button>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="marketers__volumeListing">
          <div class="marketerStatsListng" id="all-cases-listings">
            <el-table :data="this.usersList" height="calc(100vh - 235px)">
              <el-table-column type="index" label="S No." width="100" />
              <el-table-column prop="date" label="MARKETER NAME" width="350">
                <template #default="scope">
                  <p class="marketer-name">
                    {{ scope.row.first_name + " " + scope.row.last_name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(head, index) in tableFilterCategoryHeaders"
                :key="index"
                :label="getDate(head.label)"
              >
                <el-table-column
                  width="150"
                  :prop="item.value"
                  :label="item.label"
                  v-for="(item, index) in head.tableHeader"
                  :key="index"
                >
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog top="10%" width="35%" v-model="noDataPopup">
        <div class="dialog-header">
          <span class="header-part"> Warning! </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="noDataPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date text-center">
          <img src="@/assets/images/icons/cases/warning-icon.svg" alt="icon" />
        </div>

        <h2 class="text-center">No Data Found With This Filter!</h2>
        <div class="popup-date text-center">
          <h3>Please Select Any Filter</h3>

          <div class="dialog-footer justify-center">
            <el-button class="blue-save-button" @click="noDataPopup = false">
              Ok!</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
// import { jsontoexcel } from "vue-table-to-excel";
import * as XLSX from "xlsx";
import FilterHelper from "@/mixins/FilterHelper";
export default {
  data() {
    return {
      layout: "div",
      usersList: [],
      managersList: [],
      manager_id: "",
      marketer_ids: [],
      managerWiseMarketers: [],
      loading: false,
      orderBy: "",
      orderType: "",
      page: 1,
      pageSize: 25,
      newMarketersList: [],
      value1: [],
      noDataPopup: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      shortcuts: [
        {
          text: "Today",
          value: [new Date(), new Date()],
        },
        {
          text: "Yesterday",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            return [start, end];
          },
        },
        {
          text: "Last 30 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 30);
            return [start, end];
          },
        },
        {
          text: "This month",
          value: () => {
            const end = new Date();
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            );
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            return [start, end];
          },
        },
        {
          text: "Last 6 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          },
        },
        {
          text: "Last 9 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 9);
            return [start, end];
          },
        },
        {
          text: "Last year",
          value: () => {
            const end = new Date(new Date().getFullYear() - 1, 11, 31);
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            return [start, end];
          },
        },
        {
          text: "This year",
          value: () => {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            return [start, end];
          },
        },
      ],
      tableHeader: [],
      tableFilterCategoryHeaders: [],
    };
  },
  mixins: [FilterHelper],
  computed: {
    ...mapGetters("marketerStats", [
      "getAllMarketers",
      "getManagerWiseMarketers",
    ]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    checkFiltersData() {
      if (this.manager_id && this.value1 && this.value1.length) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.fetchMarketerAccounts();
    await this.fetchManagerAccounts();
  },
  methods: {
    getDate(dateString) {
      const startDate = new Date(dateString.split("_")[0]);
      const endDate = new Date(dateString.split("_")[1]);
      const formattedStartDate = this.formatDate(startDate);
      const formattedEndDate = this.formatDate(endDate);
      return `${formattedStartDate} to ${formattedEndDate}`;
    },
    formatDate(date) {
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = date.getUTCDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    fetchManagerMarketers() {
      if (this.manager_id) {
        this.fetchManagerWiseMarketers(this.manager_id);
      }
      this.marketer_ids = "";
    },
    clearFilters() {
      (this.manager_id = ""),
        (this.marketer_ids = []),
        (this.tableFilterCategoryHeaders = []),
        (this.value1 = []);
      this.fetchMarketerAccounts();
    },
    async fetchManagerWiseMarketers(manager) {
      try {
        let managerId = manager;
        await this.$store.dispatch(
          "marketerStats/fetchManagerWiseMarketersData",
          managerId
        );
        if (
          this.getManagerWiseMarketers &&
          this.getManagerWiseMarketers.length
        ) {
          this.managerWiseMarketers = this.getManagerWiseMarketers;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMarketerAccounts() {
      try {
        let params = {
          get_all: true,
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          // Filter the array based on user_type
          this.usersList = this.getAllUsers.data.filter(
            (user) =>
              user.user_type === "HOSPITAL_MARKETING_MANAGER" ||
              user.user_type === "MARKETER"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchManagerAccounts() {
      try {
        let params = {
          get_all: true,
          user_type: "HOSPITAL_MARKETING_MANAGER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.managersList = this.getAllUsers.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMarketers() {
      try {
        this.loading = true;
        let params = {};
        if (this.manager_id) {
          await this.fetchManagerWiseMarketers(this.manager_id);
          params.manager_id = this.manager_id;
        }
        if (this.marketer_ids && this.marketer_ids.length) {
          params.marketer_ids = [this.marketer_ids];
        }
        if (this.value1 && this.value1.length) {
          params.from_date = this.value1[0] + "T00:00:00.000Z";
          params.to_date = this.value1[1] + "T00:00:00.000Z";
        }
        await this.$store.dispatch("marketerStats/fetchAllMarketers", params);
        let keyPrefix = params.from_date + "_" + params.to_date;

        if (
          this.getAllMarketers &&
          this.getAllMarketers.data &&
          this.getAllMarketers.data.length
        ) {
          this.noDataPopup = false;
          this.usersList = this.usersList
            .filter((user) => {
              // Include only users whose _id exists in marketer_id of getAllMarketers.data
              return this.getAllMarketers.data.some(
                (item) => item.marketer_id === user._id
              );
            })
            .map((e) => {
              let exists = this.getAllMarketers.data.find(
                (item) => item.marketer_id == e._id
              );
              if (exists) {
                e[`${keyPrefix}_hospitals_count`] = exists._sum.hospitals_count;
                e[`${keyPrefix}_total_cases`] = exists._sum.total_cases;
              } else {
                e[`${keyPrefix}_hospitals_count`] = 0;
                e[`${keyPrefix}_total_cases`] = 0;
              }
              return e;
            });
        } else if (
          this.getAllMarketers &&
          this.getAllMarketers.data &&
          !this.getAllMarketers.data.length
        ) {
          this.usersList = this.usersList.map((e) => {
            e[`${keyPrefix}_hospitals_count`] = 0;
            e[`${keyPrefix}_total_cases`] = 0;
            return e;
          });
          this.noDataPopup = true;
        } else {
          this.noDataPopup = true;
        }

        if (params.from_date && params.to_date) {
          let hospitalKey = `${keyPrefix}_hospitals_count`;
          let totalKey = `${keyPrefix}_total_cases`;

          let categoryHeader = this.tableFilterCategoryHeaders.find(
            (category) => category.label === keyPrefix
          );

          if (!categoryHeader) {
            this.tableFilterCategoryHeaders.push({
              label: keyPrefix,
              tableHeader: [
                {
                  label: "NO OF FACILITIES",
                  value: hospitalKey,
                },
                {
                  label: "TOTAL CASES",
                  value: totalKey,
                },
              ],
            });
          }
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    exportTableData() {
      let currentDate = new Date();

      let formattedDate = currentDate.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const modifiedData = this.usersList.map((item) => {
        const exportData = {};

        // Extract all keys with "_hospitals_count" and "_total_cases"
        const hospitalsCountKeys = Object.keys(item).filter((key) =>
          key.includes("_hospitals_count")
        );
        const totalCountKeys = Object.keys(item).filter((key) =>
          key.includes("_total_cases")
        );

        // Check if the user has data for "NO OF FACILITIES" and "TOTAL CASES"
        if (hospitalsCountKeys.length > 0) {
          // Include "MARKETER NAME" only once
          exportData["MARKETER NAME"] = item.first_name + " " + item.last_name;
        }

        // Iterate over the keys and extract values
        hospitalsCountKeys.forEach((hospitalsCountKey, index) => {
          const noOfFacilities = item[hospitalsCountKey];
          const totalCountKey = totalCountKeys[index];
          const noOfCases = totalCountKey ? item[totalCountKey] : null;

          // Add the values to exportData
          exportData[`NO OF FACILITIES_${index + 1}`] = noOfFacilities;
          exportData[`TOTAL CASES_${index + 1}`] = noOfCases;
        });

        return exportData;
      });

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(modifiedData);

      const headerRowStyle = {
        fill: { fgColor: { rgb: "8e8e8e" } },
        font: { color: { rgb: "FFFFFF" }, bold: true },
      };

      // Set styles for header row
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: 0 });
      const cell = ws[cellRef] || {};
      cell.s = headerRowStyle;
      ws[cellRef] = cell;

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the file
      XLSX.writeFile(wb, `Volume Stats Combined_${formattedDate}.xlsx`);
    },
  },
};
</script>
