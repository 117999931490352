<template>
  <section id="lookup-popup-section">
    <div class="cases-lookup-popup">
      <el-dialog
        width="70%"
        top="5vh"
        v-model="lookUpModalVisible"
        custom-class="lookup-popup"
        :show-close="false"
        :close-on-press-escape="false"
        :destroy-on-close="handleClose"
      >
        <div class="lookup-header">
          <ul>
            <li>
              <h3 class="title">Patient Information</h3>
            </li>
            <li>
              <el-button
                class="close-btn"
                @click="handleLookUpDialogClose('close-btn')"
              >
                <img
                  src="@/assets/images/icons/cases/close-btn.svg"
                  alt="icon"
                />
              </el-button>
            </li>
          </ul>
          <span class="header-part">
            <div class="patient-history"></div>
          </span>
        </div>
        <div class="lookup-body">
          <div class="popup-details">
            <div class="details-form">
              <el-row :gutter="20">
                <el-form :model="patientInformation">
                  <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item>
                        <label for="text">First Name</label>
                        <el-input
                          v-model="patientInformation.first_name"
                          placeholder="Enter First Name"
                          class="el-input"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="first_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item>
                        <label for="text">Middle Name</label>
                        <el-input
                          v-model="patientInformation.middle_name"
                          placeholder="Enter Middle Name"
                          class="el-input"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="middle_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item>
                        <label for="text">Last Name</label>

                        <el-input
                          v-model="patientInformation.last_name"
                          placeholder="Enter Last Name"
                          class="el-input"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="last_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item>
                        <label for="text">Gender</label>
                        <div class="gender-select">
                          <el-select
                            v-model="patientInformation.gender"
                            placeholder="Select Gender"
                            filterable
                            default-first-option
                            clearable
                          >
                            <el-option
                              v-for="(gender, index) in genderOptions"
                              :key="index"
                              :label="gender"
                              :value="gender"
                            ></el-option>
                          </el-select>
                          <FormError errorName="gender"></FormError>
                        </div>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                    <div class="date-of-birth case-creation__input">
                      <dobVersion
                        v-if="showDob"
                        :dobData="patientInformation"
                        :validataionErrors="getErrors"
                        ref="dobField"
                        :errorKey="'date_of_birth'"
                        :displayRequired="false"
                      ></dobVersion>
                    </div>
                  </el-col>
                  <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item>
                        <label for="text">EMR</label>

                        <el-input
                          v-model="patientInformation.emr"
                          placeholder="Enter EMR"
                          class="el-input"
                          @keypress="allowAlphaNumericSpace($event)"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="emr"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-form>
              </el-row>
            </div>
          </div>
          <div class="refine-actions">
            <ul>
              <li>
                <h4>Enter Additional Search Criteria Above To Refine Search</h4>
              </li>

              <li>
                <el-button @click="clearInputSearch">Clear Filters</el-button>

                <el-button class="button blue-btn" @click="checkInputSearch"
                  >Refine search</el-button
                >
              </li>
            </ul>
          </div>

          <div class="history-count" v-if="tableVisible == true">
            <h3>
              HISTORY
              <span>({{ this.patientsList.total }})</span>
            </h3>
          </div>
          <div class="history-table" v-loading="loading">
            <h1 v-if="tableVisible == true">PATIENT DETAILS FORM HISTORY</h1>
            <div
              v-if="tableVisible == true"
              class="listings listings__table listings__all-cases"
              id="all-cases-listings"
            >
              <el-table
                :data="patientsList.data"
                ref="multipleTable"
                @selection-change="handleSelectionChange"
                :row-class-name="tableRowClassName"
              >
                <el-table-column type="selection" max-width="40" />
                <el-table-column label="ACCESSION ID" prop="accession_id">
                  <template #default="scope">{{
                    scope.row.accession_id
                  }}</template>
                </el-table-column>
                <el-table-column label="NAME" prop="firstname">
                  <template #default="{ row }">{{
                    getPatientNameByInfo(row.patient_info)
                  }}</template>
                </el-table-column>
                <el-table-column prop="gender" label="GENDER" max-width="100">
                  <template #default="scope">
                    <span v-if="scope.row.patient_info">{{
                      scope.row.patient_info.gender
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="dob" label="DOB" max-width="150">
                  <template #default="scope">
                    <span v-if="scope.row.patient_info">{{
                      formatDate(scope.row.patient_info.date_of_birth)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="ADDRESS"
                  prop="address_line_1"
                  min-width="150"
                >
                  <template #default="scope">
                    <span
                      class="table_cell-ellipsis"
                      v-if="scope.row.patient_info"
                      >{{ scope.row.patient_info.address_line_1 }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              class="listings listings__pagination"
              v-if="tableVisible == true"
            >
              <el-pagination
                v-model:currentPage="page"
                v-model:page-size="pageSize"
                :page-sizes="[10, 25, 50, 100]"
                layout=" prev, pager, next, jumper, sizes ,total"
                :total="patientsList.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <div class="lookup-footer" v-if="tableVisible == true">
              <ul>
                <li>
                  <el-button
                    class="select-button"
                    @click="handleSelectPatientInfo()"
                    :disabled="!multipleSelection || !multipleSelection.length"
                    plain
                    >SUBMIT</el-button
                  >
                </li>
                <li>
                  <el-button
                    class="cancel-button"
                    @click="handleLookUpDialogClose('cancel')"
                    >CANCEL</el-button
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
const dobVersion = defineAsyncComponent(() =>
  import("@/components/misc/DobNewFormat")
);
import AppHelper from "@/mixins/AppHelper";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);

export default {
  name: "lookUpPopup",
  props: ["lookupSearchInfo"],
  components: {
    dobVersion,
    FormError,
  },
  mixins: [AppHelper],
  data() {
    return {
      lookUpModalVisible: true,
      loading: false,
      page: 1,
      pageSize: 10,
      showDob: false,
      multipleSelection: [],
      genderOptions: ["MALE", "FEMALE", "OTHERS"],
      patientInformation: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        emr: "",
      },
      list: [],
      patientsList: {},
      tableVisible: false,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.showDob = true;
    }, 100);

    this.patientInformation = { ...this.lookupSearchInfo };
    await this.fetchAllPatients();
  },

  computed: {
    ...mapGetters("patients", ["getAllPatients"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  methods: {
    clearInputSearch() {
      this.patientInformation = {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        pms_or_pws: "",
      };
      this.tableVisible = false;
    },
    handle(row, column, event) {
      //list - selected data
      let index = this.list.findIndex((item) => {
        //determine whether the data already exists in the selected array
        return item.id == row.id;
      });
      if (index == -1) {
        //If it does not exist, set the selected state and add this data to the list
        this.$refs.multipleTable.toggleRowSelection(row, true); //Set the checkbox to the selected state
        this.list.push(row);
      } else {
        //If it already exists, set the unselected state and delete this data in the list
        this.$refs.multipleTable.toggleRowSelection(row, false); //Set the check box to the unselected state
        this.list.splice(index, 1);
      }
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllPatients();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllPatients();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // this.checkSelectionLength();
      if (this.multipleSelection.length > 1) {
        this.$refs.multipleTable.toggleRowSelection(this.multipleSelection[0]);
      }
    },
    getPatientFormData() {
      return this.getFormattedValues(this.patientInformation);
    },
    checkInputSearch() {
      const dobField = this.$refs["dobField"];
      if (
        !this.patientInformation.date_of_birth &&
        (dobField.day || dobField.year || dobField.month)
      ) {
        this.$message({
          message: "Please select valid DOB!",
          type: "warning",
        });
        this.loading = false;

        return null;
      }

      const info = this.patientInformation;

      if (
        Object.keys(
          info.first_name ||
            info.last_name ||
            info.middle_name ||
            info.gender ||
            info.date_of_birth
        ).length < 1
      ) {
        this.$message({
          message: "Please select an option to search!",
          type: "warning",
        });

        return null;
      }

      setTimeout(() => {
        this.fetchAllPatients();
      }, 100);
    },
    async fetchAllPatients() {
      try {
        this.loading = true;
        let params = this.getPatientFormData();
        params.page = this.page;
        params.limit = this.pageSize;
        await this.$store.dispatch("patients/fetchAllPatients", params);
        this.tableVisible = true;
        this.patientsList = JSON.parse(JSON.stringify(this.getAllPatients));
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    handleLookUpDialogClose(event) {
      this.patientInformation = {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        pms_or_pws: "",
      };
      this.$emit("closeLookUpPopup");
    },
    handleSelectPatientInfo() {
      this.$emit("selectPatientInfo", this.multipleSelection[0]);
    },
    handleClose() {
      console.log("hi");
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      }
      return "warning-row";
    },
  },
};
</script>

<style lang="scss">
#lookup-popup-section {
  .cases-lookup-popup {
    .el-dialog {
      background: #ffffff;
      border: 1.2px solid #e7e7f7;
      border-radius: 6.40567px;
    }
    .lookup-header {
      border-bottom: 1.2px solid #e7e7f7;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          .title {
            color: #1b2459;
            font-weight: 600;
            min-height: 38px;
            line-height: 40px;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0;
            padding: 0 20px;
            border-right: 1.2px solid #e7e7f7;
          }
          .close-btn {
            border: none;
            margin-right: 10px;
            padding: 0;
            background-color: transparent;

            &:active,
            &:hover {
              background-color: transparent;
            }
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
    .lookup-popup {
      .el-dialog__header {
        padding: 0;
        .icon {
          display: none;
        }
      }
      .el-dialog__body {
        padding: 0;
      }
      .el-dialog {
        border-radius: 10px;
        min-height: 590px;
      }
    }
    .refine-actions {
      padding: 5px 20px;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          h4 {
            color: #34495e;
            font-size: 13px;
            text-transform: capitalize;
          }
          button {
            min-height: 30px;
            padding: 0px 11px;
            color: #262626;
            letter-spacing: 0;
            text-transform: uppercase;
            background: #ffffff;
            border: 0.719521px solid #dedbdb;
            border-radius: 4px;

            &:hover,
            &:active {
              background-color: #eb5757;
              // background-color: #fe7968;
              color: #fff;
              border-color: #eb5757;
            }
            &.blue-btn {
              background-color: #273480;
              border-color: #273480;
              color: #fff;
            }
          }
        }
      }
    }
    .history-count {
      background-color: #1b2459;
      padding: 4px 20px;
      margin-top: 6px;
      color: #fff;
      h3 {
        font-size: 13px;
        font-weight: 600;
        span {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
    .history-table {
      width: 98%;
      margin: auto;
      h1 {
        padding: 10px 0;
        text-align: center;
        color: #1b2459;
        font-weight: 600;
        font-size: 16px;
      }
      .listings__table {
        height: 250px;
        overflow: auto;
      }
      .listings__table .el-table table tbody tr td .cell {
        height: 38px;
        line-height: 2.4;
      }
      .listings__table .el-table table tbody tr td.el-table__cell {
        border: none !important;
      }
      .listings__table .el-table table thead tr th {
        &:first-child {
          border-radius: 6.5px 0 0 6.5px;
          border-left: 0.633951px solid #e0e0e0 !important;
        }

        &:last-child {
          border-radius: 0 6.5px 6.5px 0;
          border-right: 0.633951px solid #e0e0e0 !important;
        }

        &.el-table__cell {
          padding: 0;
          border: 0.633951px solid #e0e0e0;
          border-left: none;
          border-right: none;
        }
      }
      .listings__table .el-table table .el-checkbox {
        height: 16px;
        position: relative;
        top: 2px;
      }
      .el-table::before {
        height: 0;
      }

      .warning-row:nth-child(odd) {
        background-color: #f2f2f2;
        border: none;
      }
      .el-table thead {
        color: rgba(0, 0, 0, 0.8);

        font-size: 12.5px;
        .el-checkbox {
          opacity: 0;
          visibility: hidden;
        }
      }
      .listings__pagination {
        width: 98.8%;
        background: #ffffff;
        border: 0.578032px solid #e0e0e0;
        border-radius: 7.31213px;
      }
    }
    .lookup-footer {
      padding: 15px 0;
      width: 98.8%;

      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
        .el-button {
          min-height: 30px;
          background-color: #0a5da6;
          color: #fff;
          padding: 7px 15px;
          border-radius: 4px;
          border: none;
          &.cancel-button {
            background: #ffffff;
            border: 0.719521px solid #dedbdb;
            color: #262626;
          }
          &.select-button {
            background-color: #273480;
            border-color: #273480;
            color: #fff;
          }
          &.is-disabled {
            opacity: 0.7;
            background-color: #273480;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#lookup-popup-section {
  .popup-details {
    padding: 20px 20px 0 20px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.1);
      height: 1px;
    }
  }
  .el-table {
    width: 99.5%;
  }
}
</style>
