<template>
  <section class="marketers" id="volume-stats-section" v-loading="loading">
    <div class="container-default">
      <div class="marketers__statsBlock">
        <div class="marketers__statsNavbar">
          <div class="left-block">
            <ul class="justify-start">
              <li>
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="manager_id"
                    placeholder="Select Manager"
                    @change="fetchManagerMarketers"
                  >
                    <el-option
                      v-for="(manager, index) in managersList"
                      :key="index"
                      :label="manager.first_name + ' ' + manager.last_name"
                      :value="manager._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li>
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="marketer_ids"
                    placeholder="Select Territory Manager"
                  >
                    <el-option
                      v-for="(marketer, index) in managerWiseMarketers"
                      :key="index"
                      :label="marketer.first_name + ' ' + marketer.last_name"
                      :value="marketer._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div class="date-picker">
                  <el-date-picker
                    v-model="value1"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                    :disabled-date="disabledDate"
                    format="MM-DD-YYYY"
                    value-format="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </div>
              </li>
              <li>
                <el-button
                  class="btn button-with-icon with-blue-bg"
                  @click="fetchMarketers()"
                  :disabled="checkFiltersData"
                >
                  GET DATA
                </el-button>
              </li>
              <li
                v-if="
                  this.manager_id &&
                  this.value1 &&
                  this.value1.length &&
                  this.noDataPopup == false
                "
              >
                <el-button
                  class="btn button-with-icon cancel"
                  @click="clearMarketerData"
                >
                  CLEAR
                </el-button>
              </li>
              <li v-if="this.manager_id && this.value1 && this.value1.length">
                <el-button
                  class="btn button-with-icon"
                  @click="exportTableData"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/export-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>Export</span>
                </el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="marketers__volumeListing">
          <div class="marketerStatsListng">
            <el-table
              :data="this.usersList"
              height="calc(100vh - 235px)"
              :default-sort="{
                prop: orderBy,
                order: orderType == 'asc' ? 'ascending' : 'descending',
              }"
              @sort-change="handleSortChange"
            >
              <el-table-column type="index" label="S No." width="70" />
              <el-table-column
                prop="date"
                label="MARKETER NAME"
                width="350"
                sortable
              >
                <template #default="scope">
                  <p class="marketer-name">
                    {{ scope.row.first_name + " " + scope.row.last_name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="NO OF FACILITIES"
                prop="hospitals_count"
                sortable
              >
                <template #header>
                  <p class="header">NO OF FACILITIES</p>
                  <p class="total-count">
                    {{ totalHospitalsCount ? totalHospitalsCount : "0" }}
                  </p>
                </template>
                <template #default="scope">
                  <p class="amount default">
                    {{ scope.row.hospitals_count }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="PENDING CASES"
                prop="pending_cases"
                sortable
              >
                <template #header>
                  <p class="header">PENDING CASES</p>
                  <p class="total-count pending">
                    {{ totalPendingCount ? totalPendingCount : "0" }}
                  </p>
                </template>
                <template #default="scope">
                  <p class="amount pending">
                    {{ scope.row.pending_cases }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="FINALIZED CASES"
                prop="completed_cases"
                sortable
              >
                <template #header>
                  <p class="header">FINALIZED CASES</p>
                  <p class="total-count paid">
                    {{ totalCompleteCount ? totalCompleteCount : "0" }}
                  </p>
                </template>
                <template #default="scope">
                  <p class="amount finalized">
                    {{ scope.row.completed_cases }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="TOTAL CASES" prop="total_cases" sortable>
                <template #header>
                  <p class="header">TOTAL CASES</p>
                  <p class="total-count total">
                    {{ totalCasesCount ? totalCasesCount : "0" }}
                  </p>
                </template>
                <template #default="scope">
                  <p class="amount total">
                    {{ scope.row.total_cases }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog top="10%" width="35%" v-model="noDataPopup">
        <div class="dialog-header">
          <span class="header-part"> Warning! </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="noDataPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date text-center">
          <img src="@/assets/images/icons/cases/warning-icon.svg" alt="icon" />
        </div>

        <h2 class="text-center">No Data Found With This Filter!</h2>
        <div class="popup-date text-center">
          <h3>Please Select Any Filter</h3>

          <div class="dialog-footer justify-center">
            <el-button class="blue-save-button" @click="noDataPopup = false">
              Ok!</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
// import { jsontoexcel } from "vue-table-to-excel";
import * as XLSX from "xlsx";
import FilterHelper from "@/mixins/FilterHelper";
export default {
  data() {
    return {
      layout: "div",
      usersList: [],
      managersList: [],
      manager_id: "",
      marketer_ids: "",
      managerWiseMarketers: [],
      loading: false,
      orderBy: "",
      orderType: "",
      page: 1,
      pageSize: 25,
      newMarketersList: [],
      value1: [],
      noDataPopup: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      shortcuts: [
        {
          text: "Today",
          value: [new Date(), new Date()],
        },
        {
          text: "Yesterday",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            return [start, end];
          },
        },
        {
          text: "Last 30 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 30);
            return [start, end];
          },
        },
        {
          text: "This month",
          value: () => {
            const end = new Date();
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            );
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            return [start, end];
          },
        },
        {
          text: "Last 6 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          },
        },
        {
          text: "Last 9 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 9);
            return [start, end];
          },
        },
        {
          text: "Last year",
          value: () => {
            const end = new Date(new Date().getFullYear() - 1, 11, 31);
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            return [start, end];
          },
        },
        {
          text: "This year",
          value: () => {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            return [start, end];
          },
        },
      ],
    };
  },
  mixins: [FilterHelper],
  computed: {
    ...mapGetters("marketerStats", [
      "getAllMarketers",
      "getManagerWiseMarketers",
    ]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    checkFiltersData() {
      if (this.manager_id && this.value1 && this.value1.length) {
        return false;
      }
      return true;
    },
    totalHospitalsCount() {
      return this.usersList.reduce(
        (total, user) => total + user.hospitals_count,
        0
      );
    },
    totalPendingCount() {
      return this.usersList.reduce(
        (total, user) => total + user.pending_cases,
        0
      );
    },
    totalCompleteCount() {
      return this.usersList.reduce(
        (total, user) => total + user.completed_cases,
        0
      );
    },
    totalCasesCount() {
      return this.usersList.reduce(
        (total, user) => total + user.total_cases,
        0
      );
    },
  },
  async mounted() {
    // this.setQueryParamsData();
    await this.fetchMarketerAccounts();
    await this.fetchManagerAccounts();
  },
  methods: {
    handleSortChange({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "ASC" : "DESC";
      }
    },
    clearMarketerData() {
      this.value1 = [];
      this.manager_id = "";
      this.marketer_ids = "";
      this.usersList = [];
      this.fetchMarketerAccounts();
    },
    fetchManagerMarketers() {
      if (this.manager_id) {
        this.fetchManagerWiseMarketers(this.manager_id);
      }
      this.marketer_ids = "";
    },
    async fetchManagerWiseMarketers(manager) {
      try {
        let managerId = manager;
        await this.$store.dispatch(
          "marketerStats/fetchManagerWiseMarketersData",
          managerId
        );
        if (
          this.getManagerWiseMarketers &&
          this.getManagerWiseMarketers.length
        ) {
          this.managerWiseMarketers = this.getManagerWiseMarketers;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goToMarketerView() {
      this.$router.push({
        name: "MarketerData",
        query: {
          manager_id: this.manager_id,
          marketer_ids: this.marketer_ids,
        },
      });
    },
    async fetchMarketerAccounts() {
      try {
        let params = {
          get_all: true,
          order_by: "first_name",
          order_type: "asc",
        };

        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);

        if (this.getAllUsers && this.getAllUsers.data) {
          // Filter the array based on user_type
          this.usersList = this.getAllUsers.data.filter(
            (user) =>
              user.user_type === "HOSPITAL_MARKETING_MANAGER" ||
              user.user_type === "MARKETER"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchManagerAccounts() {
      try {
        let params = {
          get_all: true,
          user_type: "HOSPITAL_MARKETING_MANAGER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.managersList = this.getAllUsers.data;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchMarketers() {
      try {
        this.loading = true;
        let params = {};
        if (this.manager_id) {
          await this.fetchMarketerAccounts(); // wait for fetchMarketerAccounts to complete
          await this.fetchManagerWiseMarketers(this.manager_id);
          params.manager_id = this.manager_id;
        }
        if (this.marketer_ids && this.marketer_ids.length) {
          params.marketer_ids = [this.marketer_ids];
        }
        if (this.value1 && this.value1.length) {
          params.from_date = this.value1[0] + "T00:00:00.000Z";
          params.to_date = this.value1[1] + "T00:00:00.000Z";
        }
        await this.$store.dispatch("marketerStats/fetchAllMarketers", params);
        // this.updateQueryParams(params);
        if (
          this.getAllMarketers &&
          this.getAllMarketers.data &&
          this.getAllMarketers.data.length
        ) {
          this.noDataPopup = false;
          this.usersList = this.usersList
            .filter((user) => {
              // Include only users whose _id exists in marketer_id of getAllMarketers.data
              return this.getAllMarketers.data.some(
                (item) => item.marketer_id === user._id
              );
            })
            .map((e) => {
              let exists = this.getAllMarketers.data.find(
                (item) => item.marketer_id == e._id
              );

              if (exists) {
                e["hospitals_count"] = exists._sum.hospitals_count;
                e["total_cases"] = exists._sum.total_cases;
                e["pending_cases"] = exists._sum.pending_cases;
                e["completed_cases"] = exists._sum.completed_cases;
              } else {
                e["hospitals_count"] = 0;
                e["total_cases"] = 0;
                e["pending_cases"] = 0;
                e["completed_cases"] = 0;
              }
              return e;
            });
        } else if (
          this.getAllMarketers &&
          this.getAllMarketers.data &&
          !this.getAllMarketers.data.length
        ) {
          this.usersList = this.usersList.map((e) => {
            e["hospitals_count"] = 0;
            e["total_cases"] = 0;
            e["pending_cases"] = 0;
            e["completed_cases"] = 0;
            return e;
          });
          this.noDataPopup = true;
        } else {
          this.noDataPopup = true;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    updateQueryParams(params) {
      let selectedParams = {
        selectedMarketersFromDate: params.from_date,
        selectedMarketersToDate: params.to_date,
        selectedMarketingManager: params.manager_id,
        selectedMarketers: params.marketer_ids,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    setQueryParamsData() {
      if (this.$route.query.selectedMarketingManager) {
        this.fetchManagerWiseMarketers(
          this.$route.query.selectedMarketingManager
        );
        this.manager_id = this.$route.query.selectedMarketingManager;
      }
      if (this.$route.query.selectedMarketers) {
        this.marketer_ids = this.$route.query.selectedMarketers;
      }
      if (
        this.$route.query.selectedMarketersFromDate &&
        this.$route.query.selectedMarketersToDate
      ) {
        const fromDate = this.$route.query.selectedMarketersFromDate.replace(
          "T00:00:00.000Z",
          ""
        );
        const toDate = this.$route.query.selectedMarketersToDate.replace(
          "T00:00:00.000Z",
          ""
        );
        this.value1 = [fromDate, toDate];
        this.fetchMarketers();
      }
    },
    exportTableData() {
      let currentDate = new Date();

      let formattedDate = currentDate.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const modifiedData = this.usersList.map((item) => {
        // Map original keys to custom headers
        return {
          "MARKETER NAME": item.first_name + " " + item.last_name,
          "NO OF FACILITIES": item.hospitals_count,
          "PENDING CASES": item.pending_cases,
          "FINALIZED CASES": item.completed_cases,
          "TOTAL CASES": item.total_cases,
        };
      });
      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(modifiedData);

      const headerRowStyle = {
        fill: { fgColor: { rgb: "8e8e8e" } },
        font: { color: { rgb: "FFFFFF" }, bold: true },
      };

      // Set styles for each header row
      for (let i = 0; i < modifiedData.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: i, c: 0 });
        ws[cellRef].s = headerRowStyle;
      }

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the file
      XLSX.writeFile(wb, `Volume Stats Combined_${formattedDate}.xlsx`);
    },
  },
};
</script>
