<template>
  <section class="all-cases-view" id="marketer-stats">
    <div class="container-default">
      <div class="marketers-navbar mb-5">
        <el-row type="flex">
          <el-col :lg="12">
            <ul class="marketers-menu">
              <li
                :class="{
                  'router-link-active': $route.name == 'AllRevenueStats',
                }"
              >
                <router-link
                  :to="{
                    name: 'AllRevenueStats',
                    query: this.$route.query,
                  }"
                >
                  COMBINED
                </router-link>
              </li>
              <li
                :class="{
                  'router-link-active': $route.name == 'IndividualRevenueStats',
                }"
              >
                <router-link
                  :to="{
                    name: 'IndividualRevenueStats',
                    query: this.$route.query,
                  }"
                >
                  INDIVIDUAL
                </router-link>
              </li>
              <li
                :class="{
                  'router-link-active': $route.name == 'ComparisonRevenueStats',
                }"
              >
                <router-link
                  :to="{
                    name: 'ComparisonRevenueStats',
                    query: this.$route.query,
                  }"
                >
                  ANALYSIS
                </router-link>
              </li>
              <li
                :class="{
                  'router-link-active': $route.name == 'FileUpload',
                }"
              >
                <router-link
                  :to="{
                    name: 'FileUpload',
                    query: this.$route.query,
                  }"
                >
                  FILE UPLOAD
                </router-link>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
    <router-view :layout="layout"></router-view>
  </section>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "combined",
    };
  },
};
</script>

<style lang="scss">
.revenue-stats-tabs {
  .el-tabs__item {
    color: #005475;
    font-weight: 500;
    background-color: #f7f9fb;
    height: 32px;
    line-height: 32px;
    border-radius: 50px;
    padding: 0 20px !important;
    margin: 3px 5px;
  }
  .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 10px;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__nav-scroll {
    overflow: hidden;
    display: flex;
    background: #ffffff;
    justify-content: center;
    width: fit-content;
    padding: 3px 0px;
    border-radius: 20px;
    margin: auto;
  }
  .el-tabs__item.is-active {
    color: #ffffff;
    font-weight: 500;
    background: #005475;
    height: 32px;
    line-height: 32px;
    border-radius: 50px;
    padding: 0 20px !important;
    margin: 3px 5px;
  }
  .el-tabs__active-bar {
    background-color: transparent !important;
  }
}
</style>
