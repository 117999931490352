<template>
    <div>
      <StatusBasedCases :selectedStatuses="statuses"></StatusBasedCases>
    </div>
  </template>
    
    <script>
  const StatusBasedCases = defineAsyncComponent(() =>
    import("@/components/cases/StatusBasedBilledCases")
  );
  import { defineAsyncComponent } from "vue";
  export default {
    components: {
        StatusBasedCases,
    },
    data() {
    return {
      statuses: "Unbilled",
    };
  },
  };
  </script>
    