<template>
  <div class="default-modal" v-loading="loading">
    <el-dialog
      v-model="visible"
      top="5vh"
      width="55%"
      ;
      :title="displayTitle"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="dialog-header">
        <span class="header-part"> Attention! </span>
        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="close"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
        /></el-button>
      </div>
      <div class="icdAttentionPopup pb-0">
        <div class="icdAttentionPopupBlock" v-if="validationErrors">
          <ul class="attentionlists">
            <li
              class="eachLists"
              v-for="(error, index) in validationErrors"
              :key="index"
            >
              <div class="icdValidations">
                <ul>
                  <!-- <li v-if="error.errors">
                    <span>Error Message:</span> {{ error.errors.errorMessage }}
                  </li> -->
                  <li v-if="error.errorCode == 'BILLING_INFO_MISSING'">
                    <p class="pt-10" style="color: #ff4141">
                      {{ error.message }}
                    </p>
                  </li>
                  <li
                    class="eachLists"
                    v-if="error.errorCode == 'INSURANCE_VERIFICATION_FAILED'"
                  >
                    <div class="title">
                      <img src="@/assets/images/icons/caution-icon.svg" />
                      <h3>Insurance Verification Failed!</h3>
                    </div>
                    <p class="para">
                      Insurance verification for this case failed.
                      Please make sure all details are correct to proceed with
                      <br/>insurance verification.
                    </p>
                  </li>
                  <li
                    class="eachLists"
                    v-if="error.errorCode == 'PAYABLE_CODE_NOT_EXISTS'"
                  >
                    <div class="title">
                      <img src="@/assets/images/icons/caution-icon.svg" />
                      <h3>ICD Codes Error</h3>
                    </div>
                    <p class="para">
                      Unrelated ICD codes identified. Please
                      verify and use eligible codes from the list below.
                    </p>
                  </li>
                  <p class="pt-10" style="color: #ff4141">
                    {{ error.errors ? error.errors.errorMessage : "" }}
                  </p>

                  <li
                    class="notMatched"
                    v-if="
                      error.errors &&
                      error.errors.not_matched_values &&
                      error.errors.not_matched_values.length > 0
                    "
                  >
                    <div class="headerBlock">
                      <img src="@/assets/images/icons/mini-error.svg" />
                      <span class="header">Unrelated ICD codes :</span>
                    </div>

                    <ul>
                      <li
                        v-for="(value, index) in error.errors
                          .not_matched_values"
                        :key="index"
                      >
                        {{ value }}
                      </li>
                    </ul>
                  </li>
                  <li
                    class="Matched"
                    v-if="
                      error.errors &&
                      error.errors.payable_codes &&
                      error.errors.payable_codes.length > 0
                    "
                  >
                    <div class="headerBlock">
                      <img src="@/assets/images/icons/mini-success.svg" />
                      <span class="header"
                        >Relevant ICD Codes :
                        {{
                          caseTypeDisplayName(error.errors.original.case_types)
                        }}</span
                      >
                    </div>
                    <ul>
                      <li
                        v-for="(code, index) in error.errors.payable_codes"
                        :key="index"
                      >
                        {{ code }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <p class="para pb-10">
            Please review and update the fields to continue.
          </p>
        </div>
        <div class="dialog-footer pt-0">
          <!-- <el-button
            class="blue-save-button cancel-button"
            @click="discardOrder"
            >Discard Order</el-button
          > -->

          <el-button class="blue-save-button" @click="close">Cancel</el-button>
          <el-button
            class="blue-save-button success-button"
            @click="createCase"
            v-if="this.caseCreation"
            >Proceed</el-button
          >
          <!-- <el-button
            class="blue-save-button"
            @click="createPendingOrder"
            v-if="this.createOrder"
            :loading="buttonLoading"
          >
            Create Pending Order</el-button
          > -->
          <el-button
            class="blue-save-button success-button"
            @click="moveToPending"
            v-if="this.createOrder"
            :loading="loading"
            >Proceed</el-button
          >
          <el-button
            class="blue-save-button success-button"
            @click="createAccession"
            v-if="this.convert"
            :loading="loading"
            >Proceed</el-button
          >
        </div>
      </div>
    </el-dialog>
    <div class="default-modal order-reason-change-model">
      <el-dialog
        v-model="openNotesReasonsDialog"
        top="20vh"
        width="40%"
        title="Provide Reason and Notes"
        @close="handleCloseReason"
        :close-on-click-modal="false"
      >
        <div class="dialog-header">
          <div class="header-part">
            <span>Notes and Reasons</span>
          </div>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="close"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon" />
          </el-button>
        </div>
        <!-- <el-form :model="reasonForm" :rules="reasonFormRules" ref="reasonForm"> </el-form> -->
        <!-- <el-form-item label="Reason" prop="reason">   </el-form-item> -->
        <div class="popup-date pb-0">
          <el-form
            :model="reasonForm"
            :rules="reasonFormRules"
            ref="reasonForm"
          >
            <el-form-item prop="reason">
              <el-select
                v-model="reasonForm.reason"
                placeholder="Select Reason"
                @change="updateNotes"
                multiple
              >
                <!-- Predefined reasons -->
                <el-option
                  v-if="hasInsuranceError"
                  label="Invalid Insurance"
                  value="Invalid Insurance"
                />
                <el-option
                  v-if="hasICDError"
                  label="Unrelated ICD codes"
                  value="Unrelated ICD codes"
                />
              </el-select>
            </el-form-item>
            <br />
            <el-form-item prop="notes">
              <el-input
                type="textarea"
                v-model="reasonForm.notes"
                placeholder="Enter additional notes"
                rows="3"
              ></el-input>
              <p><b>Note :</b> Minimum 3 characters are required</p>
            </el-form-item>
          </el-form>
          <div class="dialog-footer">
            <el-button @click="handleCloseReason">Cancel</el-button>
            <el-button type="primary" @click="submitReasonForm"
              >Submit</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

  <script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";

export default {
  props: [
    "validationErrors",
    "caseCreation",
    "convert",
    "createOrder",
    "caseInformation",
  ],
  data() {
    return {
      visible: true,
      currentWidth: "30%",
      formData: {
        reason: "",
      },
      reasonForm: {
        reason: "",
        notes: "",
      },
      reasonFormRules: {
        reason: [
          { required: true, message: "Reasons are required", trigger: "blur" },
        ],
        notes: [
          { required: true, message: "Notes are required", trigger: "blur" },
        ],
      },
      rules: {
        reason: [
          {
            required: true,
            message: "Invalid reason",
            pattern: /^.\S/,
            trigger: "blur",
          },
        ],
      },
      reasonDialogVisible: false,
      takeStatusChangeReasonDialogTitle: "PENDING",
      toStatus: "PENDING",
      loading: false,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      orderID: "",
      openNotesReasonsDialog: false,
      predefinedOptions: {
        "Invalid Insurance":
          "Please provide valid insurance details along with a copy of the insurance.",
        "Unrelated ICD codes": "Please provide valid ICD codes.",
      },
    };
  },
  mounted() {
    if (this.width) {
      this.currentWidth = this.width + "%";
    }
  },
  computed: {
    ...mapGetters("orders", [
      "getUpdateOrderStatus",
      "getStatusMovingStatus",
      "getPendingOrderCreationStatus",
    ]),
    getCaseTypeMapped(case_type) {
      return this.caseTypeMap[case_type] || "";
    },
    checkReason() {
      if (
        this.formData.reason &&
        this.formData.reason.trim() &&
        this.formData.reason.trim().length &&
        this.formData.reason.trim().length >= 3
      ) {
        return false;
      }

      return true;
    },
    hasInsuranceError() {
      return this.validationErrors.some(
        (error) => error.errorCode === "INSURANCE_VERIFICATION_FAILED"
      );
    },
    hasICDError() {
      return this.validationErrors.some(
        (error) => error.errorCode === "PAYABLE_CODE_NOT_EXISTS"
      );
    },
  },
  methods: {
    caseTypeDisplayName(caseType) {
      return this.caseTypeMap[caseType] || caseType;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visible = false;

          this.$emit("reasonSubmit", this.formData.reason);
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    discardOrder() {
      window.location.reload();
    },
    async createPendingOrder() {
      try {
        this.reasonDialogVisible = true;
        this.populateReasonAndNotes();
      } catch (err) {}
    },
    populateReasonAndNotes() {
      let reason = "";
      let notes = "";

      const hasInsuranceError = this.validationErrors.some(
        (error) => error.errorCode === "INSURANCE_VERIFICATION_FAILED"
      );
      const hasICDError = this.validationErrors.some(
        (error) => error.errorCode === "PAYABLE_CODE_NOT_EXISTS"
      );

      if (hasInsuranceError && hasICDError) {
        reason = "Billing info & ICD Codes invalid";
        notes = "Subscriber not found & ICD codes are invalid";
      } else if (hasInsuranceError) {
        reason = "Billing info is invalid";
        notes = "Subscriber not found";
      } else if (hasICDError) {
        reason = "ICD Code is invalid";
        notes = "ICD codes are invalid for this casetype";
      }

      this.reasonForm.reason = reason;
      this.reasonForm.notes = notes;
    },
    openReasonDialog() {
      this.visible = false;
      this.reasonDialogVisible = true;
    },
    moveToPending() {
      // this.takeStatusChangeReasonDialog = true;
      this.visible = false;
      this.$emit("approveOrder", false);
    },
    createCase() {
      this.visible = false;
      this.$emit("moveCase", false);
    },
    createAccession() {
      // this.visible = false;
      this.openNotesReasonsDialog = true;
    },
    handleCloseReason() {
      this.openNotesReasonsDialog = false;
    },
    async submitReasonForm() {
      this.$refs.reasonForm.validate(async (valid) => {
        if (valid) {
          const data = {
            reasons: this.reasonForm.reason,
            notes: this.reasonForm.notes,
          };
          this.openNotesReasonsDialog = false;

          this.$emit("accessionCreation", data);

          // await this.moveStatus(data);
        }
      });
    },
    async moveStatus(params) {
      try {
        this.loading = true;
        params.id = this.$route.params.order_id || this.orderID;
        await this.$store.dispatch(
          "orders/checkInsuranceVerification",
          params,
          {
            root: true,
          }
        );
        if (this.getStatusMovingStatus && this.getStatusMovingStatus.success) {
          this.$notify({
            type: "success",
            title: "Success",
            message: this.getStatusMovingStatus.message,
          });
          this.openNotesReasonsDialog = false;
          this.$emit("approveOrder", false);
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async updateStatus(data) {
      try {
        let param = this.caseInformation;
        this.loading = true;

        await this.$store.dispatch("orders/pedningOrderCreation", param);
        if (this.getPendingOrderCreationStatus) {
          this.orderID = this.getPendingOrderCreationStatus._id;
          let params = {
            id: this.$route.params.order_id || this.orderID,
            status: this.toStatus,
            notes: data.notes,
            reasons: data.reasons,
          };
          await this.$store.dispatch("orders/changeOrderStatus", params, {
            root: true,
          });
          if (this.getUpdateOrderStatus && this.getUpdateOrderStatus.success) {
            this.$notify({
              type: "success",
              title: "Success",
              message: this.getUpdateOrderStatus.message,
            });
            this.$router.push({
              name: "ActiveOrders",
            });
          }
        } else {
          this.close();
        }

        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    updateNotes() {
      const actions = this.reasonForm.reason
        .map((reason) => this.predefinedOptions[reason])
        .filter((action) => action);
      this.reasonForm.notes = actions.join("\n");
    },
  },
};
</script>

  <style lang="scss"></style>
