<template>
  <section class="marketers" id="volume-stats-section" v-loading="loading">
    <div class="container-default">
      <div class="marketers__statsBlock">
        <div class="marketers__statsNavbar">
          <div class="left-block">
            <ul class="justify-start">
              <li>
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="manager_id"
                    placeholder="Select Manager"
                    @change="fetchManagerMarketers()"
                  >
                    <el-option
                      v-for="(manager, index) in managersList"
                      :key="index"
                      :label="manager.first_name + ' ' + manager.last_name"
                      :value="manager._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li>
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="marketer_ids"
                    placeholder="Select Territory Manager"
                  >
                    <el-option
                      v-for="(marketer, index) in managerWiseMarketers"
                      :key="index"
                      :label="marketer.first_name + ' ' + marketer.last_name"
                      :value="marketer._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li>
                <div class="date-picker">
                  <el-date-picker
                    v-model="value1"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                    :disabled-date="disabledDate"
                    format="MM-DD-YYYY"
                    value-format="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </div>
              </li>
              <li>
                <el-button
                  class="btn button-with-icon with-blue-bg"
                  @click="fetchSingleMarketer"
                  :disabled="checkFiltersData"
                >
                  GET DATA
                </el-button>
              </li>
            </ul>
            <ul
              v-if="
                this.singleMarketerData &&
                this.singleMarketerData.data &&
                this.singleMarketerData.data.length &&
                this.noDataPopup == false
              "
            >
              <li v-if="this.manager_id && this.value1 && this.value1.length">
                <el-button
                  class="btn button-with-icon"
                  @click="exportTableData"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/export-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>Export</span>
                </el-button>
              </li>
              <li
                v-if="
                  this.manager_id &&
                  this.value1 &&
                  this.value1.length &&
                  this.noDataPopup == false
                "
              >
                <el-button
                  class="btn button-with-icon cancel"
                  @click="clearMarketerData"
                >
                  CLEAR
                </el-button>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="marketers__volumeListing"
          v-if="
            this.singleMarketerData &&
            this.singleMarketerData.data &&
            this.singleMarketerData.data.length &&
            this.noDataPopup == false
          "
        >
          <div class="marketerStatsListng singlemarketer" v-loading="loading">
            <el-table
              :data="singleMarketerData.data"
              height="calc(100vh - 235px)"
              :default-sort="{
                prop: orderBy,
                order: orderType == 'asc' ? 'ascending' : 'descending',
              }"
              @sort-change="handleSortChange"
            >
              <el-table-column
                type="index"
                fixed="left"
                label="S No."
                width="90"
              />
              <el-table-column
                label="ORDERING FACILITY NAME"
                width="300"
                fixed="left"
                sortable
                prop="hospital"
              >
                <template #default="scope">
                  <p class="marketer-name">
                    {{ scope.row.hospital }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="CARDIAC"
                prop="cardiac"
                width="110"
                sortable
              >
                <template #header>
                  <p class="header">CARDIAC</p>
                  <p class="total-count total">{{ cardiacTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.cardiac">
                    {{ scope.row.cardiac ? scope.row.cardiac : "0" }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="CGX" prop="cgx" width="100" sortable>
                <template #header>
                  <p class="header">CGX</p>
                  <p class="total-count total">{{ cgxTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.cgx">
                    {{ scope.row.cgx ? scope.row.cgx : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="CLINICAL CHEMISTRY"
                prop="clinical_chemistry"
                width="200"
                sortable
              >
                <template #header>
                  <p class="header">CLINICAL CHEMISTRY</p>
                  <p class="total-count total">{{ clinicalChemistryTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.clinical_chemistry">
                    {{
                      scope.row.clinical_chemistry
                        ? scope.row.clinical_chemistry
                        : "0"
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="COVID" prop="covid" width="90" sortable>
                <template #header>
                  <p class="header">COVID</p>
                  <p class="total-count total">{{ covidTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.covid">
                    {{ scope.row.covid ? scope.row.covid : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="COVID FLU"
                prop="covid_flu"
                width="120"
                sortable
              >
                <template #header>
                  <p class="header">COVID FLU</p>
                  <p class="total-count total">{{ covidFluTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.covid_flu">
                    {{ scope.row.covid_flu ? scope.row.covid_flu : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="DIABETES"
                prop="diabetes"
                width="120"
                sortable
              >
                <template #header>
                  <p class="header">DIABETES</p>
                  <p class="total-count total">{{ diabetesTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.diabetes">
                    {{ scope.row.diabetes ? scope.row.diabetes : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="GASTRO"
                prop="gastro"
                width="110"
                sortable
              >
                <template #header>
                  <p class="header">GASTRO</p>
                  <p class="total-count total">{{ gastroTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.gastro">
                    {{ scope.row.gastro ? scope.row.gastro : "0" }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="GTI STI"
                prop="gti_sti"
                width="110"
                sortable
              >
                <template #header>
                  <p class="header">GTI STI</p>
                  <p class="total-count total">{{ gtiStiTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.gti_sti">
                    {{ scope.row.gti_sti ? scope.row.gti_sti : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="GTI WOMEN'S HEALTH"
                prop="gti_womens_health"
                width="200"
                sortable
              >
                <template #header>
                  <p class="header">GTI WOMEN'S HEALTH</p>
                  <p class="total-count total">{{ gtiWomensHealthTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.gti_womens_health">
                    {{
                      scope.row.gti_womens_health
                        ? scope.row.gti_womens_health
                        : "0"
                    }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column label="NAIL" prop="nail" width="90" sortable>
                <template #header>
                  <p class="header">NAIL</p>
                  <p class="total-count total">{{ nailTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.nail">
                    {{ scope.row.nail ? scope.row.nail : "0" }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="PAD" prop="pad" width="90" sortable>
                <template #header>
                  <p class="header">PAD</p>
                  <p class="total-count total">{{ padTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.pad">
                    {{ scope.row.pad ? scope.row.pad : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column label="PGX" prop="pgx" width="90" sortable>
                <template #header>
                  <p class="header">PGX</p>
                  <p class="total-count total">{{ pgxTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.pgx">
                    {{ scope.row.pgx ? scope.row.pgx : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="PULMONARY"
                prop="pulmonary"
                width="135"
                sortable
              >
                <template #header>
                  <p class="header">PULMONARY</p>
                  <p class="total-count total">{{ pulmonaryTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.pulmonary">
                    {{ scope.row.pulmonary ? scope.row.pulmonary : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="RPP"
                prop="respiratory_pathogen_panel"
                width="90"
                sortable
              >
                <template #header>
                  <p class="header">RPP</p>
                  <p class="total-count total">
                    {{ respiratoryPathogenPanelTotal }}
                  </p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.respiratory_pathogen_panel">
                    {{
                      scope.row.respiratory_pathogen_panel
                        ? scope.row.respiratory_pathogen_panel
                        : "0"
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="TOXICOLOGY"
                prop="toxicology"
                width="140"
                sortable
              >
                <template #header>
                  <p class="header">TOXICOLOGY</p>
                  <p class="total-count total">{{ toxicologyTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.toxicology">
                    {{ scope.row.toxicology ? scope.row.toxicology : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                label="URINALYSIS"
                prop="urinalysis"
                width="140"
                sortable
              >
                <template #header>
                  <p class="header">URINALYSIS</p>
                  <p class="total-count total">{{ urinalysisTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.urinalysis">
                    {{ scope.row.urinalysis ? scope.row.urinalysis : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column label="UTI" prop="uti" width="90" sortable>
                <template #header>
                  <p class="header">UTI</p>
                  <p class="total-count total">{{ utiTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.uti">
                    {{ scope.row.uti ? scope.row.uti : "0" }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column label="WOUND" prop="wound" width="110" sortable>
                <template #header>
                  <p class="header">WOUND</p>
                  <p class="total-count total">{{ woundTotal }}</p>
                </template>
                <template #default="scope">
                  <p v-if="scope.row.wound">
                    {{ scope.row.wound ? scope.row.wound : "0" }}
                  </p>
                </template> </el-table-column
              ><el-table-column label="TOTAL" width="90" fixed="right">
                <template #default="scope">
                  <p class="amount finalized">
                    {{
                      getTotalSum(
                        [scope.row],
                        [
                          "cardiac",
                          "cgx",
                          "clinical_chemistry",
                          "covid",
                          "covid_flu",
                          "diabetes",
                          "gastro",
                          "gti_sti",
                          "gti_womens_health",
                          "nail",
                          "pad",
                          "pgx",
                          "pulmonary",
                          "respiratory_pathogen_panel",
                          "toxicology",
                          "urinalysis",
                          "uti",
                          "wound",
                        ]
                      )
                    }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog top="10%" width="35%" v-model="noDataPopup">
        <div class="dialog-header">
          <span class="header-part"> Warning! </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="noDataPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date text-center">
          <img src="@/assets/images/icons/cases/warning-icon.svg" alt="icon" />
        </div>

        <h2 class="text-center">No Data Found With This Filter!</h2>
        <div class="popup-date text-center">
          <h3>Please Select Any Filter</h3>

          <div class="dialog-footer justify-center">
            <el-button class="blue-save-button" @click="noDataPopup = false">
              Ok!</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { jsontoexcel } from "vue-table-to-excel";
import FilterHelper from "@/mixins/FilterHelper";
export default {
  data() {
    return {
      usersList: [],
      managersList: [],
      manager_id: "",
      marketer_ids: [],
      managerWiseMarketers: [],
      loading: false,
      marketerId: "",
      shortcuts: [
        {
          text: "Today",
          value: [new Date(), new Date()],
        },
        {
          text: "Yesterday",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            return [start, end];
          },
        },
        {
          text: "Last 30 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 30);
            return [start, end];
          },
        },
        {
          text: "This month",
          value: () => {
            const end = new Date();
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            );
            const start = new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            );
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            return [start, end];
          },
        },
        {
          text: "Last 6 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          },
        },
        {
          text: "Last 9 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 9);
            return [start, end];
          },
        },
        {
          text: "Last year",
          value: () => {
            const end = new Date(new Date().getFullYear() - 1, 11, 31);
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            return [start, end];
          },
        },
        {
          text: "This year",
          value: () => {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            return [start, end];
          },
        },
      ],
      value1: [],
      singleMarketerData: [],
      allFetchedData: [],
      dynamicColumns: [],
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      noDataPopup: false,
      orderBy: "",
      orderType: "",
    };
  },
  mixins: [FilterHelper],
  computed: {
    ...mapGetters("marketerStats", [
      "getSingleMarketerData",
      "getManagerWiseMarketers",
    ]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    checkFiltersData() {
      if (this.manager_id && this.value1 && this.value1.length) {
        return false;
      }
      return true;
    },
    cardiacTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.cardiac || 0),
          0
        );
      }
      return 0;
    },

    cgxTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.cgx || 0),
          0
        );
      }
      return 0;
    },

    clinicalChemistryTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.clinical_chemistry || 0),
          0
        );
      }
      return 0;
    },

    covidTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.covid || 0),
          0
        );
      }
      return 0;
    },

    covidFluTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.covid_flu || 0),
          0
        );
      }
      return 0;
    },

    diabetesTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.diabetes || 0),
          0
        );
      }
      return 0;
    },

    gastroTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.gastro || 0),
          0
        );
      }
      return 0;
    },

    gtiStiTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.gti_sti || 0),
          0
        );
      }
      return 0;
    },

    gtiWomensHealthTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.gti_womens_health || 0),
          0
        );
      }
      return 0;
    },

    nailTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.nail || 0),
          0
        );
      }
      return 0;
    },

    padTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.pad || 0),
          0
        );
      }
      return 0;
    },

    pgxTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.pgx || 0),
          0
        );
      }
      return 0;
    },

    pulmonaryTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.pulmonary || 0),
          0
        );
      }
      return 0;
    },

    respiratoryPathogenPanelTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.respiratory_pathogen_panel || 0),
          0
        );
      }
      return 0;
    },

    toxicologyTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.toxicology || 0),
          0
        );
      }
      return 0;
    },

    urinalysisTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.urinalysis || 0),
          0
        );
      }
      return 0;
    },

    utiTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.uti || 0),
          0
        );
      }
      return 0;
    },

    woundTotal() {
      if (this.singleMarketerData && this.singleMarketerData.data) {
        return this.singleMarketerData.data.reduce(
          (sum, row) => sum + (row.wound || 0),
          0
        );
      }
      return 0;
    },
  },

  async mounted() {
    await this.fetchManagerAccounts();
    await this.fetchMarketerAccounts();
    await this.fetchHospitalsData();
  },
  methods: {
    handleSortChange({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "ASC" : "DESC";
      }
    },
    fetchManagerMarketers() {
      if (this.manager_id) {
        this.fetchManagerWiseMarketers(this.manager_id);
      }
      this.marketer_ids = [];
    },
    async fetchManagerWiseMarketers(manager) {
      try {
        let managerId = manager;
        await this.$store.dispatch(
          "marketerStats/fetchManagerWiseMarketersData",
          managerId
        );
        if (
          this.getManagerWiseMarketers &&
          this.getManagerWiseMarketers.length
        ) {
          this.managerWiseMarketers = this.getManagerWiseMarketers;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchManagerAccounts() {
      try {
        let params = {
          get_all: true,
          user_type: "HOSPITAL_MARKETING_MANAGER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
        if (this.getAllUsers && this.getAllUsers.data) {
          this.managersList = this.getAllUsers.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    calculateSum(data, keys) {
      return keys.reduce((sum, key) => sum + (data[key] || 0), 0);
    },
    clearMarketerData() {
      this.value1 = [];
      this.manager_id = "";
      this.marketer_ids = [];
      this.singleMarketerData = [];
    },
    getTotalSum(dataArray, keys) {
      return dataArray.reduce((totalSum, obj) => {
        // Exclude the "hospital" key
        const objWithoutHospital = { ...obj };
        delete objWithoutHospital.hospital;

        // Use calculateSum to sum values for the specified keys
        return totalSum + this.calculateSum(objWithoutHospital, keys);
      }, 0);
    },
    async fetchHospitalsData() {
      try {
        await this.$store.dispatch("hospitals/fetchAllHospitals", {
          get_all: true,
        });
      } catch (err) {
        console.log(err);
      }
    },
    getSelectedHospital(hospitalId) {
      if (this.getAllHospitals && this.getAllHospitals.data) {
        return this.getAllHospitals.data
          .filter((hospital) => hospitalId.indexOf(hospital._id) !== -1)
          .flatMap((hospital) => hospital.name)
          .join(", ");
      }
    },
    async fetchMarketerAccounts() {
      try {
        let params = {
          get_all: true,
          user_type: "MARKETER",
          order_by: "first_name",
          order_type: "asc",
        };
        await this.$store.dispatch("userAccounts/fetchAllUserAccounts", params);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSingleMarketer(manager) {
      try {
        this.loading = true;
        let params = {};
        if (manager) {
          params.manager_id = manager;
        }
        if (this.manager_id) {
          await this.fetchMarketerAccounts(); // wait for fetchMarketerAccounts to complete
          await this.fetchManagerWiseMarketers(this.manager_id);
          params.manager_id = this.manager_id;
        }
        if (this.marketer_ids && this.marketer_ids.length) {
          params.marketer_ids = [this.marketer_ids];
        }
        if (this.value1 && this.value1.length) {
          params.from_date = this.value1[0] + "T00:00:00.000Z";
          params.to_date = this.value1[1] + "T00:00:00.000Z";
        }
        await this.$store.dispatch("marketerStats/fetchMarketerData", params);
        if (
          this.getSingleMarketerData &&
          this.getSingleMarketerData.data &&
          this.getSingleMarketerData.data.length
        ) {
          this.noDataPopup = false;
          this.singleMarketerData = {
            data: this.getSingleMarketerData.data.map((marketerInfo) => ({
              hospital: this.getSelectedHospital(marketerInfo.hospital),

              cardiac: marketerInfo.cardiac,
              cgx: marketerInfo.cgx_panel,
              clinical_chemistry: marketerInfo.clinical_chemistry,
              covid: marketerInfo.covid,
              covid_flu: marketerInfo.covid_flu,
              diabetes: marketerInfo.diabetes,
              gastro: marketerInfo.gastro,
              gti_sti: marketerInfo.gti_sti,
              gti_womens_health: marketerInfo.gti_womens_health,
              nail: marketerInfo.nail,
              pad: marketerInfo.pad_alzheimers,
              pgx: marketerInfo.pgx_test,
              pulmonary: marketerInfo.pulmonary_panel,
              respiratory_pathogen_panel:
                marketerInfo.respiratory_pathogen_panel,
              toxicology: marketerInfo.toxicology,
              urinalysis: marketerInfo.urinalysis,
              uti: marketerInfo.uti,
              wound: marketerInfo.wound,
            })),
          };
        } else if (
          this.getSingleMarketerData &&
          this.getSingleMarketerData.data &&
          !this.getSingleMarketerData.data.length
        ) {
          this.singleMarketerData = [];
          this.noDataPopup = true;
        } else {
          this.noDataPopup = true;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedMarketer: params.marketer_id,
        selectedMarketerFromDate: params.from_date,
        selectedMarketerToDate: params.to_date,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    exportTableData() {
      let currentDate = new Date();

      let formattedDate = currentDate
        .toLocaleString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .replace(/[/:]/g, "-");
      let dataWithTotal = this.singleMarketerData.data.map((row) => {
        const total = this.getTotalSum(
          [row],
          [
            "cardiac",
            "cgx",
            "clinical_chemistry",
            "covid",
            "covid_flu",
            "diabetes",
            "gastro",
            "gti_sti",
            "gti_womens_health",
            "nail",
            "pad",
            "pgx",
            "pulmonary",
            "respiratory_pathogen_panel",
            "toxicology",
            "urinalysis",
            "uti",
            "wound",
          ]
        );
        return { ...row, TOTAL: total };
      });

      let json = {
        data: dataWithTotal,
        head: [
          "ORDERING FACILITY NAME",
          "CARDICAC",
          "CGX",
          "CLINICAL CHEMISTRY",
          "COVID",
          "COVID FLU",
          "DIABETES",
          "GASTRO",
          "GTI STI",
          "GTI WOMEN'S HEALTH",
          "NAIL",
          "PAD",
          "PGX",
          "PULMONARY",
          "RESPIRATORY",
          "TOXICOLOGY",
          "URINALYSIS",
          "UTI",
          "WOUND",
          "TOTAL",
        ],
        fileName: `Volume Stats Individual_${formattedDate}`,
      };
      const { data, head, fileName } = json;
      jsontoexcel.getXlsx(data, head, fileName);
    },
  },
};
</script>
