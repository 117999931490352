import axios from "@/config/axios"

const initialState = () => {
  return {
    files: [],
    sendReportStatus: false,
    singleEMROrder: {},
    singleEMRTestInfo: {},
    caseCreationValidationErrors: null,
    caseCreationStatus: false,
    orderUpdateStatus: false,
    EMROrderviewConfig: {},
    EMROrderFinalizedReports: {},
    allEMRClients: null,
    accessionValidationErrors: null,
    downloadCEmrOrderDataStatus: false,
    caseCreationValidationErrorsCode: null,
    globalSearchEMROrders: [],
    allEMRErrors: false,
    validationEMROrders: [],
    updateValidationStatus: false,
    bulkValidations: false,
    failedEmrOrders: [],
    emrUniBiDirectionalStatus: false,
    failedEmrOrders: [],
    report: null,
    requisitionDownloadStatus: false,
    sendAthenaEMRDiscreteResultsStatus: false,
    createAccessionDetails: null
  }
}

const state = initialState()

const getters = {
  getAllFiles: (state) => state.files,
  getSendReportStatus: (state) => state.sendReportStatus,
  getSingleEMROrder: (state) => state.singleEMROrder,
  getSingleEMRTestInfo: (state) => state.singleEMRTestInfo,
  getCaseCreationValidationErrors: (state) =>
    state.caseCreationValidationErrors,
  getCaseCreationValidationErrorsCode: (state) =>
    state.caseCreationValidationErrorsCode,

  getCaseCreationStatus: (state) => state.caseCreationStatus,
  getOrderUpdateStatus: (state) => state.orderUpdateStatus,
  getSingleEMROrderViewConfig: (state) => state.EMROrderviewConfig,
  getSingleEMROrderReports: (state) => state.EMROrderFinalizedReports,
  getAllEMRClients: (state) => state.allEMRClients,
  getAccessionValidationErrors: (state) => state.accessionValidationErrors,
  getDownloadEmrOrderDataStatus: (state) => state.downloadCEmrOrderDataStatus,
  getGlobalSearchEMROrders: (state) => state.globalSearchEMROrders,
  getAllEMRErrors: (state) => state.allEMRErrors,
  getValidationEMROrders: (state) => state.validationEMROrders,
  getUpdateValidationStatus: (state) => state.updateValidationStatus,
  getBulkValidations: (state) => state.bulkValidations,
  getFailedEmrOrders: (state) => state.failedEmrOrders,
  getEmrUniBiDirectionalStatus: (state) => state.emrUniBiDirectionalStatus,
  getFailedEmrOrders: (state) => state.failedEmrOrders,
  getReport: (state) => state.report,
  getDownloadReportStatus: (state) => state.requisitionDownloadStatus,
  getSendAthenaEMRDiscreteResultsStatus: (state) => state.sendAthenaEMRDiscreteResultsStatus,
  getCreateAccessionDetails: (state) => state.createAccessionDetails,

}

const mutations = {
  setAllFiles(state, files) {
    state.files = files
  },
  setSendReportStatus(state, data) {
    state.sendReportStatus = data
  },
  setSingleEMROrder(state, data) {
    state.singleEMROrder = data
  },
  setSingleEMRTestInfo(state, data) {
    state.singleEMRTestInfo = data
  },
  setSingleEMROrderViewConfig(state, data) {
    state.EMROrderviewConfig = data
  },
  setCaseCreationValidationErrors(state, data) {
    state.caseCreationValidationErrors = data
  },
  setCaseCreationValidationErrorsCode(state, data) {
    state.caseCreationValidationErrorsCode = data
  },
  setCaseCreationStatus(state, data) {
    state.caseCreationStatus = data
  },
  setOrderUpdateStatus(state, data) {
    state.orderUpdateStatus = data
  },
  setCreateAccessionDetails(state, data) {
    state.createAccessionDetails = data
  },
  setSingleEMROrderReports(state, data) {
    state.EMROrderFinalizedReports = data
  },
  setAllEMRClients(state, data) {
    state.allEMRClients = data
  },

  setAccessionValidationErrors(state, data) {
    state.accessionValidationErrors = data
  },
  setDownloadEmrOrderDataStatus(state, status) {
    state.downloadCEmrOrderDataStatus = status
  },
  setValidationEMROrders(state, data) {
    state.validationEMROrders = data
  },
  setUpdateValidationStatus(state, data) {
    state.updateValidationStatus = data
  },
  setGlobalSearchEMROrders(state, data) {
    state.globalSearchEMROrders = data
  },
  setAllEMRErrors(state, data) {
    state.allEMRErrors = data
  },
  setBulkValidations(state, data) {
    state.bulkValidations = data
  },
  setAllFailedEmrOrders(state, data) {
    state.failedEmrOrders = data
  },
  setEmrUniBiDirectionalStatus(state, data) {
    state.emrUniBiDirectionalStatus = data
  },
  setAllFailedEmrOrders(state, data) {
    state.failedEmrOrders = data
  },
  setRequisition(state, report) {
    state.report = report
  },
  setRequisitionDownloadStatus(state, report) {
    state.requisitionDownloadStatus = report
  },
  setSendAthenaEMRDiscreteResultsStatus(state, data) {
    state.sendAthenaEMRDiscreteResultsStatus = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  fetchAllFiles: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/emr-orders", { params })

      commit("setAllFiles", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchSingleEMROrder: async ({ commit, dispatch }, params) => {
    try {
      commit("setSingleEMROrder", null)
      let response = await axios.get("/emr-orders/" + params.order_id)
      commit("setSingleEMROrder", response.data.data)
    } catch (err) {
      commit("setSingleEMROrder", null)
    }
  },
  fetchSingleEMROrderTestsInfo: async ({ commit }, params) => {
    try {
      let emrId = params.emr_id
      delete params.emr_id

      commit("setSingleEMRTestInfo", null)
      let response = await axios.get("/emr-orders/" + emrId + "/tests-info", {
        params,
      })
      commit("setSingleEMRTestInfo", response.data.data)
    } catch (err) {
      commit("setSingleEMRTestInfo", null)
    }
  },
  fetchSingleEMROrderViewConfig: async ({ commit, dispatch }, params) => {
    try {
      commit("setSingleEMROrderViewConfig", null)
      let response = await axios.get(
        "/emr-orders/" + params.order_id + "/config"
      )
      commit("setSingleEMROrderViewConfig", response.data.data)
    } catch (err) {
      commit("setSingleEMROrderViewConfig", null)
    }
  },
  clearNewCaseData: async ({ commit }) => {
    commit("reset", {})
  },
  createAccession: async ({ commit, dispatch }, params) => {
    try {
      commit("setCaseCreationValidationErrors", null)
      commit("errors/clear", null, { root: true })
      commit("setCaseCreationValidationErrorsCode", null)
      commit("setOrderUpdateStatus", false)
      commit("setCreateAccessionDetails", null)
      let order_id = params.emr_order
      delete params.emr_order
      let response = await axios.post(
        "/emr-orders/" + order_id + "/create-accession-number",
        params
      )
      commit("setCreateAccessionDetails", response.data.data)
      commit("setOrderUpdateStatus", true)
    } catch (err) {
      commit("setCreateAccessionDetails", null)

      if (err.response) {
        commit("setCaseCreationValidationErrorsCode", err.response.data)
        let errorData = await dispatch("errors/errorResponse", err.response, {
          root: true,
        })
        commit("setCaseCreationValidationErrors", errorData)
      }
    }
  },
  fetchFinalizedReportsData: async ({ commit, dispatch }, params) => {
    try {
      commit("setSingleEMROrderReports", false)
      let response = await axios.get(
        "/emr-orders/" + params.order_id + "/finalized-reports"
      )
      commit("setSingleEMROrderReports", response.data.data)
    } catch (err) {
      commit("setSingleEMROrderReports", false)
    }
  },
  sendReportToEmr: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendReportStatus", false)
      commit("errors/clear", null, { root: true })
      let order_id = params.order_id
      delete params.order_id
      await axios.post(
        "/emr-orders/" + order_id + "/finalized-reports/send-to-emr",
        params
      )
      commit("setSendReportStatus", true)
    } catch (err) {
      commit("setSendReportStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  updateEmrOrder: async ({ commit, dispatch }, params) => {
    try {
      commit("setCaseCreationValidationErrors", null)
      commit("setCaseCreationValidationErrorsCode", null)

      commit("setOrderUpdateStatus", false)
      let order_id = params.order_id
      delete params.order_id
      delete params.data_update_reason
      await axios.put("/emr-orders/" + order_id, params)
      commit("setOrderUpdateStatus", true)
    } catch (err) {
      if (err.response) {
        commit("setCaseCreationValidationErrorsCode", err.response.data)

        let errorData = await dispatch("errors/errorResponse", err.response, {
          root: true,
        })
        commit("setCaseCreationValidationErrors", errorData)
      }
    }
  },
  fetchAllEMRClients: async ({ commit, dispatch }, params) => {
    try {
      commit("setAllEMRClients", null)
      let response = await axios.get("/emr-clients", { params })
      commit("setAllEMRClients", response.data)
    } catch (err) {
      if (err.response) {
        let errorData = await dispatch("errors/errorResponse", err.response, {
          root: true,
        })
        commit("setCaseCreationValidationErrors", errorData)
      }
    }
  },

  downloadEmrOrderData: async ({ commit }, params) => {
    try {
      commit("setDownloadEmrOrderDataStatus", false)
      const response = await axios.get("/emr-orders/download-as-csv", {
        params,
      })
      commit("setDownloadEmrOrderDataStatus", true)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "emrorders.csv") //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      commit("setDownloadEmrOrderDataStatus", false)
      console.log(error)
    }
  },
  fetchGlobalSearchEMROrdersData: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], {
        root: true,
      })
      let response = []
      if (params) {
        response = await axios.get("/emr-orders-search", {
          params,
        })
      } else {
        response = await axios.get("/emr-orders-search")
      }

      commit("setGlobalSearchEMROrders", response.data)
    } catch (err) {
      commit("setAllEMRErrors", true)
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  fetchValidationEMROrders: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/validation-emr-orders", { params })

      commit("setValidationEMROrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  changeCaseValidationStatus: async ({ commit }, params) => {
    try {
      commit("setUpdateValidationStatus", false)
      await axios.patch(
        "/emr-orders/" + params.id + "/validation-status/" + params.status,
        { validation_reason: params.validation_reason }
      )
      commit("setUpdateValidationStatus", true)
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
    }
  },
  updateBulkValidations: async ({ commit, dispatch }, params) => {
    try {
      let status = params.status
      delete params.status
      commit("setBulkValidations", false)

      await axios.patch(`/emr-orders/validation-status/${status}/bulk`, params)

      commit("setBulkValidations", true)
    } catch (error) {
      commit("setBulkValidations", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  sendEmrUniDirectionResults: async ({ commit, dispatch }, params) => {
    try {
      commit("setEmrUniBiDirectionalStatus", false)
      commit("errors/clear", null, { root: true })
      await axios.post(
        `${process.env.VUE_APP_UNI_DIRECTION_URL}/orders/unsolicited-result`,
        params
      )
      commit("setEmrUniBiDirectionalStatus", true)
    } catch (err) {
      commit("setEmrUniBiDirectionalStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  fetchAllNotSentEmrOrders: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/emr-orders/pending/orders", { params })

      commit("setAllFailedEmrOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },

  fetchRequisitionById: async ({ commit, dispatch }, params) => {
    try {
      let orderId = params.orderId
      delete params.orderId
      commit("setRequisition", null)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(`emr-orders/${orderId}/e-requisition`, {
        params,
      })

      commit("setRequisition", response.data)
    } catch (err) {
      commit("setRequisition", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },

  downloadRequisitionForm: async ({ commit, dispatch }, params) => {
    try {
      let order_id = params.orderId
      delete params.orderId
      commit("setRequisitionDownloadStatus", false)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(
        "emr-orders/" + order_id + "/e-requisition",
        {
          params,
        }
      )
      let data = response.data
      const linkSource = `data:application/pdf;base64,${data}`
      const donwloadLink = document.createElement("a")

      const fileName = "requisition.pdf"
      donwloadLink.href = linkSource
      donwloadLink.download = fileName
      donwloadLink.click()

      commit("setRequisitionDownloadStatus", true)
    } catch (err) {
      commit("setRequisitionDownloadStatus", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  sendAthenaEMRDiscreteResults: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendAthenaEMRDiscreteResultsStatus", false)
      commit("errors/clear", null, { root: true })
      await axios.post(
        "/emr-orders/finalized-reports/send-unsolicited-results",
        params
      )
      commit("setSendAthenaEMRDiscreteResultsStatus", true)
    } catch (err) {
      commit("setSendAthenaEMRDiscreteResultsStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
