<template>
  <div class="default-modal">
    <el-dialog
      width="70%"
      top="15vh"
      v-model="exportReportsDialogVisisble"
      @close="close"
    >
      <div class="export-dialog-header">
        <div class="export-header-part"><h3>EMR ORDERS REPORT</h3></div>
        <el-button
          size="medium"
          class="el-white"
          type="info"
          plain
          @click="close"
        >
          <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
        /></el-button>
      </div>
      <div class="selected-string" v-if="selected_emr_client">
        <h3>
          Selected EMR Client :
          <span class="value">{{
            getSelectedClient(selected_emr_client).name
          }}</span>
        </h3>
      </div>
      <div class="selected-string" v-if="selected_hospital">
        <h3>
          Selected Ordering Facility :
          <span class="value"
            >{{ getSelectedHospital(selected_hospital).name }}
          </span>
        </h3>
      </div>
      <div class="selected-string" v-if="selected_physician">
        <h3>
          Selected Physician :
          <span class="value"
            >{{ getSelectedPhysician(selected_physician).name }}
          </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="
          selected_marketer &&
          Array.isArray(this.selected_marketer) &&
          this.selected_marketer.length
        "
      >
        <h3>
          Selected Marketer :
          <span
            class="value"
            v-for="(marketer, index) of selected_marketer"
            :key="index"
            >{{ getSelectedUser(marketer) }}
          </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="selected_case_type && selected_case_type.length"
      >
        <h3>
          Selected caseType :
          <span class="value">{{ getCaseTypeMap(selected_case_type) }} </span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="selected_case_types && selected_case_types.length"
      >
        <h3>
          Selected caseType :
          <span class="value">{{ getCaseTypeMap(selected_case_types) }} </span>
        </h3>
      </div>
      <div class="selected-string">
        <h3 v-if="selected_string">
          Selected String :
          <span class="value">{{ selected_string }}</span>
        </h3>
        <h3 v-if="selected_dob">
          Selected Date of Birth :<span class="value">{{
            selectedDob(selected_dob)
          }}</span>
        </h3>
        <h3 v-if="selected_accession">
          Selected Accession :<span class="value">{{
            selected_accession
          }}</span>
        </h3>
      </div>
      <div
        class="selected-string"
        v-if="selected_status && this.selected_status.length"
      >
        <h3>
          Selected Status :
          <span class="value" v-if="selected_status == 'COMPLETED'"
            >FINALIZED</span
          >
          <span class="value" v-else-if="selected_status == 'CANCELLED'"
            >REJECTED</span
          >
          <span class="value" v-else>{{ selected_status }} </span>
        </h3>
      </div>
      <div class="selected-string" v-if="selected_from_date">
        <h3>
          Selected From Date :
          <span class="value">{{ selectedDob(selected_from_date) }} </span>
        </h3>
      </div>
      <div class="selected-string" v-if="selected_to_date">
        <h3>
          Selected To Date :
          <span class="value">{{ selectedDob(selected_to_date) }} </span>
        </h3>
      </div>
      <div class="dialog-body">
        <div class="export-popup-body" v-loading="loading">
          <el-checkbox-group v-model="activeFieldList">
            <ul class="select-fields-list">
              <li
                class="search-checkbox"
                v-for="(item, index) in exportEmrOrderExcelFields"
                :key="index"
              >
                <el-checkbox :label="item.value">
                  {{ item.label }}
                </el-checkbox>
              </li>
            </ul>
          </el-checkbox-group>
        </div>
      </div>
      <div class="dialog-footer export-footer w-95">
        <el-button
          class="cancel-button"
          @click="handleClear"
          :disabled="!this.activeFieldList.length"
          >CLEAR ALL</el-button
        >
        <el-button class="cancel-button" @click="handleCheckAllChange"
          >SELECT ALL</el-button
        >
        <el-button class="cancel-button" @click="close">CANCEL</el-button>
        <el-button class="blue-save-button" @click="downloadCases">
          DOWNLOAD
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import AppHelper from "@/mixins/AppHelper";
import exportEmrOrdersHelper from "@/mixins/exportEmrOrdersHelper";
import FilterHelper from "@/mixins/FilterHelper";
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import moment from "moment";
export default {
  mixins: [
    UserPermissionsHelper,
    exportEmrOrdersHelper,
    caseDataHelper,
    AppHelper,
    FilterHelper,
  ],
  props: [
    "selected_case_type",
    "selected_emr_client",
    "selected_hospital",
    "selected_physician",
    "selected_string",
    "selected_dob",
    "selected_accession",
    "selected_status",
    "pregnancy",
    "selected_marketer",
    "selected_from_date",
    "selected_to_date",
    "selected_case_types",
  ],
  computed: {
    ...mapGetters("emrOrders", [
      "getDownloadEmrOrderDataStatus",
      "getAllEMRClients",
    ]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    getCaseTypeMap() {
      return (status) => this.caseTypeMap[status];
    },

    selectedDob() {
      return (selected_dob) => moment(selected_dob).format("YYYY-MM-DD");
    },
  },
  data() {
    return {
      exportReportsDialogVisisble: true,
      fieldList: [],
      activeFieldList: [],
      checkAll: true,
      loading: false,
      date_type: "collection_date",
      accession_id: "",
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      hospital: "",
      ordering_physician: "",
      emr_client: "",
      search_string: "",
      caseType: "",
      dob: "",
      selectedMarketer: "",
    };
  },
  created() {
    this.activeFieldList = [
      ...this.defaultExportEmrOrderExcelFields.map((opt) => opt.value),
    ];
  },
  mounted() {
    this.loading = true;
    this.accession_id = this.selected_accession;
    this.hospital = this.selected_hospital;
    this.emr_client = this.selected_emr_client;
    this.ordering_physician = this.selected_physician;
    this.search_string = this.selected_string;
    this.dob = this.selected_dob;
    this.caseType = this.selected_case_type;
    this.selectedMarketer = this.selected_marketer;
    this.loading = false;
  },
  methods: {
    getSelectedUser(userId) {
      return this.getAllUsers.data
        .filter((user) => userId.indexOf(user._id) != -1)
        .flatMap((user) => user.first_name + " " + user.last_name)
        .join(", ");
    },
    handleClear() {
      this.activeFieldList = [];
      this.fieldList = [];
    },
    getSelectedClient(emrId) {
      return this.getAllEMRClients.data.find((emr) => emr._id == emrId);
    },
    getSelectedHospital(hospitalId) {
      return this.getAllHospitals.data.find(
        (hospital) => hospital._id == hospitalId
      );
    },
    getSelectedPhysician(physicianId) {
      return this.getAllPhysicians.data.find(
        (physician) => physician._id == physicianId
      );
    },
    async downloadCases() {
      if (this.activeFieldList.length) {
        let params = {
          include: this.activeFieldList,
        };

        params.date_type = this.date_type;

        if (this.emr_client != "") {
          params.emr_client = this.emr_client;
        }
        if (this.hospital != "") {
          params.hospital = this.hospital;
        }
        if (this.ordering_physician != "") {
          params.ordering_physician = this.ordering_physician;
        }
        if (this.selectedMarketer && this.selectedMarketer.length) {
          params.hospital_marketers = this.selectedMarketer;
        }
        if (this.search_string != "") {
          params.search_string = this.search_string;
        }
        if (this.selected_case_type && this.selected_case_type.length) {
          params.case_types = this.selected_case_type;
        }
        if (this.selected_case_types && this.selected_case_types.length) {
          params.case_types = [this.selected_case_types];
        }
        if (this.selected_dob) {
          const date_of_birth = moment(this.selected_dob).format("YYYY-MM-DD");

          params.date_of_birth = date_of_birth;
        }
        if (this.selected_accession != "") {
          params.accession_id = this.selected_accession;
        }
        if (
          this.selected_status &&
          Array.isArray(this.selected_status) &&
          this.selected_status.length > 1
        ) {
          params.case_status = this.selected_status;
        } else if (this.selected_status && this.selected_status.length) {
          params.status = this.selected_status.toString();
        } else if (Object.keys(params).indexOf("status") != -1) {
          delete params.status;
        }
        if (this.selected_from_date) {
          const slected_date = this.convertToLabUTC(this.selected_from_date);

          params.from_date = slected_date;
        }
        if (this.selected_to_date) {
          const selected_end_date = this.convertToLabUTC(
            this.selected_to_date,
            true
          );
          params.to_date = selected_end_date;
        }
        if (this.pregnancy) {
          params.pregnant = this.pregnancy;
        }
        this.loading = true;
        await this.$store.dispatch("emrOrders/downloadEmrOrderData", params);
        this.loading = false;
        if (this.getDownloadEmrOrderDataStatus) {
          this.close();
        } else {
          this.$message({
            message: "Error in downloading cases",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "Please select the data fields.",
          type: "warning",
        });
      }
    },
    close() {
      this.$emit("close");
    },
    handleCheckAllChange() {
      this.activeFieldList = [];
      this.fieldList = [];
      this.exportEmrOrderExcelFields.forEach((obj) => {
        this.fieldList.push(obj.value);
      });
      this.activeFieldList = this.fieldList;
    },
  },
};
</script>
