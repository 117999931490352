<template>
  <section v-loading="loading">
    <div class="revenue-stats-upload-block">
      <el-upload
        name="logo"
        drag
        action
        :on-change="handleUpload"
        :show-file-list="false"
        :auto-upload="false"
        accept=".csv"
        :limit="1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload-text-align">
          <div class="el-upload__text-img">
            <img src="@/assets/images/icons/revenue-upload-icon.svg" alt="" />
          </div>
          <div class="el-upload__text">
            <h3>Drag & Drop file</h3>
            <p>
              or <span class="blue-text"> Browse files </span> on your computer
            </p>
          </div>
        </div>
      </el-upload>
      <div class="revenue-attachment-file-block">
        <div v-if="selectedFileName">
          <ul class="revenue-attachment-file">
            <li>
              <div class="revenue-attachment">
                <img src="@/assets/images/icons/pdf-icon_1.svg" alt="" />
                <p>
                  {{ selectedFileName }}
                </p>
                <span class="file-sized">
                  {{ this.selectedFileSize + " KB" }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div class="upload-btn-block">
          <el-button
            v-if="selectedFileName"
            type="text"
            @click="cancel()"
            class="upload-button cancel"
          >
            Cancel
          </el-button>
          <el-button
            class="upload-button"
            @click="uploadRevenueFile"
            :disabled="!selectedFile ? true : false"
          >
            Upload
          </el-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  data() {
    return {
      selectedFile: null,
      selectedFileName: null,
      selectedFileSize: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("revenueStats", [
      "getRevenueStatsFileUploadStatus",
      "getRevenueStatsProcessFileStatus",
    ]),
  },
  mounted() {},
  methods: {
    handleUpload(file) {
      this.selectedFile = file.raw;
      this.selectedFileName = file.raw.name;
      this.selectedFileSize = file.raw.size;
    },
    cancel() {
      this.selectedFile = null;
      this.selectedFileName = "";
      this.selectedFileSize = "";
    },
    async uploadRevenueFile() {
      try {
        this.loading = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        await this.$store.dispatch(
          "revenueStats/uploadRevenueStatsFileUpload",
          data
        );
        if (this.getRevenueStatsFileUploadStatus) {
          await this.processRevenueFile();
          successNotification("File uploaded and processed successfully");
          this.cancel();
        } else {
          errorNotification("Error while uploading file");
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async processRevenueFile() {
      try {
        await this.$store.dispatch("revenueStats/revenueStatsProcessFile");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.revenue-stats-upload-block {
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      background: transparent;
      border-radius: 24px;
      display: flex;
      width: 90%;
      height: 100%;
      padding: 1rem;
      margin: 1.5rem auto 0;
      align-items: center;
      justify-content: center;
      border: 3px dashed #e2e6ea;
    }
    .el-upload-text-align {
      .el-upload__text-img {
        img {
          width: 50px;
          height: 50px;
          padding: 0 10px;
        }
      }
      .el-upload__text {
        line-height: 14px;
        h3 {
          color: #4353ff;
          text-align: center;
          font-size: 22px;
          font-weight: 800;
          padding: 10px 0 15px;
        }
        p {
          color: #1b2459;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          .blue-text {
            color: #4353ff;
          }
        }
      }
    }
  }
  .revenue-attachment-file-block {
    .revenue-attachment-file {
      width: 95%;
      margin: auto;
      li {
        .revenue-attachment {
          display: grid;
          grid-template-columns: 35px auto 75px;
          align-items: center;
          column-gap: 5px;
          padding: 0px 10px 7px 0px;
          border-radius: 5px;
          height: 30px;
          margin: 1rem;
          img {
            padding: 0 10px;
            height: 20px;
            width: 20px;
          }
          p {
            color: #242634;
            font-size: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .file-sized {
            border: 1.3px solid #cdd3d8;
            text-align: center;
            border-radius: 4px;
          }
        }
      }
    }
    .upload-btn-block {
      text-align: center;
      .upload-button {
        border-radius: 24px;
        border: none;
        color: #fff;
        background: #4353ff;
        margin-top: 1.5rem;
        padding: 0.5rem 1.5rem;
        &.cancel {
          border-radius: 24px;
          border: 1px solid #b9b9b9;
          background: #d0d5dd;
          color: #60737d;
        }
      }
    }
  }
}
</style>
