<template>
  <div>
    <AddMilageCase></AddMilageCase>
  </div>
</template>
  
  <script>
const AddMilageCase = defineAsyncComponent(() =>
  import("@/components/cases/AddMilageCase")
);
import { defineAsyncComponent } from "vue";
export default {
  components: {
    AddMilageCase,
  },
};
</script>
