import axios from "@/config/axios"

const initiateState = () => {
  return {
    allPhlebioOrders: [],
    sendOrdersToPhlebio: false,
    singlePhlebioDetails: null,
    updatePhlebioStatus: false,
    caseConversionStatus: false,
    caseData: null,
    phlebioInfo: {},
    validationErrors: {},
    phlebioTestInfo: {},
    orderDetails: null,

  }
}
const state = initiateState()
const getters = {
  getAllPhlebioOrders: (state) => state.allPhlebioOrders,
  getSendOrdersToPhlebioStatus: (state) => state.sendOrdersToPhlebio,
  getSinglePhlebioDetails: (state) => state.singlePhlebioDetails,
  getUpdatePhlebioStatus: (state) => state.updatePhlebioStatus,
  getCaseConversionStatus: (state) => state.caseConversionStatus,
  getCaseData: (state) => state.caseData,
  getPhlebioInfo: (state) => state.phlebioInfo,
  getValidationErrors: (state) => state.validationErrors,
  getPhlebioTestInfo: (state) => state.phlebioTestInfo,
  getScanOrderDetails: (state) => state.orderDetails,

}
const mutations = {
  setAllPhlebioOrders(state, data) {
    state.allPhlebioOrders = data
  },
  setSendOrdersToPhlebio(state, data) {
    state.sendOrdersToPhlebio = data
  },
  setSinglePhlebioDetails(state, data) {
    state.singlePhlebioDetails = data
  },
  setUpdatePhlebioStatus(state, data) {
    state.updatePhlebioStatus = data
  },
  setCaseConversionStatus(state, data) {
    state.caseConversionStatus = data
  },
  setCaseData(state, data) {
    state.caseData = data
  },
  setPhlebioInfo(state, data) {
    state.phlebioInfo = data
  },
  setValidationErrors(state, data) {
    state.validationErrors = data
  },
  setPhlebioTestInfo(state, data) {
    state.phlebioTestInfo = data
  },
  setOrderDetails(state, data) {
    state.orderDetails = data
  },
  reset: (state) => {
    const initial = initiateState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  fetchPhlebioOrders: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders`,
        { params }
      )
      commit("setAllPhlebioOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  sendOrdersToPhlebio: async ({ commit, dispatch }, params) => {
    try {
      let phlebo_id = params.emr_id
      delete params.emr_id
      commit("setSendOrdersToPhlebio", false)
      await axios.post(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/emr-orders/${phlebo_id}/send`,
        {
          to_address_type: params.to_address,
          address_value: params.address_value,
        }
      )
      commit("setSendOrdersToPhlebio", true)
    } catch (error) {
      commit("setSendOrdersToPhlebio", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  phlebioDetails: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/${params.phlebio_id}`
      )
      commit("setSinglePhlebioDetails", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  updatePhlebio: async ({ commit, dispatch }, params) => {
    try {
      let phlebio_id = params.phlebio_id
      delete params.phlebio_id
      commit("setUpdatePhlebioStatus", false)
      await axios.put(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/${phlebio_id}`,
        params
      )
      commit("setUpdatePhlebioStatus", true)
    } catch (error) {
      commit("setUpdatePhlebioStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  prepareCaseData: async ({ commit, dispatch }, params) => {
    try {
      let phlebio_id = params.phlebio_id
      delete params.phlebio_id
      commit("setCaseData", null)
      let response = await axios.get(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/${phlebio_id}/prepare-case-data`,
      )
      commit("setCaseData", response.data)
    } catch (error) {
      commit("setCaseData", null)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },

  convertToCase: async ({ commit, dispatch }, params) => {
    try {

      commit("setCaseConversionStatus", false)
      commit('setValidationErrors', {})
      await axios.post(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/convert-to-case`, params
      )
      commit("setCaseConversionStatus", true)
    } catch (error) {
      commit("setCaseConversionStatus", false)
      if (error.response) {
        commit('setValidationErrors', error.response.data)
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchSinglePhlebioOrderTestsInfo: async ({ commit }, params) => {
    try {
      let phlebioId = params.phlebio_order_id
      delete params.phlebio_order_id

      commit("setPhlebioTestInfo", null)
      let response = await axios.get(`${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/${phlebioId}/case_types/${params.case_type}/tests-info`)
      commit("setPhlebioTestInfo", response.data)
    } catch (err) {
      commit("setPhlebioTestInfo", null)
    }
  },
  setPhlebioInfo({ commit }, params) {
    commit("setPhlebioInfo", params);
  },
  fetchScanOrderById: async ({ commit, dispatch }, params) => {
    try {
      let orderId = params.phlebioOrderId
      delete params.orderId
      commit("setOrderDetails", null)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(`${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/by-phlebio-order-id/${orderId}`,)

      commit("setOrderDetails", response.data.data)
    } catch (err) {
      commit("setOrderDetails", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
}
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
}
