<template>
  <section
    v-loading="loading"
    class="selected-test-preview"
    id="default-test-preview"
  >
    <el-tabs>
      <el-tab-pane
        v-for="(category, index) of getCaseCategoryData(test.case_type_code)"
        :key="index"
      >
        <template #label>
          <div class="custom-tabs-label">
            <span @click="showDefaultTests">
              {{ category.category }}
            </span>
            <el-button
              class="custom-tab-close-btn"
              @click="remove(category)"
              v-if="notCreateCase && isBillingUser && !isReleasePending"
              :disabled="hasCases"
            >
              <img
                src="@/assets/images/fax-tag-close.svg"
                alt="icon"
                width="30"
              />
            </el-button>
          </div>
        </template>
        <div class="default-modal">
          <div
            :class="
              this.caseType && this.caseType === 'RESPIRATORY_PATHOGEN_PANEL'
                ? 'respiratory-checkbox'
                : 'panel'
            "
            v-if="defaultTestsPopup"
          >
            <el-dialog v-model="defaultTestsPopup" class="w-60">
              <div class="dialog-header">
                <span class="header-part"> {{ category.category }} </span>
                <el-button
                  size="medium"
                  class="el-white"
                  type="info"
                  plain
                  @click="defaultTestsPopup = false"
                >
                  <img
                    src="@/assets/images/icons/cases/close-btn.svg"
                    alt="icon"
                /></el-button>
              </div>
              <div
                class="popup-panels-blocks"
                :class="{ 'check-list-gt-women': isGTI_STI(category) }"
              >
                <ul class="check-list-check-list-default">
                  <li
                    v-for="(item, index) of getCategoryWiseTestsData(
                      category.case_type_code,
                      category.category_code
                    )"
                    :key="index"
                  >
                    <el-checkbox-group
                      v-model="panelsTestsData.selectedTestIds"
                    >
                      <el-checkbox :label="item._id" :value="item" disabled>
                        {{ item.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </li>
                </ul>
              </div>
            </el-dialog>
          </div>
        </div>       
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import AppHelper from "@/mixins/AppHelper";
import { mapGetters } from "vuex";

export default {
  mixins: [UserPermissionsHelper, AppHelper],
  components: {},
  props: [
    "panelTestsData",
    "testType",
    "allSelectedTests",
    "caseType",
    "newCase",
  ],

  data() {
    return {
      panelsTestsData: "",
      allTests: "",
      selectedTest: "",
      activeNameCollapse: "",
      BloodPanels: [],
      selectedBloodPanels: [],
      testsData: [],
      existed: [],
      loading: false,
      show: false,
      test: "",
      caseRouteNames: [
        "NewCase",
        "Case-Information-Update",
        "NewOrder",
        "Edit-Order-Info",
        "PendingCasesEdit",
        "Concurrent-Case-Information-Update",
      ],
      defaultTestsPopup: false,
    };
  },

  async mounted() {
    this.loading = true;
    this.test = this.testType;
    this.allTests = this.allSelectedTests;
    this.panelsTestsData = this.panelTestsData;

    this.testsData = JSON.parse(
      JSON.stringify(this.panelsTestsData.selectedTestIds)
    );
    this.selectedCoriellPanels = JSON.parse(
      JSON.stringify(this.panelsTestsData.selectedCoriellPanels)
    );
    this.loading = false;
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    hasCases() {
      if (this.checkUser && !this.hasAllowedCaseType && !this.newCase) {
        return true;
      } else if (this.checkUser && this.hasAllowedCaseType && this.newCase) {
        return true;
      }
      return false;
    },
    checkUser() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type == "LAB_TECHNOLOGIST"
      ) {
        return true;
      }
      return false;
    },
    hasAllowedCaseType() {
      if (
        this.getAuthenticatedUser.allowed_case_types &&
        this.getAuthenticatedUser.allowed_case_types.length
      ) {
        if (
          this.getCaseInformation &&
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.length
        ) {
          const cases = this.getCaseInformation.case_types;
          const casetypes = this.getAuthenticatedUser.allowed_case_types;

          if (cases.every((item) => casetypes.includes(item))) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },

    ...mapGetters("cases", ["getCaseInformation", "getCorriellTestPanels"]),
    notCreateCase() {
      if (this.caseRouteNames.indexOf(this.$route.name) != -1) {
        return true;
      }
      return false;
    },
    getTestsInformationObject() {
      const obj = {};
      if (this.allTests) {
        this.allTests.forEach((el) => {
          obj[el._id] = el;
        });
      }
      return obj;
    },
    selectedTestsDetails() {
      const data = [];
      if (this.panelsTestsData.selectedTestIds) {
        this.panelsTestsData.selectedTestIds.forEach((el) => {
          if (el && this.getTestsInformationObject[el]) {
            data.push(this.getTestsInformationObject[el]);
          }
        });
      }

      return data;
    },

    isEdit() {
      if (this.$route.query.edit) {
        return true;
      } else if (
        !this.getCaseInformation ||
        !this.getCaseInformation.tests_info ||
        this.getCaseInformation.tests_info.length === 0
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    isGTI_STI(test) {
      if (test && test.case_type_code && test.case_type_code === "GTI_STI") {
        return true;
      }
      return false;
    },
    showDefaultTests() {
      this.defaultTestsPopup = true;
    },
    remove(test) {
      let testName = test.category_code.split("_").join(" ");
      this.$confirm(
        `Are you sure want to remove this
        <br/>
        <b style="color:#0A5DA6">"${testName}"</b> test type ?`,
        "Warning",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        for (var i = 0; i < this.allTests.length; i++) {
          var obj = this.allTests[i];

          if (test.category_code.indexOf(obj.category_code) !== -1) {
            this.allTests.splice(i, 1);
            i--;
          }
        }
      });
    },
    checkNotExistedInSelection(option) {
      let selectedWoundPanelValue = this.existed.find(
        (e) => e.case_type === this.caseType
      );

      if (selectedWoundPanelValue) {
        return selectedWoundPanelValue.test_panels.find(
          (panel) => panel == option.code
        )
          ? false
          : true;
      }
      return true;
    },
    checkConflictExist() {
      let panel = this.selectedBloodPanels.find((e) => {
        let otherPanels = this.selectedBloodPanels
          .filter((f) => f.code != e.code)
          .map((f) => f.code);
        return this.arrayInterSection(otherPanels, e.parallel_panels).length > 0
          ? true
          : false;
      });
      if (panel) {
        this.$alert(
          "<strong>" +
            panel.parallel_panels.join(", ") +
            " and " +
            panel.code +
            " are not allowed to select at a time </strong>",
          "Warning",
          {
            dangerouslyUseHTMLString: true,
            type: "warning",
          }
        );

        return true;
      } else {
        return false;
      }
    },
    setSelectedCoriellPanels() {
      this.selectedBloodPanels = [];
      this.existed = [...this.panelsTestsData.selectedCoriellPanels];
      let selectedWoundPanelValue =
        this.panelsTestsData.selectedCoriellPanels.find(
          (e) => e.case_type === this.caseType
        );

      if (selectedWoundPanelValue) {
        selectedWoundPanelValue.test_panels.forEach((panel) => {
          let selectedWoundPanel = this.BloodPanels.find(
            (x) => x.code == panel
          );
          this.selectedBloodPanels.push(selectedWoundPanel);
        });
      }
    },
    handleWoundTests() {
      if (this.checkConflictExist()) {
        // we have to remove recently added one
        this.selectedBloodPanels.pop();
        return false;
      }
      let selectedTests = [].concat.apply(
        [],
        this.selectedBloodPanels.map((e) => e.tests)
      );

      let woundIndex = this.panelsTestsData.selectedCoriellPanels.findIndex(
        (e) => e.case_type == this.caseType
      );
      if (woundIndex > -1) {
        this.panelsTestsData.selectedCoriellPanels[woundIndex] = {
          case_type: this.caseType,
          test_panels: this.selectedBloodPanels.map((e) => e.code),
        };
      } else {
        this.panelsTestsData.selectedCoriellPanels.push({
          case_type: this.caseType,
          test_panels: this.selectedBloodPanels.map((e) => e.code),
        });
      }
      // getting unique tests and removing non selected tests

      if (selectedTests.length) {
        this.removeAll();
        this.panelsTestsData.selectedTestIds = this.getUniquIds([
          ...this.panelsTestsData.selectedTestIds,
          ...selectedTests,
        ]);
        this.testsData = selectedTests;
      } else {
        this.removeAll();
        this.panelsTestsData.selectedCoriellPanels =
          this.panelsTestsData.selectedCoriellPanels.filter(
            (x) => x.case_type != this.caseType
          );
      }

      this.$emit("updateTestPanles", this.panelsTestsData);
    },
    getUniquIds(ids) {
      return Array.from(new Set(ids));
    },
    removeAll() {
      let woundPanelTests = [].concat.apply(
        [],
        this.BloodPanels.map((e) => e.tests)
      );
      this.panelsTestsData.selectedTestIds =
        this.panelsTestsData.selectedTestIds.filter(
          (x) => woundPanelTests.indexOf(x) == -1
        );
    },
    getCategoryWiseTestsData(caseType, category) {
      const data = this.selectedTestsDetails.filter(
        (el) => el.case_type_code === caseType && el.category_code === category
      );
      return data;
    },
    getCaseCategoryData(caseType) {
      const data = this.selectedTestsDetails.filter(
        (el) => el.case_type_code === caseType
      );
      let flags = [],
        output = [],
        outputWithFullDetails = [],
        l = data.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[data[i].category_code]) continue;
        flags[data[i].category_code] = true;
        output.push(data[i].category_code);
        outputWithFullDetails.push(data[i]);
      }
      return outputWithFullDetails;
    },
    getCaseCategoryBasedData(caseType, category, include = true) {
      let data = "";
      if (include) {
        data = this.selectedTestsDetails.filter(
          (el) =>
            el.case_type_code === caseType &&
            category.indexOf(el.category_code) != -1
        );
      } else {
        data = this.selectedTestsDetails.filter(
          (el) =>
            el.case_type_code === caseType &&
            category.indexOf(el.category_code) == -1
        );
      }

      let flags = [],
        output = [],
        outputWithFullDetails = [],
        l = data.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[data[i].category_code]) continue;
        flags[data[i].category_code] = true;
        output.push(data[i].category_code);
        outputWithFullDetails.push(data[i]);
      }
      return outputWithFullDetails;
    },
  },
};
</script>
<style lang="scss">
#default-test-preview {
  .el-dialog {
    width: 60% !important;
    margin-top: 7vh;
  }
}
.default-modal {
  .respiratory-checkbox {
    .popup-panels-blocks {
      .check-list-check-list-default {
        grid-template-columns: repeat(2, 1fr);
      }
      .el-checkbox__label {
        color: #34495e;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
