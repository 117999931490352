<template>
  <section id="attachment-barcode-component">
    <div class="case-creation__step case-creation__test-information">
      <el-row :gutter="20">
        <el-col :xl="24" :lg="24">
          <div class="case-creation__card attachments">
            <div
              class="case-creation__heading test-attacments border-bottom-none"
            >
              <div class="title-with-icon">
                <span class="icon">
                  <img
                    src="@/assets/images/icons/icon-attachments.svg"
                    alt="icon"
                    class="img-fluid"
                  />
                </span>
                <h3 class="title">ATTACHMENTS</h3>
              </div>              
            </div>
            <div class="attachment-popup">
              <el-button @click="attachmentPopup = true">
                <span class="popup">
                  <img
                    src="@/assets/images/icons/attachment-icon-v2.svg"
                    alt=""
                  />
                  <h4>ATTACHMENTS</h4>
                </span>
              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- model-popup -->
    <div class="attachment-modal-popup">
      <div class="default-modal">
        <el-dialog v-model="attachmentPopup" title="">
          <div class="attachment-dialog-header">
            <div class="attachment-header-part">
              <span>
                <img
                  src="@/assets/images/icons/attachment-icon-v2.svg"
                  alt=""
                />
              </span>
              <h4>ATTACHMENTS & NOTES</h4>
            </div>
            <div @click="handleClose" class="attachment-close cursor">
              <img src="@/assets/images/icons/dashboard-close.svg" alt="" />
            </div>
          </div>
          <div class="attachment-dialog-body">
            <el-tabs v-model="activeName" class="attachment-dialog-tabs">
              <el-tab-pane label="Attachments" name="attachments">
                <template #label>
                  <div class="custom-tab-title">
                    <div class="icon">
                      <img
                        src="@/assets/images/icons/attachments/attachments_0.svg"
                        alt="icon"
                        class="img-fluid non-active"
                      />
                      <img
                        src="@/assets/images/icons/attachments/attachments_1.svg"
                        alt="icon"
                        class="img-fluid active"
                      />
                    </div>
                    <div class="text">Attachments</div>
                  </div>
                </template>
                <Attachments v-if="activeName == 'attachments'" />
              </el-tab-pane>
              <el-tab-pane label="Internal Documents" name="internal-documents">
                <template #label>
                  <div class="custom-tab-title">
                    <div class="icon">
                      <img
                        src="@/assets/images/icons/attachments/internal-documents_0.svg"
                        alt="icon"
                        class="img-fluid non-active"
                      />
                      <img
                        src="@/assets/images/icons/attachments/internal-documents_1.svg"
                        alt="icon"
                        class="img-fluid active"
                      />
                    </div>
                    <div class="text">Internal Documents</div>
                  </div>
                </template>
                <InternalDocuments v-if="activeName == 'internal-documents'" />
              </el-tab-pane>
              <el-tab-pane label="Internal Case Notes" name="notes">
                <template #label>
                  <div class="custom-tab-title">
                    <div class="icon">
                      <img
                        src="@/assets/images/icons/attachments/internal-documents_0.svg"
                        alt="icon"
                        class="img-fluid non-active"
                      />
                      <img
                        src="@/assets/images/icons/attachments/internal-documents_1.svg"
                        alt="icon"
                        class="img-fluid active"
                      />
                    </div>
                    <div class="text">Internal notes</div>
                  </div>
                </template>
                <InternalCaseNotes v-if="activeName == 'notes'" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-dialog>
      </div>
    </div>
  </section>
</template>
<script>
import { defineAsyncComponent } from "vue";
const Attachments = defineAsyncComponent(() =>
  import("@/components/cases/Attachments")
);
const InternalDocuments = defineAsyncComponent(() =>
  import("@/components/cases/InternalDocuments")
);
const InternalCaseNotes = defineAsyncComponent(() =>
  import("@/components/cases/InternalCaseNotes")
);
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      attachmentPopup: false,
      activeName: "attachments",
      generatingBarcode: false,
      caseId: "",
      showBarCode: false,
      barCodeURL: "",
    };
  },
  computed: {
    ...mapGetters("cases", ["getCaseInformation"]),
  },
  components: {
    Attachments,
    InternalDocuments,
    InternalCaseNotes,
  },
  mounted() {
    this.caseId = this.$route.params.case_id;
  },
  methods: {
    handleClose() {
      this.activeName = "attachments";
      this.attachmentPopup = false;
    },

    async viewBarcode() {
      let urlData = this.$router.resolve({
        name: "MultipleBarcodes",
        path: "multiple-barcodes",
        query: {
          case_ids: this.caseId,
        },
      });
      window.open(urlData.href, "_blank");
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/partials/cases/attachments.scss";

#attachment-barcode-component {
  .case-creation__test-information {
    .case-creation__card {
      &.attachments {
        display: flex;
        justify-content: space-between;
      }
      .test-attacments {
        display: flex;
        align-items: center;
        p {
          font-size: 11px;
          color: #215fad;
          font-weight: 500;
          line-height: 14px;
          margin: 0 7px 0 10px;
        }
      }
    }
    .attachment-popup {
      display: flex;
      .el-button {
        padding: 7px 12px;
        background-color: #273480;
        color: #fff;
        min-height: 31px;
        border: none;
        border-radius: 6px;
        margin: 2px;
        &.view {
          background-color: #eb5757;
        }
      }
      .popup {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
    }
    .barcode {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      background-color: #fff;
      border-radius: 5px;

      img {
        width: 32px;
        height: 32px;
        background-color: #eb5757;
        padding: 0 8px;
        border-radius: 5px 0 0 5px;
      }

      p {
        line-height: 14px;
        font-size: 12px;
        color: #215fad;
        padding-left: 10px;
        font-weight: 500;
      }
      .el-button {
        min-height: 31.5px;
        background: linear-gradient(79.26deg, #f24a55 -13.43%, #0a5da6 137.71%);
        color: white;
        border-radius: 7px;
        padding: 8px 20px;
        border: none;
      }
    }
  }
}
</style>
