<template>
  <div>
    <AllMileageCases></AllMileageCases>
  </div>
</template>
  
  <script>
const AllMileageCases = defineAsyncComponent(() =>
  import("@/components/cases/AllMileageCases")
);
import { defineAsyncComponent } from "vue";
export default {
  components: {
    AllMileageCases,
  },
};
</script>
  