<template>
  <section class="profile-data-view">
    <div class="profile-container">
      <div class="profile-content">
        <el-card class="account-card">
          <div class="account-header">ACCOUNT</div>
          <div class="profile-account-view">
            <ul>
              <li class="active">
                <el-button class="list-item-account-menu" type="button">
                  <span class="account-icons">
                    <img src="@/assets/images/icons/profile/personal-info.svg" alt="icon"
                  /></span>
                  <span class="text">Personal Information</span>
                </el-button>
              </li>
              <li
                v-if="getAuthenticatedUser && getAuthenticatedUser.user_type != 'TOP_DOG'"
              >
                <el-button
                  class="list-item-account-menu"
                  type="button"
                  @click="updatePassword()"
                >
                  <span class="account-icons"
                    ><img
                      src="@/assets/images/icons/profile/reset-key-fill.svg"
                      alt="icon"
                  /></span>
                  <span class="text">Update Password</span>
                </el-button>
              </li>
              <li>
                <el-button class="list-item-account-menu" @click="logOut()">
                  <span class="account-icons"
                    ><img src="@/assets/images/icons/profile/log-out2.svg" alt="icon"
                  /></span>
                  <span class="text">Log Out</span>
                </el-button>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div class="right-block">
        <div class="user-profile-header">
          <h4>Personal Information</h4>
          <span class="edit-details">
            <el-button
              class=""
              type="button"
              @click="changeProfileStatus"
              v-if="status != 'edit'"
            >
              <img src="@/assets/images/icons/profile/edit-icon.svg" alt="icon" />

              Edit Profile</el-button
            >
          </span>
        </div>
        <!-- User-Profile-View -->
        <div class="user-Profile-view">
          <el-row>
            <el-col :span="6" class="left-column" v-if="getProfileData">
              <div class="profile-image-details">
                <div class="profile" v-loading="loading">
                  <el-upload
                    name="avatar"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    accept="image/*"
                    v-loading="snapUploading"
                    :action="snapUploadUrl"
                    :headers="uploadHeaders()"
                    :on-change="handleAvatarChange"
                    :on-success="handleAvatarSuccess"
                    :on-error="handleAvatarError"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                  >
                    <el-avatar v-if="snapUrl" v-loading="loading">
                      <img class="profile-image" :src="snapUrl" />
                    </el-avatar>

                    <span>
                      <img
                        class="edit-profile-icon"
                        src="@/assets/images/icons/profile/profile-edit.svg"
                      />
                    </span>
                  </el-upload>

                  <p class="user-name">
                    {{ getProfileData.first_name }} {{ getProfileData.last_name }}
                  </p>
                  <p class="designation">
                    {{ getProfileData.user_type.split("_").join(" ") }}
                  </p>
                </div>
              </div>
            </el-col>
            <el-col :span="18" class="right-column">
              <div class="profile-data" v-if="status == 'view' && getProfileData">
                <el-row gutter="40" type="flex" justify="center" align="center">
                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24" class="">
                    <div class="each-view">
                      <p class="label">First Name</p>
                      <p class="value">
                        {{ getProfileData.first_name || "---" }}
                      </p>
                    </div>
                  </el-col>
                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24" class="">
                    <div class="each-view">
                      <p class="label">Last Name</p>
                      <p class="value">
                        {{ getProfileData.last_name || "---" }}
                      </p>
                    </div>
                  </el-col>
                  <el-col
                    :xl="12"
                    :lg="12"
                    :md="12"
                    :sm="12"
                    :xs="24"
                    class=""
                    v-if="
                      getProfileData && getProfileData.lab && getProfileData.lab.lab_code
                    "
                  >
                    <div class="each-view">
                      <p class="label">Lab Code</p>
                      <p class="value">
                        {{ getProfileData.lab.lab_code || "---" }}
                      </p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Gender</p>
                      <p class="value">{{ getProfileData.gender || "---" }}</p>
                    </div>
                  </el-col>
                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Educational Qualification</p>
                      <p class="value">
                        {{ getProfileData.educational_qualification || "---" }}
                      </p>
                    </div>
                  </el-col>
                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Email ID</p>
                      <p class="value email-address">
                        {{ getProfileData.email }}
                      </p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Address Line 1</p>
                      <p class="value" v-if="getProfileData && getProfileData.address">
                        {{ getProfileData.address.line_one || "--" }}
                      </p>
                      <p class="value" v-else>--</p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Address Line 2</p>
                      <p class="value" v-if="getProfileData && getProfileData.address">
                        {{ getProfileData.address.line_two || "--" }}
                      </p>
                      <p class="value" v-else>--</p>
                    </div>
                  </el-col>
                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Phone Number</p>
                      <p class="value">{{ getProfileData.phone }}</p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">Zip</p>
                      <p class="value" v-if="getProfileData && getProfileData.address">
                        {{ getProfileData.address.zip || "---" }}
                      </p>
                      <p class="value" v-else>--</p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">State</p>
                      <p class="value" v-if="getProfileData && getProfileData.address">
                        {{ getProfileData.address.state || "---" }}
                      </p>
                      <p class="value" v-else>--</p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="each-view">
                      <p class="label">City</p>
                      <p class="value" v-if="getProfileData && getProfileData.address">
                        {{ getProfileData.address.city || "---" }}
                      </p>
                      <p class="value" v-else>--</p>
                    </div>
                  </el-col>

                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24"> </el-col>
                </el-row>
              </div>
              <!-- user-edit-step -->
              <div
                class="profile-user-data-view"
                v-if="status == 'edit'"
                v-loading="loading"
              >
                <el-form v-model="formData">
                  <el-row gutter="40" type="flex" justify="center" align="center">
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          First Name
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            v-model="formData.first_name"
                            type="text"
                            placeholder="Enter First Name"
                          ></el-input>
                          <p class="err">
                            <FormError errorName="first_name"></FormError>
                          </p>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Last Name
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            v-model="formData.last_name"
                            type="text"
                            placeholder="Enter Last Name"
                          ></el-input>
                          <p class="err">
                            <FormError errorName="last_name"></FormError>
                          </p>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Gender
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-select
                            placeholder="Select Gender"
                            v-model="formData.gender"
                            filterable
                            default-first-option
                            clearable
                          >
                            <el-option label="MALE" value="MALE"></el-option>
                            <el-option label="FEMALE" value="FEMALE"></el-option>
                          </el-select>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="gender"></FormError>
                        </p>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <dobVersion
                          v-if="showDob"
                          :dobData="formData"
                          :validataionErrors="getErrors"
                          :errorKey="'dob'"
                          ref="dobField"
                        >
                        </dobVersion>
                      </div>
                    </el-col>

                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Educational Qualification
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            placeholder="Enter Educational Qualificaiton"
                            v-model="formData.educational_qualification"
                          >
                          </el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="educational_qualification"></FormError>
                        </p>
                      </div>
                    </el-col>

                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Phone Number
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            v-model="formData.phone"
                            placeholder="Enter Phone Number"
                            maxlength="10"
                          ></el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="phone"></FormError>
                        </p>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text"> Fax Number </label>
                        <el-form-item class="form-input">
                          <el-input
                            maxlength="10"
                            v-model="formData.fax"
                            type="number"
                            placeholder="Enter Fax Number"
                          ></el-input>
                          <p class="err">
                            <FormError errorName="fax"></FormError>
                          </p>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Email Address
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            v-model="formData.email"
                            type="email"
                            class="email-lower"
                            placeholder="Enter Email"
                          ></el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="email"></FormError>
                        </p>
                      </div>
                    </el-col>
                    <el-col
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="12"
                      :xs="24"
                      v-if="formData.user_type == 'LAB_MANAGER'"
                    >
                      <div class="each-data-edit">
                        <label for="text">
                          Report Signature
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            type="text"
                            v-model="formData.report_signature"
                            placeholder="Enter Report Signature"
                          ></el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="report_signature"></FormError>
                        </p>
                      </div>
                    </el-col>

                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Address Line 1
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            v-model="formData.address.line_one"
                            type="input"
                            placeholder="Enter Address Line 1"
                          ></el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="address.line_one"></FormError>
                        </p>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text"> Address Line 2 </label>
                        <el-form-item class="form-input">
                          <el-input
                            v-model="formData.address.line_two"
                            type="input"
                            placeholder="Enter Address Line 2"
                          ></el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="address.line_two"></FormError>
                        </p>
                      </div>
                    </el-col>

                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="each-data-edit">
                        <label for="text">
                          Zip
                          <span class="star">*</span>
                        </label>
                        <el-form-item class="form-input">
                          <el-input
                            placeholder="Enter Zip"
                            v-model="formData.address.zip"
                            maxlength="5"
                            @input="getStateCity"
                          >
                          </el-input>
                        </el-form-item>
                        <p class="err">
                          <FormError errorName="address.zip"></FormError>
                        </p>
                      </div>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="information-state">
                        <state :stateData="formData.address"></state>
                      </div>
                      <p class="err">
                        <FormError errorName="address.state"></FormError>
                      </p>
                    </el-col>
                    <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                      <div class="information-city">
                        <city :cityData="formData.address"></city>
                      </div>
                      <p class="err">
                        <FormError errorName="address.city"></FormError>
                      </p>
                    </el-col>

                    <el-col
                      v-if="formData.user_type !== 'LAB_MANAGER'"
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="12"
                      :xs="24"
                    >
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="footer-btn" v-if="status == 'edit'">
        <el-button class="cancel-btn" @click="cancelUpdate">CANCEL</el-button>
        <el-button class="save-btn" @click="updateProfile">UPDATE</el-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

const state = defineAsyncComponent(() => import("@/components/misc/State"));
const city = defineAsyncComponent(() => import("@/components/misc/City"));
import appConfig from "@/config/app";
const FormError = defineAsyncComponent(() => import("@/components/misc/FormError"));
const dobVersion = defineAsyncComponent(() => import("@/components/misc/DobNewFormat"));
import { successNotification, errorNotification } from "@/helpers/notifications";
export default {
  components: {
    state,
    city,
    FormError,
    dobVersion,
  },
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      status: "view",
      snapUploading: false,
      snapUploadUrl: appConfig.API_URL + "/profile-pic",
      snapUrl: "",
      loading: false,
      showDob: false,
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        educational_qualification: "",
        username: "",
        date_of_birth: "",
        gender: "",
        fax: "",
        address: {
          line_one: "",
          line_two: "",
          city: "",
          state: "",
          zip: "",
        },
        user_type: "",
        report_signature: "",
      },
    };
  },
  computed: {
    ...mapGetters("profile", ["getProfileData", "getProfileUpdateStatus"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getAuthenticationDetails"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("cases", ["getStateAndCity"]),
  },
  async mounted() {
    this.loading = true;
    await this.$store.commit("errors/clear", null, { root: true });

    await this.getUpadatedData();
    if (this.getProfileData) {
      this.formData = { ...this.formData, ...this.getProfileData };
      this.formData.date_of_birth = this.getProfileData.dob
        ? this.getProfileData.dob
        : "";
    }
    if (this.getProfileData && this.getProfileData.avatar) {
      this.snapUrl = this.getProfileData.avatar;
    }
    this.loading = false;
  },
  methods: {
    async getStateCity() {
      try {
        let params = {
          zip: this.formData.address.zip,
        };
        await this.$store.dispatch("cases/getStateCity", params);
        if (this.getStateAndCity) {
          this.formData.address.state = this.getStateAndCity.state;
          this.formData.address.city = this.getStateAndCity.city;
        } else {
          if (!this.getStateAndCity && params.zip.length == 5) {
            this.$message({
              message: "No State & City Found",
              type: "warning",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    beforeAvatarUpload(file) {
      this.snapUploading = true;
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("Avatar picture must be JPG/PNG format!");
      }
      if (!isLt2M) {
        this.$message.error("Avatar picture size can not exceed 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleAvatarError(res) {
      if (!res.success) {
        this.$message.error(JSON.parse(res.message).message);
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.success) {
        this.snapUrl = URL.createObjectURL(file.raw);
        this.$store.dispatch("profile/updateProfilePicInHeader", res.data.avatar);
        // window.location.reload();
      } else {
        this.$message.error(res.message);
      }
      this.snapUploading = false;
    },
    handleAvatarChange() {
      this.snapUploading = false;
    },
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
    async updateProfile() {
      try {
        this.loading = true;
        let params = this.formData;
        if (this.formData.date_of_birth) {
          params.dob = this.formData.date_of_birth;
        }
        if (params.date_of_birth) {
          params.dob = this.formData.date_of_birth;
          delete params.date_of_birth;
        }

        if (params.email) {
          params.email = params.email.toLowerCase();
        }
        if (!params.report_signature) delete params.report_signature;
        if (!params.fax) delete params.fax;
        if (!params.address.line_two) delete params.address.line_two;
        await this.$store.dispatch("profile/updateProfile", params);
        if (this.getProfileUpdateStatus && this.getProfileUpdateStatus.success) {
          successNotification(this.getProfileUpdateStatus.message);

          await this.getUpadatedData();
          this.cancelUpdate();
        } else {
          if (!this.getErrors || this.getError) {
            errorNotification("Error while Updating Profile");
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    updatePassword() {
      this.$router.push("/update-password");
    },
    async logOut() {
      await this.$store.dispatch("globalReset");
      this.$router.push("/signin");
    },
    async getUpadatedData() {
      await this.$store.dispatch("profile/getProfile");
      if (this.getProfileData) {
        this.formData.date_of_birth = this.getProfileData.dob
          ? this.getProfileData.dob
          : "";
      }
    },
    cancelUpdate() {
      this.status = "view";
      this.showDob = false;
    },
    changeProfileStatus() {
      this.status = "edit";
      setTimeout(() => {
        this.showDob = true;
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.star {
  color: red;
}
.profile-data-view {
  padding: 2em;
  .profile-container {
    display: grid;
    grid-template-columns: 300px 1fr;
    column-gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .profile-content {
    .account-card {
      border-radius: 10px;
      &.is-always-shadow {
        box-shadow: none;
        color: #34495e;
        font-weight: 600;
        border: 1px solid #e7e7f7;
      }

      .el-card__body {
        padding: 0;
      }
      .account-header {
        padding: 20px 20px 10px 20px;
        border-bottom: 0.5px solid #e7e7f7;
      }
    }
    .profile-account-view {
      ul {
        li {
          position: relative;
          &.active {
            .list-item-account-menu {
              background-color: #569ff210;
            }
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 3px;
              background-color: #215fad;
            }
          }
          .list-item-account-menu {
            border: none;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
            color: #34495e;
            text-align: left;
            border-radius: 0;
            & > span {
              display: flex;
              align-items: center;
            }
            .account-icons {
              img {
                width: 18px;
                top: 1px;
                padding: 0 10px 0 0;
                position: relative;
              }
            }
            &:hover,
            &:focus,
            &:active {
              background: #56cbf210;
              color: #34495e;
            }
          }
        }
      }
    }
  }

  .right-block {
    background: #ffffff;
    border: 1px solid #e7e7f7;

    border-radius: 10px;
    .user-profile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1.5rem;
      border-bottom: 0.5px solid #e7e7f7;
      h4 {
        font-weight: 600;
        line-height: 100%;
        font-size: 1rem;

        letter-spacing: 0.5px;
        text-transform: uppercase;

        color: #233a73;
      }
      .edit-details {
        .el-button {
          font-size: 14px;
          border: none;
          display: flex;

          font-weight: 500;
          align-items: center;
          padding: 0;
          color: #828282;
          img {
            margin: -5.5px 0;
            width: 22px;
          }
          &:hover,
          &:active {
            background-color: transparent;
          }
        }
      }
    }
    .user-Profile-view {
      .left-column {
        .profile-image-details {
          margin-top: 1rem;
          .profile {
            text-align: center;
            .profile-image {
              .el-avatar {
                width: auto;
                height: auto;
                display: table;
                margin: 0 auto;
                img {
                  max-width: 175px;
                  object-position: center;
                  object-fit: cover;
                  height: 175px;
                }
              }
            }
            .edit-profile-icon {
              width: 25px;
              position: relative;
              bottom: 16px;
            }
            .user-name {
              font-weight: 600;
              font-size: 15px;
              line-height: 1.2;
              color: #233a73;
              margin-bottom: 0.25em;
              text-transform: capitalize;
            }
            .designation {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #828282;
            }
          }
        }
      }
    }

    .right-column {
      border-left: 0.5px solid #e7e7f7;
      .profile-data {
        padding: 1rem;
        .each-view {
          margin-bottom: 25px;
          .label {
            letter-spacing: 0.5px;
            color: #4f4f4f;
            font-size: 14px;
            margin-bottom: 0 !important;
            line-height: 20px;
            font-weight: 500;
          }
          .value {
            font-size: 16px;
            font-weight: 400;
            margin: 0 12px 0 0;
            line-height: 1.4;
            color: #0a2342;
            padding: 0 15px 0 0;
            word-break: break-all;
            text-transform: capitalize;
          }
          .email-address {
            text-transform: lowercase !important;
          }
        }
      }
      .profile-user-data-view {
        padding: 1rem;
        .each-data-edit {
          label {
            letter-spacing: 0.5px;
            color: #4f4f4f;
            font-size: 14px;
            margin-bottom: 2px !important;
          }
          .form-input {
            margin-bottom: 0px;
            .el-input__inner {
              background: #ffffff;
              border: 0.5px solid rgba(86, 204, 242, 0.8);
              box-sizing: border-box;
              border-radius: 3px;
              height: 30px;
            }
            .el-textarea__inner {
              background: #ffffff;
              border: 0.5px solid rgba(86, 204, 242, 0.8);
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          .date-of-birth {
            .el-form-item__content {
              line-height: 24px;
              flex: 1;
              position: relative;
              font-size: 14px;
              min-width: 0;
            }
          }
          .FormDate {
            border: 0.5px solid rgba(86, 204, 242, 0.8);
          }
        }
        .information-state {
          margin: 5px 0;
          letter-spacing: 0.5px;
          color: #4f4f4f;
          font-size: 16px;
          .el-input__inner {
            background: #ffffff;
            border: 0.5px solid rgba(86, 204, 242, 0.8);
            box-sizing: border-box;
            border-radius: 3px;
            height: 30px;
          }
        }
        .information-city {
          margin: 5px 0;
          letter-spacing: 0.5px;
          color: #4f4f4f;
          font-size: 16px;
          .el-input__inner {
            background: #ffffff;
            border: 0.5px solid rgba(86, 204, 242, 0.8);
            box-sizing: border-box;
            border-radius: 3px;
            height: 30px;
          }
        }
      }
    }
  }
  .footer-btn {
    text-align: right;
    grid-column: 2 / span 1;
    padding-top: 10px;
    .el-button {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      min-height: 36px;
      padding: 0px 15px;
      border: none;
      line-height: 4px;
      color: #4f4f4f;
      &.cancel-btn {
        background-color: #f24a55;
        color: #ffffff;

        &:hover,
        &:active {
          background-color: #f24a55;
          color: #ffffff;
        }
      }
      &.save-btn {
        background: #215fad;
        color: #ffffff;
      }
    }
  }
}
</style>
