<template>
  <section class="all-cases-view" id="volume-stats-section">
    <div class="container-default">
      <div class="marketers">
        <div class="marketers__statsBlock">
          <div class="marketers__statsTabs">
            <ul class="each-stats-tabs">
              <li
                :class="{
                  'router-link-active': $route.name == 'AllMarketersData',
                }"
              >
                <router-link
                  :to="{
                    name: 'AllMarketersData',
                    query: this.$route.query,
                  }"
                >
                  REGIONAL MANAGER
                </router-link>
              </li>
              <li
                :class="{
                  'router-link-active': $route.name == 'MarketerData',
                }"
              >
                <router-link
                  :to="{
                    name: 'MarketerData',
                    query: this.$route.query,
                  }"
                >
                  MARKETER
                </router-link>
              </li>
              <li
                :class="{
                  'router-link-active': $route.name == 'CompareMarketersData',
                }"
              >
                <router-link
                  :to="{
                    name: 'CompareMarketersData',
                    query: this.$route.query,
                  }"
                >
                  ANALYSIS
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <router-view :layout="layout"></router-view>
  </section>
</template>
<script>
export default {
  data() {
    return {
      layout: "div",
    };
  },
  computed: {},
  async mounted() {},
  methods: {},
};
</script>
