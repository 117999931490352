<template>
  <div>
    <AddMileageCase></AddMileageCase>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
const AddMileageCase = defineAsyncComponent(() =>
  import("@/components/cases/AddMilageCase")
);
export default {
  components: {
    AddMileageCase,
  },
  mounted() {},
};
</script>
